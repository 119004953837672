import { Avatar, Button, Card, CardBody, CircularProgress, Input, ModalBody, ModalHeader, useDisclosure, NextUIProvider } from '@nextui-org/react'
import React, { useEffect, useRef, useState } from 'react'
import { DeleteSingleAttribute, UpdateOneUserData, getOneData, uploadFile } from '../../controllers/strapiController';
import ModalPopup from '../Collections/ModalPopup';
import DeleteModal from '../../components/DeleteModal';
import { useNavigate } from "react-router-dom";
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { EyeFilledIcon } from "../../Images/EyeFilledIcon";
import { EyeSlashFilledIcon } from "../../Images/EyeSlashFilledIcon";
import pluralize from "pluralize";
import CryptoJS from "crypto-js";
import { Notify } from "../../components/Toastify";
import { colorPicker, displayName } from '../../controllers/basicControllers';
import { settingSkeleton } from '../../components/Skeleton';
import Theme from '../../components/Theme';
import { useDispatch } from "react-redux";
import { setData } from "../../redux/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const AccountSettings = () => {
    // STATE INITIALISATION
    const [UserDetails, setUserDetails] = useState(null);
    const [Loader, setLoader] = useState(true);
    const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
    const UserId = UserLogged && UserLogged.user.id;
    const fileInputRef = useRef();
    const { isOpen: isModalOpen, onOpen: onModalOpen, onOpenChange: onModalOpenChange } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
    const [FileUpload, setFileUpload] = useState(null);
    const [fileUploaded, setFileUploaded] = useState();
    const [Response, setResponse] = useState('');
    const [notification, setNotification] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [imageLoader, setImageLoader] = useState(false);
    var PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    const [passwordError, setPasswordError] = useState('');
    const [formData, setFormData] = useState({ Firstname: '', Lastname: '', Picture: '', Theme: '', Password: '' });

    // API RENDERING
    useEffect(() => {
        setLoader(true);
        getOneData("users", UserId)
            .then((data) => {
                setLoader(false);
                setFormData({
                    Firstname: data.firstname,
                    Lastname: data.lastname,
                    Picture: data?.Picture && data.Picture !== null && `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data?.Picture?.url}` : `${data?.Picture?.url}`}`,
                    Theme: data && data.Theme,
                    Password: ""
                })
                setUserDetails(data)
                dispatch(setData(data))
            })
    }, [Response, UserId])

    // UPLOAD FILE FUNCTION
    useEffect(() => {
        const UploadFileHandler = async () => {
            setImageLoader(true);
            let formData = new FormData();
            formData.append("files", FileUpload);
            const response = await uploadFile(formData);
            if (response) {
                setImageLoader(false);
                const URLresponse = response && response[0];
                URLresponse && setFileUploaded(URLresponse);
            }
        };

        if (FileUpload) {
            UploadFileHandler();
        }
    }, [FileUpload]);

    const UserProfile = UserDetails?.Picture !== null ? `${process.env.REACT_APP_STAGE === "dev" ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${UserDetails?.Picture?.url}` : `${UserDetails?.Picture?.url}`}` : "";
    const UploadedUser = fileUploaded && fileUploaded !== null && `${process.env.REACT_APP_STAGE === "dev" ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${fileUploaded.url}` : `${fileUploaded.url}`}`;

    // UPDATE USER DETAILS
    const UpdateProfile = async (onClose) => {
        // setLoader(true);
        const payload = {
            firstname: formData.Firstname,
            lastname: formData.Lastname,
            Picture: fileUploaded,
            Theme: formData.Theme
        }

        const response = await UpdateOneUserData("users", UserId, payload);
        if (response) {
            onClose();
            setNotification(true)
            setTimeout(() => {
                setLoader(false);
                setResponse(response);
            }, 500);
        }
    }

    useEffect(() => {
        if (notification === true) {
            Notify("User profile successfully updated.")
        }
    }, [notification])

    // ONCHANGE FUNCTION TO SELECT FILE
    const handleFileChange = (event) => {
        const files = event.target.files;

        if (files && files[0]) {
            const file = files[0];
            setFileUpload(file);
        } else {
            setFileUpload(null);
        }
    }

    // REMOVE PROFILE PICTURE
    const Handleremove = async () => {
        setFileUploaded(null)
        setFormData({ ...formData, Picture: "" })
    }

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    //GETTING LST OF USERS RELATED TO USER
    const UserWithRelations = (userData) => {
        const RelationType = [];
        for (const key in userData) {
            if (key === "tag") {
                RelationType.push({ [key]: [userData[key]] })
            }
            if (Array.isArray(userData[key])) {
                if (key === "collections" || key === "connectors" || key === "spaces" || key === "tag") {
                    RelationType.push({ [key]: userData[key] })
                }
            }
        }
        return RelationType;
    }

    const RelationData = UserDetails && UserWithRelations(UserDetails);

    // DELETE FUNCTIO TO DELETE USER ALONG WITH RELATED DATA
    const DeleteHandler = async () => {
        setLoader(true);
        try {
            const promises = RelationData.map(async (data) => {
                for (const key in data) {
                    if (Array.isArray(data[key])) {
                        return Promise.all(data[key].map(async (newdata) => {
                            const PluralizeData = pluralize.plural(key)
                            await DeleteSingleAttribute(PluralizeData, newdata.id);
                        }));
                    }
                }
            });

            await Promise.all(promises);

            const response = await DeleteSingleAttribute("users", UserId);
            if (response) {
                setTimeout(() => {
                    setLoader(false);
                    navigate("/sign-in");
                    sessionStorage.clear();
                }, 500)
            }
        } catch (error) {
            setLoader(false);
        }
    };

    // PASSWORD EYE FUNCTIONALITY
    const [isVisible, setIsVisible] = React.useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);

    // VALIDATION FORM
    const Validation = () => {
        var isValid = true;

        if (formData.Password !== "" && !PasswordRegex.test(formData.Password)) {
            isValid = false;
            setPasswordError("Enter a strong password.")
        } else {
            setPasswordError(null)
        }
        return isValid;
    }

    // PASSWORD CHANGE FUNCTION
    const HandlePasswordChange = async () => {
        const Validate = Validation()
        const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
        const inputString = formData.Password;
        const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
        const AESHash = encrypted.toString();
        const payload = {
            Password2: btoa(AESHash)
        }

        if (Validate) {
            setValidationError(true);
            const response = await UpdateOneUserData("users", UserId, payload);
            if (response) {
                window.location.reload();
            }
        } else {
            setValidationError(false);
        }
    }

    const ModalBodyData = () => {
        return <>
            <ModalHeader>Edit profile</ModalHeader>
            <ModalBody>
                <div className='flex flex-row items-center gap-6'>
                    <Avatar radius="full" size='lg' src={UploadedUser ? UploadedUser : formData.Picture} color={colorPicker(UserDetails.id)} name={UserDetails?.firstname !== null ? UserDetails?.firstname.slice(0, 1).toUpperCase() : UserDetails?.email.slice(0, 1).toUpperCase()} />
                    <input ref={fileInputRef} type='file' onChange={(event) => handleFileChange(event)} className='hidden' />
                    <Button onClick={handleButtonClick} size='sm' variant='bordered' >Select profile image</Button>
                    {UserProfile ? <>{imageLoader ? <CircularProgress size="sm" aria-label="Loading..." /> : <p className='text-xs cursor-pointer' onClick={Handleremove}>Remove</p>}</> : ""}
                </div>
                <div className='flex flex-col gap-4 pt-4'>
                    <div>
                        <p className='text-sm font-normal text-slate-500 pb-1'>First name</p>
                        <Input
                            key="firstname"
                            type="text"
                            size='sm'
                            label=""
                            defaultValue={formData && formData.Firstname}
                            onChange={(e) => setFormData({ ...formData, Firstname: e.target.value })}
                            labelPlacement="outside"
                            placeholder="Enter your full name"
                        />
                    </div>
                    <div>
                        <p className='text-sm font-normal text-slate-500 pb-1'>Last name</p>
                        <Input
                            key="lastname"
                            type="text"
                            size='sm'
                            label=""
                            defaultValue={formData && formData.Lastname}
                            onChange={(e) => setFormData({ ...formData, Lastname: e.target.value })}
                            labelPlacement="outside"
                            placeholder="Enter your last name"
                        />
                    </div>
                    <div className='cursor-not-allowed'>
                        <p className='text-sm font-normal text-slate-500 pb-1'>Email</p>
                        <Input
                            isDisabled
                            key="email"
                            type="text"
                            size='sm'
                            label=""
                            value={UserDetails && UserDetails.email}
                            labelPlacement="outside"
                            placeholder="Enter your email"
                        />
                    </div>
                </div>
            </ModalBody>
        </>
    }

    const footerCreate = (onClose) => {
        return <>
            <Button variant='bordered' onClick={() => UpdateProfile(onClose)}>
                Update
            </Button>
        </>
    }

    // RETURN UI
    return (
        <div className='flex flex-col max-w-6xl h-screen mx-auto pt-8'>
            <NextThemesProvider defaultTheme={'light'}>
                <div className='max-w-2xl w-full m-auto flex flex-col gap-6 px-4 pt-8'>
                    {Loader ? settingSkeleton() : <>
                        <NextUIProvider>
                            <div className='text-slate-500 cursor-pointer' onClick={() => navigate(-1)}>
                                <Button
                                    isIconOnly
                                    color="danger"
                                    className='mb-2 mr-2'
                                    radius='full'
                                    size='sm'
                                    aria-label="Like"
                                    onClick={() => navigate(-1)}
                                    startContent={<FontAwesomeIcon icon={faArrowLeft} />}
                                ></Button>
                                Go back
                            </div>
                            <Card className="shadow-none border border-divider rounded-2xl p-4 mt-4">
                                <CardBody>
                                    <div className='flex flex-row items-center gap-8'>
                                        <Avatar radius="full" size='md' classname="" color={colorPicker(UserDetails.id)} src={UserProfile} name={UserDetails?.firstname !== null ? UserDetails?.firstname.slice(0, 1).toUpperCase() : UserDetails?.email.slice(0, 1).toUpperCase()} />
                                        <p className='text-lg font-medium'>{UserDetails?.firstname !== null ? UserDetails.firstname : UserDetails?.email}</p>
                                    </div>
                                    <div className='flex flex-row w-full text-base pt-7 font-normal'>
                                        <p className='w-1/3 text-slate-400'>Display Name</p>
                                        <p className='w-full '>{UserDetails ? displayName(UserDetails.firstname, UserDetails.lastname, UserDetails.email) : "---"}</p>
                                    </div>
                                    <div className='flex flex-row w-full  text-base pt-3 font-normal'>
                                        <p className='w-1/3 text-slate-400'>Email</p>
                                        <p className='w-full'>{UserDetails?.email !== null ? UserDetails?.email : ""}</p>
                                    </div>
                                    <div className='pt-6'>
                                        <Button variant='bordered' className='text-base' size='sm' onPress={() => { onModalOpen(); setResponse(formData) }}>Edit profile</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </NextUIProvider>
                        <Theme

                            UserDetails={UserDetails}
                            formData={formData}
                            setFormData={setFormData}
                            UserId={UserId}
                            setResponse={setResponse}
                        />
                        <NextUIProvider>
                            <Card className="shadow-none border border-danger rounded-2xl p-4">
                                <CardBody>
                                    <p className='text-lg font-normal'>Danger zone</p>
                                    <p className='text-lg font-normal pt-4'>Change password</p>
                                    <p className='text-sm pt-4'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit, similique. Ullam labore ipsa magnam fuga numquam quidem blanditiis consectetur assumenda.</p>
                                    <div className='pt-4 flex flex-row gap-4'>
                                        {UserDetails && <Input
                                            size="sm"
                                            variant="flat"
                                            placeholder="Enter your password"
                                            isInvalid={formData.Password !== "" && (!PasswordRegex.test(formData.Password) || validationError) ? !PasswordRegex.test(formData.Password) || validationError : ""}
                                            errorMessage={formData.Password !== "" && (!PasswordRegex.test(formData.Password) || validationError) ? passwordError : ""}
                                            onChange={(e) => setFormData({ ...formData, Password: e.target.value })}
                                            endContent={
                                                <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                                    {isVisible ? (
                                                        <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                                    ) : (
                                                        <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                                    )}
                                                </button>
                                            }
                                            type={isVisible ? "text" : "password"}
                                            className="max-w-xs"
                                        />}
                                        {/* <Input type="password" size="sm" placeholder="enter your password." /> */}
                                        <Button
                                            isDisabled={formData.Password === ""}
                                            onClick={HandlePasswordChange}
                                            className="px-4"
                                            size='sm'
                                            color='secondary'>
                                            Change password
                                        </Button>
                                    </div>
                                    <p className='text-lg font-normal pt-8  '>Delete your account</p>
                                    <p className='text-sm pt-4'>This will permanently remove your account you will lose access to all your data inside your space.</p>
                                    <div className='pt-4'>
                                        <Button size='sm' onPress={onDeleteOpen} className='text-base' color='danger'>Delete account</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </NextUIProvider>
                    </>}
                </div>
                <ModalPopup
                    isOpen={isModalOpen}
                    onOpenChange={onModalOpenChange}
                    ModalBodyData={ModalBodyData}
                    footer={footerCreate}
                />
                <DeleteModal
                    isOpen={isDeleteOpen}
                    onOpenChange={onDeleteOpenChange}
                    deleteData={""}
                    setDeleteData={""}
                    Collectionname={"user"}
                    setLoader={setLoader}
                    DeleteHandler={DeleteHandler}
                />
            </NextThemesProvider>
        </div>
    )
}

export default AccountSettings;