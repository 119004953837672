import { Squares2X2Icon } from "@heroicons/react/24/outline";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from "@nextui-org/react";
import React, { useState } from "react";
import { CreateData } from "../../controllers/strapiController";
import { useSelector } from "react-redux";
import { spaceInfo } from "../../redux/actions";
const intialDashboardData = {
  name: "",
  description: "",
};


const CreateDashboardModal = ({ isOpen, onOpenChange, submitHandler }) => {
  const [dashboardDetails, setDashboardDetails] = useState(intialDashboardData);
  const [errorMessage, setErrorMessage] = useState(null);

  const ReduxData = useSelector(spaceInfo);
  const userDetails = JSON.parse(sessionStorage.getItem("userData"));
  const userID = userDetails && userDetails.user && userDetails.user.id;

  const changeHandler = (fieldName, value) => {
    setDashboardDetails({ ...dashboardDetails, [fieldName]: value });
  };

  const saveHandler = async (onClose) => {
    if (dashboardDetails?.name?.trim() === "") {
      setErrorMessage((prev) => ({
        ...prev,
        name: "Name is required",
      }));
      return;
    }

    const data = {
      name: dashboardDetails?.name,
      description: dashboardDetails?.description,
      users_permissions_user: [userID],
      space: [ReduxData?.payload?.space?.[0]?.id],
    };

    const result = await CreateData("dashboards", data);

    setErrorMessage(null);
    setDashboardDetails(intialDashboardData);
    onClose();
    submitHandler();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="xl"
        hideCloseButton={true}
        backdrop="opaque"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex gap-2 items-center">
                <Squares2X2Icon color="blue" className="size-6" />
                New Dashbaord
              </ModalHeader>
              <ModalBody className="flex flex-col justify-between my-4 gap-4 h-full">
                <>
                  <Input
                    type="text"
                    label={"Name"}
                    isRequired
                    placeholder={"Name of the Dashboard"}
                    variant="faded"
                    labelPlacement="outside"
                    value={dashboardDetails?.name}
                    onChange={(e) => changeHandler("name", e.target.value)}
                    isInvalid={errorMessage?.name && errorMessage?.name !== ""}
                    errorMessage={errorMessage?.name}
                  />
                  <Textarea
                    label={"Description"}
                    placeholder={"Description of the Dashboard "}
                    variant="faded"
                    labelPlacement="outside"
                    value={dashboardDetails?.description}
                    onChange={(e) =>
                      changeHandler("description", e.target.value)
                    }
                  />
                </>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onPress={() => saveHandler(onClose)}>
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateDashboardModal;
