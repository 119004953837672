import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from "@nextui-org/react";
import React, { useMemo, useState } from "react";
import { delete_document, patchDataProduct } from "../../controllers/vectordbController";
import { useNavigate, useParams } from "react-router-dom";

const DeleteModal = ({ id, isOpen, setIsOpen }) => {
  const { _spaceid } = useParams();

  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const handleDelete = async () => {
    if (val !== "DELETE") {
      return
    }
    try {
      await delete_document("anomaly", id, _spaceid);
      navigate(`/${_spaceid}/anomaly`);
    }
    catch (e) {
      console.log(e)
    }
  }
  const disabled = useMemo(() => {
    if (val === "DELETE") {
      return false;
    } else {
      return true;
    }
  }, [val]);
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} >
      <ModalContent>
        <ModalHeader>Delete Anomaly</ModalHeader>
        <ModalBody>
          <div>Type "DELETE" to permanently delete the anomaly</div>
          <Input value={val} onChange={(e) => setVal(e.target.value)} color="danger" />
        </ModalBody>
        <ModalFooter className="flex justify-end">
          <Button onClick={handleDelete} disabled={disabled} color="danger">DELETE</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const Settings = ({ anomaly, setAnomaly }) => {
  const [name, setName] = useState(anomaly?.payload?.name);
  const [desc, setDesc] = useState(anomaly?.payload?.description);
  const [isOpen, setIsOpen] = useState(false);
  const { _spaceid } = useParams();
  const handleUpdate = async () => {
    const formData = {
      name,
      description: desc,
    };
    try {
      await patchDataProduct(formData, anomaly.payload.id, _spaceid, "anomaly");
      setAnomaly((prev) => ({
        ...prev,
        payload: {
          ...prev.payload,
          ...formData,
        },
      }));
    } catch (e) { }
  };
  const disabled = useMemo(() => {
    if (name === "" || desc === "") {
      return true;
    } else {
      if (
        name === anomaly?.payload?.name &&
        desc === anomaly?.payload?.description
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [name, desc, anomaly]);
  return (
    <div className="flex flex-col gap-4">
      <DeleteModal id={anomaly.payload.id} isOpen={isOpen} setIsOpen={setIsOpen} />
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        label="Name"
        labelPlacement="outside"
      />
      <Textarea
        value={desc}
        onChange={(e) => setDesc(e.target.value)}
        label="Description"
        labelPlacement="outside"
      />
      <div className="flex justify-end">
        <Button
          onClick={handleUpdate}
          disabled={disabled}
          size="md"
          color="primary"
        >
          Save
        </Button>
      </div>
      <div className="border-danger-500 border rounded-md p-8">
        <div className="text-xl mb-8" >Delete Anomaly</div>

        <div>Warning: Once deleted, this anomaly and all its associated data will be permanently lost and cannot be recovered.</div>
        <div className="flex justify-end" >
          <Button color="danger" onClick={() => setIsOpen(true)} >Delete Anomlay</Button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
