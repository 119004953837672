import React, { useEffect, useRef, useState } from "react";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Header from "./Header";
import {
  Avatar,
  Button,
  Divider,
  Image,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  useDisclosure,
  NextUIProvider,
  Chip,
  Tooltip,
  Accordion,
  AccordionItem,
} from "@nextui-org/react";
import Continue from "../Images/Continue";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import hayalogo from "../Images/haya-logo.png";
import {
  UpdateOneUserData,
  getOneData,
  CommonCollectionFilterApi,
  InviteUser,
} from "../controllers/strapiController";
import { getAllAppDocuments } from "../controllers/vectordbController";
import Tour from "reactour";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { NavbarItems, userDropDownItems } from "../components/NavbarItems";
import Loading from "../components/Loader/Loading";
import NotFound from "../components/NotFound";
import { TourSteps } from "../components/TourSteps";
import BreadcrumbsTabs from "./BreadcrumbsTabs";
import { useDispatch, useSelector } from "react-redux";
import { documents, setData, spaceInfo } from "../redux/actions";
import Feedback from "./Feedback";
import { colorPicker } from "../controllers/basicControllers";
import CustomToast from "./CustomToast";
import { FanIcon } from "lucide-react";
import { ChevronLeft, ChevronRight } from "@geist-ui/icons";
import ModalPopup from "../pages/Collections/ModalPopup";
import toast from "react-hot-toast";

const Navbar = () => {
  //STATE INITIALIZATION
  const [UserDetails, setUserDetails] = useState(null);
  const JWT_Testing = JSON.parse(sessionStorage.getItem("userData"));
  const email = JWT_Testing && JWT_Testing.user && JWT_Testing.user.email;
  const userId = JWT_Testing && JWT_Testing.user && JWT_Testing.user.id;
  const truncatedEmail =
    email && email.length > 17 ? `${email.slice(0, 17)}...` : email;
  const Location = useLocation();
  const [tags, setTags] = useState([]);
  const Navigate = useNavigate();
  const [isOpenFir, setIsOpenFir] = useState(new Set(["1"]));
  const [isOpenSec, setIsOpenSec] = useState(new Set(["1"]));
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [userDataList, setUserData] = useState([]);

  const [documentsData, setDocuments] = useState();
  const URLPath = Location.pathname.split("/");
  const [tagInput, setTagInput] = useState('');
  const TextRef = useRef();
  const [formData, setFormdata] = useState([]);
  const [Validatetags, setValidatetags] = useState('');
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const { isOpen: isModalOpen, onOpen: onModalOpen, onOpenChange: onModalOpenChange } = useDisclosure();
  const params = useParams();
  const [validate, setValidate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputText, setInputText] = useState("");
  const title = params._spaceid;
  const [reload, setReload] = useState(null);
  const dispatch = useDispatch();
  const vectorName = "data-products";
  const navbarItems = NavbarItems(params);
  const TourListdata = TourSteps();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };
  // SPACE API CALL
  useEffect(() => {
    localStorage.removeItem("one_drive");
  }, [location]);
  useEffect(() => {
    setLoading(true);
    if (JWT_Testing === null) {
      if (location.pathname !== "") {
        Navigate(`/sign-in/redirectTo?path=${location.pathname}`);
      } else {
        Navigate("/sign-in");
      }
    }

    const payload = { Theme: "Light" };
    UpdateOneUserData("users", JWT_Testing && JWT_Testing.user.id, payload);

    const url = `filters[$or][0][admin][id][$eq]=${userId}&filters[$or][1][members][id][$eq]=${userId}&filters[Name][$in][1]=${title}&[populate][admin][on]`;
    CommonCollectionFilterApi("spaces", url)
      .then((data) => {
        setLoading(false);
        dispatch(spaceInfo(data.data));
        setValidate(data.data);
      })
      .catch((error) => {
        CustomToast(
          "Oops!, something went wrong, please try after some time.",
          "error"
        );
      });

    getAllAppDocuments(vectorName, params._spaceid)
      .then((data) => {
        setDocuments(data);
      })
      .catch((error) => error);
  }, [title, vectorName]);
  // API RESPONSE
  useEffect(() => {

    const url2 = `filters[$or][0][spaces][Name][$in]=${params._spaceid}&filters[$or][1][space][Name][$in]=${params._spaceid}&[populate][Picture][on]&[populate][spaces][on]&[populate][space][on]`
    CommonCollectionFilterApi("users", url2)
      .then((data) => {
        
        setUserData(data)
      })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
  }, [])
  // FILTERED DOCUMENTS
  useEffect(() => {
    const filterUniqueEntries = (array) => {
      const uniqueEntries = [];
      const uniqueIds = new Set();

      array?.forEach((item) => {
        item?.forEach((value) => {
          if (!uniqueIds.has(value.payload.file_document_id)) {
            uniqueIds.add(value.payload.file_document_id);
            uniqueEntries.push(value);
          }
        });
      });
      dispatch(documents(uniqueEntries));
    };
    filterUniqueEntries(documentsData);
  }, [documentsData]);

  // USER API CALL
  useEffect(() => {
    getOneData("users", JWT_Testing && JWT_Testing.user.id)
      .then((data) => {
        setUserDetails(data);
        dispatch(setData(data));
      })
      .catch((error) =>
        CustomToast(
          "Oops!, something went wrong, please try after some time.",
          "error"
        )
      );
  }, [reload]);

  const spaceData = validate && validate[0];

  // LAST SPACE ACTIVE UPDATE
  const handleSpaces = async () => {
    Navigate("/");
    if (UserDetails && UserDetails.spaces && UserDetails.spaces.length !== 1) {
      if (spaceData.attributes.SpaceName !== UserDetails.LastSpace) {
        const payload = {
          LastSpace: spaceData && spaceData.attributes.SpaceName,
        };
        await UpdateOneUserData(
          "users",
          JWT_Testing && JWT_Testing.user.id,
          payload
        );
      }
    }
  };

  // STORING LAST SPACE NAME TO STRAPI FUNCTION
  const handleToLastSpace = async () => {
    const lowerCase = UserDetails && UserDetails.LastSpace.trim().toLowerCase();
    const replaceAll = lowerCase && lowerCase.replaceAll(" ", "-");

    if (
      UserDetails &&
      UserDetails.LastSpace &&
      spaceData &&
      spaceData.attributes.SpaceName !== UserDetails.LastSpace
    ) {
      const payload = {
        LastSpace: spaceData && spaceData.attributes.SpaceName,
      };
      const response = await UpdateOneUserData(
        "users",
        JWT_Testing && JWT_Testing.user.id,
        payload
      );
      if (response) {
        Navigate(`/${replaceAll}`);
        setReload(!reload);
      }
    } else {
      Navigate(`/${replaceAll}`);
    }
  };
   // FORM VALIDATION FUNCTION
   const Validation = () => {
    let isValid = true;

    if (tags.includes(Text)) {
      setValidatetags("Entered email already exists in invite list.");
      isValid = false;
    } else if (EmailExists) {
      setValidatetags("Entered email already exists.");
      isValid = false;
    } else if (!EmailRegex.test(Text)) {
      setValidatetags("Enter a valid email.");
      isValid = false;
    } else {
      setValidatetags(null)
    }
    return isValid;
  }
  const InviteHandler = async (onClose) => {
    onClose();
    const invitations = (tags || []).map((data) => {
      const payload = {
        "to": data,
        "subject": "Invitation from Haya.",
        "html": "<html><body><p>Hello </p></body></html>"
      };
      return InviteUser(payload);
    });
    await Promise.all(invitations);
    setTags([]);
  }
  // FUNCTION TO REMOVE TAGS
  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
    setFormdata(() => ({ Tags: tags.filter(tag => tag !== tagToRemove) }))
  };
  const Text = tagInput.trim().toLowerCase();
  const EmailExists = userDataList && userDataList.some((data) => data.email === Text)
  const handleKeyPress = (e) => {
    if (tagInput.trim() !== '' && e.key === "Enter") {
      const Validate = Validation();
      if (Validate) {
        setTags((previous) => [...previous, Text]);
        setValidatetags("")
        setFormdata((previous) => ([...tags, Text]));
        setTagInput('');
      }
    }
  }
  // LOGOUT FUNCTION
  const logOutHandler = () => {
    sessionStorage.clear();
    Navigate("/sign-in");
  };

  // NAVIAGTE TO USER
  const userNavigate = () => {
    Navigate("/profile");
  };

  // TOUR ONCHANGE HANDLER
  const tourHandler = () => {
    setIsTourOpen(true);
  };

  const handleChange = () => {
    onOpen();
    setInputText("");
  };

  const userItems = userDropDownItems(
    userNavigate,
    tourHandler,
    handleChange,
    setIsTourOpen,
    logOutHandler
  );

  // FILTER CONDITION FOR USER AND TYPE
  const adminTesting = validate?.[0]?.attributes?.admin?.data?.id === userId;
  const filterAdmin =
    validate &&
    validate[0] &&
    validate[0] &&
    validate[0].attributes.type === "Enterprise";

  // Filter tour steps based on conditions
  const filteredSteps = TourListdata?.filter((step) => {
    if (!filterAdmin && step.selector === "#Teams") {
      return false;
    }
    if (!adminTesting && step.selector === "#members") {
      return false;
    }
    return true;
  });

  // NAVBAR ITEMS UI
  const navbarListItems = (data, Verified) => {
    return (
      <li key={data?.title}>
        <NavLink className=" rounded-md" to={data.path} id={data.id}>
          <span
            className={`flex p-1 items-center gap-2 rounded-md px-2 text-sm hover:bg-grey-200 text-black hover:text-foreground ${
              Verified ? "bg-grey-200 text-foreground" : ""
            }`}
          >
            {collapsed ? (
              <Tooltip content={data.title} placement="right">
                {data.icon}
              </Tooltip>
            ) : (
              data.icon
            )}
            {!collapsed && <span>{data.title}</span>}
          </span>
        </NavLink>
      </li>
    );
  };

  // NOT FOUND PAGE RENDER
  if (!loading && validate?.length === 0) {
    return <NotFound />;
  }

  // LOADER
  if (loading) {
    return (
      <div className={`absolute z-[100]  inset-0 h-screen w-screen bg-white`}>
        <Loading />
      </div>
    );
  }

  // COLOR FOR PROJECT TOUR BUTTON
  const accentColor = "#f31260";
  const ModalBodyData = () => {
    return (
      <div className='p-8'>
        <div className='text-lg'>
          Invite your team to your data mesh
        </div>
        <div className="relative pt-2">
          <div className="text-sm my-4">Enter your team members emails</div>
          <div style={{ Background: "#f4f4f5" }} className={`border rounded-xl  flex flex-col border-divider p-2`}>
            <input
              className={`bg-transparent border-0 rounded text-sm`}
              key={"outside"}
              ref={TextRef}
              style={{ outline: 'none' }}
              type="text"
              variant="bordered"
              labelPlacement={"outside"}
              placeholder="Press enter to add multiple emails"
              value={tagInput && tagInput.trim()}
              onChange={(e) => { setTagInput(e.target.value) }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleKeyPress(e)
                }
              }}
            />
            <div className="pt-4 flex flex-wrap gap-2">
              {renderTags()}
            </div>
            <p className="pt-2 text-xs text-red-400">{tags.includes(Text) || (EmailExists === true) || !EmailRegex.test(Text) ? Validatetags : " "}</p>
          </div>
        </div>
      </div>
    )
  }
  const footerCreate = (onClose) => {
    var Disable = tags && tags.length === 0;
    return <>
      <Button onClick={() => InviteHandler(onClose)} isDisabled={Disable} color="secondary">
        Invite
      </Button></>
  }
  const renderTags = () => {
    return tags && tags.map((tag, index) => (
      <Chip
        key={`${index}`}
        className=""
        size='sm'
        color={colorPicker(6)}
        variant="flat"
        onClose={() => handleTagRemove(tag)}
      >
        {tag}
      </Chip>
    ));
  };
  // RETURN UI
  return (
    <div className={`flex flex-row w-full h-screen p-2 overflow-y-auto`}>
      <Tour
        steps={filteredSteps}
        isOpen={isTourOpen}
        rounded={20}
        className="text-white"
        accentColor={accentColor}
        onRequestClose={() => setIsTourOpen(false)}
      />
      <ModalPopup
        isOpen={isModalOpen}
        onOpenChange={onModalOpenChange}
        ModalBodyData={ModalBodyData}
        footer={footerCreate}
        size="3xl"
        scrollBehavior="inside"
      />
      <div
        className={`flex flex-col justify-between h-full overflow-y-auto ${
          collapsed ? "w-16" : "w-64"
        }  dark:bg-root transition-all duration-300 ease-in-out bg-inherit px-4 dark:border-gray-600`}
      >
        <div className="flex flex-col gap-4">
          <div>
            <div
              className={`w-full ${
                collapsed ? " flex-col gap-2" : " justify-between items-center"
              } flex`}
            >
              <Dropdown placement="bottom-start">
                <DropdownTrigger>
                  <div className="flex items-center h-14  cursor-pointer">
                    <Image width={30} alt="NextUI hero Image" src={hayalogo} />
                    <a href className="font-bold">
                      {!collapsed && <span className=" ml-2">Haya</span>}
                    </a>
                    {/* <Chip size='sm' variant='flat' radius='sm'>Beta</Chip> */}
                  </div>
                </DropdownTrigger>
                <NextThemesProvider>
                  <DropdownMenu
                    aria-label="Single selection example"
                    variant="flat"
                    disallowEmptySelection
                    selectedKeys={[title]}
                  >
                    <DropdownSection>
                      <DropdownItem isDisabled={true}>
                        <p className="cursor-default">Switch to...</p>
                      </DropdownItem>
                      {UserDetails?.LastSpace && (
                        <DropdownItem
                          variant="faded"
                          onPress={handleToLastSpace}
                        >
                          {UserDetails?.LastSpace}
                        </DropdownItem>
                      )}
                      <DropdownItem
                        endContent={<FontAwesomeIcon icon={faCheck} />}
                        isDisabled={true}
                        key={title}
                        variant="faded"
                        className="mb-2"
                      >
                        {spaceData?.attributes?.SpaceName}
                      </DropdownItem>
                    </DropdownSection>
                    <DropdownSection>
                      <DropdownItem key="button" className="px-0 py-0">
                        <Button
                          size="sm"
                          className="bg-gray-dark text-slate-50 w-full"
                          onClick={() => handleSpaces()}
                        >
                          View all applications
                        </Button>
                      </DropdownItem>
                    </DropdownSection>
                  </DropdownMenu>
                </NextThemesProvider>
              </Dropdown>
              <div className={` cursor-pointer ${collapsed ? "ml-2" : ""}`}>
                <div onClick={() => Navigate(`/${params._spaceid}/settings`)}>
                  <Tooltip content="Settings">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  </Tooltip>
                </div>
              </div>
            </div>

            <nav className="mt-4 flex-1">
              <ul className="flex flex-col gap-2">
                {navbarItems &&
                  navbarItems.nav1.map((data, index) => {
                    const Verified =
                      `/${URLPath[1]}/${URLPath[2]}` === data.path;
                    return navbarListItems(data, Verified);
                  })}
              </ul>
            </nav>
          </div>
          {collapsed ? (
            <ul className="flex flex-col gap-2 mt-12 ">
              {navbarItems?.nav2?.map((data, index) => {
                const Verified = `/${URLPath[1]}/${URLPath[2]}` === data.path;
                return navbarListItems(data, Verified);
              })}
            </ul>
          ) : (
            <Accordion defaultExpandedKeys={["1"]}>
              <AccordionItem
                selectedKeys={isOpenFir}
                onSelectionChange={setIsOpenFir}
                indicator={
                  <svg
                    className={`w-3 h-3 transform transition-transform duration-300 ${
                      Array.from(isOpenFir)?.[0] === "1"
                        ? "rotate-90"
                        : "rotate-0"
                    }`}
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5 8L0.669872 0.5H9.33013L5 8Z" fill="#575859" />
                  </svg>
                }
                title="Workspace"
                key={"1"}
              >
                <ul className="flex flex-col gap-2">
                  {navbarItems?.nav2?.map((data, index) => {
                    const Verified =
                      `/${URLPath[1]}/${URLPath[2]}` === data.path;
                    return navbarListItems(data, Verified);
                  })}
                </ul>
              </AccordionItem>
            </Accordion>
          )}

          {collapsed ? (
            <ul className="flex flex-col gap-2 mt-12">
              {navbarItems?.nav3?.map((data, index) => {
                const Verified = `/${URLPath[1]}/${URLPath[2]}` === data.path;
                const adminTesting =
                  validate?.[0]?.attributes?.admin?.data?.id === userId;
                const filterSpace = URLPath[1] === "haya";
                if (data.title === "Users" && adminTesting) {
                  return navbarListItems(data, Verified);
                }
                if (data.title === "Templates" && filterSpace) {
                  return navbarListItems(data, Verified);
                }
                if (data.title === "Playground" && filterSpace) {
                  return navbarListItems(data, Verified);
                }
                if (
                  data.title !== "Users" &&
                  data.title !== "Templates" &&
                  data.title !== "Playground"
                ) {
                  return navbarListItems(data, Verified);
                }

                return null;
              })}
              <li  key={"invite"}>
                <span
                  onClick={() => { onModalOpen(); setTagInput('') }}
                  className={`flex p-1 cursor-pointer items-center gap-2 rounded-md px-2 text-sm hover:bg-grey-200 text-black hover:text-foreground `}
                >
                  {collapsed ? (
                    <Tooltip content="Invite" placement="right">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </Tooltip>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  )}
                  {!collapsed && <span>Invite</span>}
                </span>
              </li>
            </ul>
          ) : (
            <Accordion defaultSelectedKeys={["1"]}>
              <AccordionItem
                selectedKeys={isOpenSec}
                onSelectionChange={setIsOpenSec}
                indicator={
                  <svg
                    className={`w-3 h-3 transform transition-transform duration-300 ${
                      Array.from(isOpenSec)?.[0] === "1"
                        ? "rotate-90"
                        : "rotate-0"
                    }`}
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5 8L0.669872 0.5H9.33013L5 8Z" fill="#575859" />
                  </svg>
                }
                title="Try"
                key={"1"}
              >
                <ul className="flex flex-col gap-2">
                  {navbarItems?.nav3?.map((data, index) => {
                    const Verified =
                      `/${URLPath[1]}/${URLPath[2]}` === data.path;
                    const adminTesting =
                      validate?.[0]?.attributes?.admin?.data?.id === userId;
                    const filterSpace = URLPath[1] === "haya";
                    if (data.title === "Users" && adminTesting) {
                      return navbarListItems(data, Verified);
                    }
                    if (data.title === "Templates" && filterSpace) {
                      return navbarListItems(data, Verified);
                    }
                    if (data.title === "Playground" && filterSpace) {
                      return navbarListItems(data, Verified);
                    }
                    if (
                      data.title !== "Users" &&
                      data.title !== "Templates" &&
                      data.title !== "Playground"
                    ) {
                      return navbarListItems(data, Verified);
                    }

                    return null;
                  })}
                  <li key={"invite"}>
                    <span
                      onClick={() => { onModalOpen(); setTagInput('') }}
                      className={`flex p-1 cursor-pointer items-center gap-2 rounded-md px-2 text-sm hover:bg-grey-200 text-black hover:text-foreground `}
                    >
                      {collapsed ? (
                        <Tooltip content="Invite" placement="right">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4.5v15m7.5-7.5h-15"
                            />
                          </svg>
                        </Tooltip>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                          />
                        </svg>
                      )}
                      {!collapsed && <span>Invite</span>}
                    </span>
                  </li>
                </ul>
              </AccordionItem>
            </Accordion>
          )}
        </div>

        {collapsed ? (
          <div className="mb-4">
            <Button isIconOnly size="sm" onClick={toggleNavbar} variant="light">
              <ChevronRight />
            </Button>
          </div>
        ) : (
          <Button
            isIconOnly
            size="sm"
            className="mb-4"
            onClick={toggleNavbar}
            variant="light"
          >
            <ChevronLeft />
          </Button>
        )}
      </div>

      <div className="w-full flex flex-col overflow-y-auto border border-divider bg-white rounded-xl ">
        <Header
          UserDetails={UserDetails}
          params={params}
          userItems={userItems}
        />
        <div className=" h-[calc(100vh-60px)] overflow-auto ">
          <Outlet />
        </div>
      </div>
      <NextThemesProvider>
        <Feedback
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          inputText={inputText}
          setInputText={setInputText}
        />
      </NextThemesProvider>
    </div>
  );
};

export default Navbar;
