// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#tabsData > div {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}

.bg-overlay\\/50 {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px; /* Vertical scrollbar width */
  height: 8px; /* Horizontal scrollbar height */
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
  border-radius: 4px;
  border: 2px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #718096;
}

::-webkit-scrollbar-thumb:active {
  background-color: #4a5568;
}

::selection {
  background-color: #7bffe1; /* Neon light teal */
  color: #000; /* Optional: Set the text color if you want it to contrast well with the background */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,cAAc;EACd,qCAAqC;AACvC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,UAAU,EAAE,6BAA6B;EACzC,WAAW,EAAE,gCAAgC;AAC/C;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB,EAAE,oBAAoB;EAC/C,WAAW,EAAE,qFAAqF;AACpG","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');\n\n\nbody {\n  margin: 0;\n  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n#tabsData > div {\n  color: #ffffff;\n  font-family: 'Montserrat', sans-serif;\n}\n\n.bg-overlay\\/50 {\n  background-color: transparent;\n}\n\n::-webkit-scrollbar {\n  width: 8px; /* Vertical scrollbar width */\n  height: 8px; /* Horizontal scrollbar height */\n}\n\n::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #a0aec0;\n  border-radius: 4px;\n  border: 2px solid transparent;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #718096;\n}\n\n::-webkit-scrollbar-thumb:active {\n  background-color: #4a5568;\n}\n\n::selection {\n  background-color: #7bffe1; /* Neon light teal */\n  color: #000; /* Optional: Set the text color if you want it to contrast well with the background */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
