import { Avatar, Button, Input, Select, SelectItem } from '@nextui-org/react';
import React, { useState, useEffect } from 'react';
import HayaLogo from "../../../Images/haya-logo.png";
import OllamaLogo from "../../../Images/ollama-logo.png";
import OpenAILogo from "../../../Images/openAI.png";
import { InfoIcon } from '../../../components/InfoIcon';
import { modalValidator } from '../../../Utils/TemplateValidation';


const ChooseModel = ({ setFormValues, formValues, onPrevious, onNext }) => {
    const [model, setModel] = useState({});
    const [LanguageModel, setLanguageModel] = useState('');
    const [validate, setValidate] = useState({});

    useEffect(() => {
        switch (LanguageModel) {
            case 'Ollama':
                setModel({ OllamaModel: '', HostAddress: '', PortNumber: '' });
                break;
            case 'OpenAI':
                setModel({ OpenAIKey: '' });
                break;
            case 'Haya':
                setModel({properties:[]});
                break;
            default:
                setModel({});
        }
    }, [LanguageModel])


    const openAI_json = {
        "properties": [
            {
                "name": "OpenAI Key",
                "type": "password",
                "key": "OpenAIKey",
                "placeholder": "Enter OpenAI key"
            },
        ]
    };


    const haya_json = {
        "properties": []
    };

    const ollama_json = {
        "properties": [
            {
                "name": "Model",
                "type": "dropdown",
                "values": ["mistral", "llama3.1", "mistral-small", "gemma2", "phi"],
                "key": "OllamaModel",
                "placeholder": "Choose a model"
            },
            {
                "name": "Enter host address",
                "type": "input",
                "key": "HostAddress",
                "placeholder": "Enter host address"
            },
            {
                "name": "Enter port number",
                "type": "input",
                "key": "PortNumber",
                "placeholder": "Enter port number"

            }
        ]
    };

    const onChange=(event)=>{
        const { name, value } = event.target;
        setModel(prev=>({...prev,[name]:value}))
    }

    const onchangeHandler = (event) => {
        const { name, value } = event.target;
        setLanguageModel(value);
    }

    const modalHandler =()=>{

        const validate=modalValidator(model);
        setValidate(validate);
        if(validate?.isValid){
            setFormValues(prev => ({ ...prev, modelJSON: { Language: LanguageModel, properties: [model] }}));
            onNext();
        }
    }

    // Function to create form inputs based on JSON data
    const formCreator = (JsonData) => {
        return (
            <div className='flex flex-col gap-8 pt-8'>
                {JsonData?.properties?.map((data) => (
                    data.type === "dropdown" ? (
                        <Select
                            key={data.key}
                            name={data.key}
                            labelPlacement='outside'
                            placeholder={data.placeholder}
                            label={data.name}
                            onChange={onChange}
                            value={model[data.key] || ''}
                            errorMessage={model[data.key]===''&&validate?.errors?.[data.key]}
                            isInvalid={model[data.key]===''&& !!validate?.errors?.[data.key]}
                        >
                            {data.values?.map((option) => (
                                <SelectItem key={option} value={option}>
                                    {option}
                                </SelectItem>
                            ))}
                        </Select>
                    ) : (
                        <Input
                            labelPlacement='outside'
                            key={data.key}
                            type={data.type === "password" ? "password" : "text"}
                            label={data.name}
                            placeholder={data.placeholder}
                            name={data.key}
                            value={model[data.key] || ''} // Use the current form value
                            onChange={onChange}
                            errorMessage={model[data.key]===''&&validate?.errors?.[data.key]}
                            isInvalid={model[data.key]===''&& !!validate?.errors?.[data.key]}
                        />
                    )
                ))}
            </div>
        );
    };

    // Render form based on the selected model
    const renderForm = () => {
        switch (LanguageModel) {
            case 'Ollama':
                return <>
                    <div className='mt-4'>
                        {formCreator(ollama_json)}
                    </div>
                </>
            case 'OpenAI':
                return <>
                    <div className='mt-4'>
                        {formCreator(openAI_json)}
                    </div>
                </>
            case 'Haya':
                return <>
                    <div className='flex items-center text-xs gap-2 mt-2'>
                        <InfoIcon />
                        <span className='font-light'>
                            Important: Running your Agent on Haya will impact your credit usage.
                        </span>
                    </div>
                </>
            default:
                return null;
        }
    };

    return (
        <div className='p-8'>
            <div className='text-3xl font-medium'>Choose a model</div>
            <div className='mt-4 leading-relaxed'>
                Choose your Language Model which you would like your agent to run on, tailoring its abilities to process data and perform actions according to your application's unique needs.
            </div>

            <div className='mt-16'>
                <Select
                    label="Language Model"
                    placeholder='Select your Language Model'
                    labelPlacement='outside'
                    onChange={onchangeHandler}
                    value={LanguageModel} // Set the value from state
                    isRequired
                >
                    <SelectItem value="Ollama" key="Ollama" textValue="Ollama">
                        <div className='flex gap-4 items-center'>
                            <Avatar className='h-[32px] w-[32px]' src={OllamaLogo} />
                            <div>
                                <div>Ollama</div>
                                <span className="text-default-500 text-tiny">
                                    Run your mini-agent using your deployed Ollama Model
                                </span>
                            </div>
                        </div>
                    </SelectItem>

                    <SelectItem value="OpenAI" key="OpenAI" textValue="OpenAI">
                        <div className='flex gap-4 items-center'>
                            <Avatar className='h-[32px] w-[32px]' src={OpenAILogo} />
                            <div>
                                <div>OpenAI</div>
                                <span className="text-default-500 text-tiny">
                                    Run your mini-agent using OpenAI
                                </span>
                            </div>
                        </div>
                    </SelectItem>

                    <SelectItem value="Haya" key="Haya" textValue="Haya">
                        <div className='flex gap-4 items-center'>
                            <Avatar className='h-[32px] w-[32px]' src={HayaLogo} />
                            <div>
                                <div>Haya</div>
                                <span className="text-default-500 text-tiny">
                                    Run your mini-agent using Haya
                                </span>
                            </div>
                        </div>
                    </SelectItem>
                </Select>
            </div>

            {/* Render the appropriate form based on the selected model */}

            {renderForm()}

            <div className='mt-8 flex gap-4 justify-end'>
                <Button onClick={onPrevious} variant='light'>Previous</Button>
                <Button isDisabled={LanguageModel?false:true} onClick={modalHandler} color='secondary' variant='flat'>Next</Button>
            </div>
        </div>
    );
}

export default ChooseModel;
