import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OauthMicrosoftToken } from "../../controllers/OAuthControllers";

const MicrosoftRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");

    const fetchToken = async() => {
        try{
            await OauthMicrosoftToken(code);
        }
        catch(e){
            console.log(e)
        }
    }

    if (code) {
      // Handle the code, e.g., send it to the server to exchange for tokens
        fetchToken();

    }
  }, [navigate]);

  return <div>Processing Microsoft Authentication...</div>;
};

export default MicrosoftRedirect;
