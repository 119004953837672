export const getSQL = async(sql,type,dbConfig) => {
    const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/query`;
    const payload = {
        "sql": sql,
        "dbType": type,
        "dbConfig": dbConfig
    };
    console.log("payload",payload)

    const resp = await fetch(URL,{method : "POST",headers: {
        "Content-Type": "application/json", // Ensure the server knows to parse JSON
      }, body : JSON.stringify(payload)});
    const data = await resp.json();
    if(!resp.ok){
        throw new Error(data?.error || "Unknown Error");
    }
    return data;
}