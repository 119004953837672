import React, { useEffect, useState } from "react";
import { CommonCollectionFilterApi } from "../../controllers/strapiController";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  Table,
} from "@nextui-org/react";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import NullData from "../../components/NullData";

const RecentDashboard = ({}) => {
  const [allRecentDashboardList, setAllRecentDashboardList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  const navigate = useNavigate();
  const headers = ["Name", "Description", "Created By"];
  useEffect(() => {
    CommonCollectionFilterApi(
      "dashboards",
      `filters[space][Name][$eq]=${params._spaceid}&[populate][users_permissions_user][on]&sort[0]=updatedAt:desc&pagination[page]=1&pagination[pageSize]=4`
    ).then((data) => {
      console.log(data);

      setAllRecentDashboardList(data?.data || []);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      {!isLoading && (
        <Table removeWrapper selectionMode="single">
          <TableHeader>
            {headers.map((header, headerIndex) => (
              <TableColumn key={headerIndex}>{header}</TableColumn>
            ))}
          </TableHeader>
          <TableBody emptyContent={<NullData title={`No Dashboard Found.`} />}>
            {allRecentDashboardList.map((item, index) => {
              const userAvatarName =
                item?.attributes?.users_permissions_user?.data?.attributes
                  ?.email || "Unknown";

              return (
                <TableRow
                  key={item?.id}
                  onClick={() => {
                    navigate(`dashboards/${item.id}`);
                  }}
                  className="cursor-pointer"
                >
                  <TableCell className="flex items-center gap-1">
                    <Squares2X2Icon color="blue" className="size-4" />
                    <span>{item?.attributes?.name}</span>
                  </TableCell>
                  <TableCell>
                    <span>{item?.attributes?.description}</span>
                  </TableCell>
                  <TableCell>
                    <Tooltip content={userAvatarName} showArrow={true}>
                      <Avatar name={userAvatarName[0]} className="w-5 h-5" />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default RecentDashboard;
