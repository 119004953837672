import React, { useState, useEffect } from 'react';
import { delete_document, getAllDocuments } from "../../controllers/vectordbController";
import { getAllData } from "../../controllers/strapiController";
import { stringCreater,StringLimit,getTimeDifference } from "../../controllers/basicControllers";
import { Dropdown, DropdownTrigger, DropdownItem, DropdownMenu, Chip, Card, CardHeader, CardBody, Image, useDisclosure } from '@nextui-org/react'
import { data } from "../../pages/Connectors/ConnectorData";
import { useNavigate, useParams } from "react-router-dom";
import { cardSkeleton } from "../../components/Skeleton";
import DeleteModal from "../../components/DeleteModal";
import { useSelector } from "react-redux";
import { filterUniqueEntries } from '../../controllers/basicControllers';
import EmptyArray from "../../components/EmptyArray";
import {toast} from "react-hot-toast";
import {spaceData} from "../../redux/actions";
import CustomToast from '../../components/CustomToast';

const SourceDocuments = () => {
  // STATE INITIALIZATION
  const [documents, setDocuments] = useState([]);
  const [loader, setLoader] = useState(true);
  const [reload, setReload] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState('');
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
  const params = useParams();
  const reduxdata = useSelector(spaceData);
  const vectorname = reduxdata?.[0]?.attributes?.VerctorDBCollection
  
  // API RENDER
  useEffect(() => {
    setLoader(true);
          getAllDocuments(vectorname, params._id, 
            reduxdata[0]?.attributes?.SpaceName
          )
      .then((data) => { setLoader(false); setDocuments(data) })
      .catch((error) => { setLoader(false) })
  }, [params._id, reload])

  // DOCUMENT FILTER
  const uniqueDataArray = documents && filterUniqueEntries(documents);
  const Navigate = useNavigate();
  // NAVIGATION FOR PERTICULAR DOCUMENT
  const NavigateHandler = (id) => {
    Navigate(`/${params._spaceid}/doc/${id}`)
  }

  // LIST OF USERS RENDERING
  useEffect(() => {
    getAllData("users")
      .then((data) => setUsers(data))
      .catch((error) => CustomToast( "Oops!, something went wrong, please try after some time.","error"))
  }, [])

  // LOADING SKELETON
  if (loader) {
    return cardSkeleton()
  }

  // HANDLER TO OPEN DELETE WARNING
  const handleDeleteOpen = (id) => {
    onDeleteOpen();
    setSelectedDoc(id);
  }

  // DELETE DOCUMENT FUNCTION
  const deleteHandler = async () => {
    const response = await delete_document(vectorname, selectedDoc, params._spaceid)

    if (response) {
      setReload(response)
    }
  }

  // RETURN UI
  return (
    <>
      {uniqueDataArray && uniqueDataArray.length > 0 ?
        <div className='grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-3'>
          {uniqueDataArray && uniqueDataArray.map((item) => {
            const userName = users && users.find((data) => JSON.stringify(data.id) === item.payload.user_id)
            const filteredData = data && data.find((arr) => arr.value === item.payload.source);
            return <div onClick={() => NavigateHandler(item.payload.file_document_id)} className='max-w-[550px] w-full'>
              <Card className="max-w-[550px] min-h-[245px] min-w-full w-full h-full border border-divider cursor-pointer " shadow='none'>
                <CardHeader className="flex gap-3 justify-between px-4 pt-4">
                  <div className='flex justify-between gap-3'>
                    <Image
                      alt="nextui logo"
                      height={40}
                      radius="sm"
                      src={filteredData && filteredData.avatar}
                      width={40}
                    />
                    <div className="flex flex-col">
                      <p className="text-md">{item.payload.document_title}</p>
                      <div className='text-sm text-warmGray-500'>
                        {userName && stringCreater(userName.firstname, userName.email)} • Uploaded {getTimeDifference(item.payload.file_document_id)}
                      </div>
                    </div>
                  </div>
                  <Dropdown placement="bottom-end">
                    <DropdownTrigger>
                      <div className='flex items-center justify-evenly cursor-pointer'>
                        <span className='text-md font-bold text-slate-500'>...</span>
                      </div>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Profile Actions" variant="flat">
                      <DropdownItem key="edit">
                        <div className='flex items-center gap-2'>
                          <lord-icon
                            src="https://cdn.lordicon.com/pflszboa.json"

                            style={{ width: "16px", height: "16px" }}>
                          </lord-icon>
                          Edit
                        </div>
                      </DropdownItem>
                      <DropdownItem key="system">
                        <div className='flex items-center gap-2'>
                          <lord-icon
                            src="https://cdn.lordicon.com/vuiggmtc.json"

                            trigger="hover"
                            style={{ width: "16px", height: "16px" }}>
                          </lord-icon>
                          Archive
                        </div>
                      </DropdownItem>
                      <DropdownItem onClick={() => handleDeleteOpen(item.payload.id)} key="configurations" className='text-danger' color="danger">
                        <div className='flex items-center gap-2'>
                          <lord-icon
                            src="https://cdn.lordicon.com/wpyrrmcq.json"
                            colors="primary:#e1426c"
                            style={{ width: "16px", height: "16px" }}>
                          </lord-icon>
                          Delete
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </CardHeader>
                <CardBody className='flex justify-between'>
                 
                  <div className='flex gap-4'>
                    {item && item.payload.user_tags && item.payload.user_tags.length > 0 && item.payload.user_tags.map((data) => (
                      <Chip
                        size='sm'
                        color={filteredData && filteredData.color}
                        variant="flat">
                        {data}
                      </Chip>))}
                  </div>
                  <div className=' mb-4'>
                    <p>{item && item.payload && item.payload.description && StringLimit(item.payload.description, 190)}</p>
                  </div>
                </CardBody>
              </Card>
            </div>
          })}
        </div>
        : <EmptyArray message={"No documents inside collection folder. Click on upload documents to upload your documents."} />}
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteData={""}
        setDeleteData={""}
        Collectionname={"document"}
        setLoader={setLoader}
        DeleteHandler={deleteHandler}
      />
    </>
  )
}

export default SourceDocuments;