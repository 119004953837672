import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
  Image,
  Input,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
} from "@nextui-org/react";
import React, { useEffect, useState, useRef } from "react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import {
  getOneData,
  CommonCollectionFilterApi,
} from "../../controllers/strapiController";
import { StringLimit } from "../../controllers/basicControllers";
import SearchIcon from "../../Images/Search";
import { knowledgeBaseSkeleton } from "../../components/Skeleton";
import { useParams, useNavigate } from "react-router-dom";
import RecentDataProductTable from "./RecentDataProductTable";
import doc_image from "../../Images/docs_logo.png";
import { useSelector } from "react-redux";
import { spaceData } from "../../redux/actions";
import { getAllDocs } from "../../controllers/vectordbController";
import { formatTimestamp } from "../../Utils/helper";
import UserAvatar from "../../components/UserAvatar";
import RecentDashboard from "./RecentDashboard";

const KnowledgeBase = () => {
  // INITIALIZATION
  const [UserDetails, setUserDetails] = useState(null);
  const [InputText, setInputText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [AllCollections, setAllCollections] = useState(null);
  const [showResults, setShowResults] = useState(true);
  const collectionName = "collections";
  const { _spaceid } = useParams();
  const [Loader, setLoader] = useState(true);
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const params = useParams();
  const spaceInfo = useSelector(spaceData);
  const [docs, setDocs] = useState([]);
  const navigate = useNavigate();

  // USER API
  useEffect(() => {
    setLoader(true);
    getOneData("users", UserId).then((data) => {
      setLoader(false);
      setUserDetails(data);
    });
  }, [UserId]);

  // API FOR COLLECTIONS RESPONSE
  useEffect(() => {
    const url = `filters[space][Name][$eq]=${params._spaceid}&[populate][author][on]&[populate][inviteds][on]&[populate][viewed][on]&[populate][requests][on]&[populate][teams][on]&[populate][space][on]`;
    CommonCollectionFilterApi(collectionName, url)
      .then((data) => setAllCollections(data.data))
      .catch((err) => err);
  }, [collectionName]);

  // SEARCH INPUT VALUE ONCHANGE EVENT
  const HandleChange = (event) => {
    const TextValue = event.target.value;
    setInputText(TextValue);
    const Filterdata =
      AllCollections &&
      AllCollections.filter(
        (data) =>
          data.attributes &&
          data.attributes.Name.toLowerCase().includes(TextValue.toLowerCase())
      );
    setSearchResults(Filterdata);
  };

  function filterUniqueById(array) {
    const seenIds = new Set([]);
    return array.filter((item) => {
      if (seenIds.has(item.payload.id)) {
        return false; // Exclude duplicate id
      }
      seenIds.add(item.payload.id); // Mark id as seen
      return true; // Include unique id
    });
  }
  // HANDLE ENTER KEY
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && InputText.trim() !== "") {
      navigate(`/${params._spaceid}/search/${InputText}`);
      setInputText("");
    }
  };

  const resultsRef = useRef(null);

  // SCROLL HANDLER
  useEffect(() => {
    function handleClickOutside(event) {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setShowResults(!showResults);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [resultsRef, showResults]);

  useEffect(() => {
    setShowResults(true);
  }, [InputText]);
  console.log("response--->", docs);

  useEffect(() => {
    const getAllDoc = async () => {
      try {
        const resp = await getAllDocs(
          spaceInfo[0]?.attributes?.SpaceName,
          spaceInfo[0]?.attributes?.VerctorDBCollection
        );
        // const resp = await getAllDocs("test-harshit", "test-harshit");
        const finalDocs = filterUniqueById(resp[0])
          .sort((a, b) => Number(a.payload.id) < Number(b.payload.id))
          .slice(0, 4);
        setDocs(finalDocs);
        setLoader(false);
      } catch (error) {
        console.log("err", error);
      }
    };

    if (
      spaceInfo.length > 0 &&
      spaceInfo[0]?.attributes?.Name &&
      spaceInfo[0]?.attributes?.VerctorDBCollection
    ) {
      getAllDoc();
    }
  }, [spaceInfo]);
  return (
    <div className="mx-8 mb-4">
      <NextThemesProvider defaultTheme={"light"}>
        {Loader ? (
          knowledgeBaseSkeleton()
        ) : (
          <>
            <div>
              {/* <BreadcrumbsTabs /> */}
              <div className="mt-8 text-3xl">
                Hello{" "}
                {UserDetails && UserDetails.firstname !== null
                  ? UserDetails.firstname
                  : UserDetails && UserDetails.email}
                ,
              </div>
              <div className="relative w-full pt-8">
                <div className="w-full relative">
                  <Input
                    classNames="w-full"
                    onChange={HandleChange}
                    value={InputText}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleKeyPress(event);
                      }
                    }}
                    placeholder="Search by files, tags, content..."
                    startContent={
                      <SearchIcon
                        className={`text-large text- pointer-events-none flex-shrink-0 `}
                      />
                    }
                  />
                </div>
                {InputText !== "" ? (
                  <>
                    {" "}
                    {showResults && (
                      <div className="transition ease-in-out delay-200 absolute z-50 w-full pt-2 ">
                        <div
                          ref={resultsRef}
                          className={`rounded-xl border border-divider divide-y divide-divider overflow-y-auto min-h-16 bg-white`}
                          style={{ maxHeight: "325px" }}
                        >
                          <div
                            className="p-4"
                            onClick={() => {
                              navigate(
                                `/${params._spaceid}/search/${InputText}`
                              );
                              setInputText("");
                            }}
                          >
                            <p
                              id="File"
                              className="flex flex-row items-center cursor-pointer text-foreground gap-2"
                            >
                              <lord-icon
                                src="https://cdn.lordicon.com/kkvxgpti.json"
                                trigger="hover"
                                colors={`primary:#000000`}
                                style={{ width: "20px", height: "20px" }}
                              ></lord-icon>{" "}
                              Search for "{InputText}"
                            </p>
                          </div>
                          {searchResults && searchResults.length > 0 ? (
                            <>
                              {searchResults &&
                                searchResults.map((data, index) => {
                                  const name = data.attributes.Name;
                                  const regex = new RegExp(
                                    `(${InputText})`,
                                    "i"
                                  );
                                  const parts = name.split(regex);
                                  const highlightedIndex = parts.findIndex(
                                    (part) =>
                                      part.toLowerCase() ===
                                      InputText.toLowerCase()
                                  );

                                  return (
                                    <div
                                      key={`${index}`}
                                      className="p-4 cursor-pointer"
                                      onClick={() => {
                                        navigate(
                                          `/${params._spaceid}/search/${data.attributes.Name}`
                                        );
                                        setInputText("");
                                      }}
                                    >
                                      <p
                                        id="File"
                                        className="flex flex-row items-center gap-1"
                                      >
                                        <lord-icon
                                          src="https://cdn.lordicon.com/jkzgajyr.json"
                                          trigger="hover"
                                          target="#File"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            paddingRight: "8px",
                                          }}
                                        ></lord-icon>
                                        <p>
                                          {parts.map((part, i) => {
                                            const NewFormatData =
                                              part.endsWith(" ") && `${part} `;
                                            return (
                                              <span key={`${i}`}>
                                                {i === highlightedIndex ? (
                                                  <span className="bg-indigo-200 text-black">
                                                    {part}
                                                  </span>
                                                ) : (
                                                  <span className="whitespace-nowrap">
                                                    {NewFormatData ? (
                                                      <>
                                                        {part.trim()}
                                                        <span className="invisible">
                                                          _
                                                        </span>
                                                      </>
                                                    ) : (
                                                      part
                                                    )}
                                                  </span>
                                                )}
                                              </span>
                                            );
                                          })}
                                        </p>
                                      </p>
                                      <p className="text-slate-400 text-sm">
                                        {data.attributes.Description &&
                                          StringLimit(
                                            data.attributes.Description,
                                            250
                                          )}
                                      </p>
                                    </div>
                                  );
                                })}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mt-8">
              <div className="flex gap-8 justify-between">
                <Card
                  className="py-4 w-[300px] h-[370px] border border-divider"
                  shadow="none"
                >
                  <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                    {/* <p className="text-tiny uppercase font-bold">Documentation</p> */}
                    <div className="flex items-center">
                      <small className="text-default-500">Documentation</small>
                      <Tooltip content="View Documentation">
                        <Link
                          showAnchorIcon
                          color="danger"
                          className="cursor-pointer"
                        ></Link>
                      </Tooltip>
                    </div>
                    <h4 className="font-bold text-large">
                      Building efficient data meshes
                    </h4>
                  </CardHeader>
                  <CardBody className="overflow-visible py-2 flex items-center">
                    <Image
                      alt="Card background"
                      className="object-cover rounded-xl"
                      src={doc_image}
                      width={240}
                    />
                  </CardBody>
                </Card>

                <Card
                  className="py-4 flex-1  h-[370px] border border-divider"
                  shadow="none"
                >
                  <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                    <p className="text-tiny uppercase font-bold flex items-center gap-4">
                      Resources uploaded
                      <Tooltip content="View more documents">
                        <Link
                          showAnchorIcon
                          color="danger"
                          className="cursor-pointer"
                        ></Link>
                      </Tooltip>
                    </p>
                  </CardHeader>
                  <CardBody className="overflow-visible pt-8">
                    <Table
                      removeWrapper
                      aria-label="Example static collection table"
                    >
                      <TableHeader>
                        <TableColumn>Document</TableColumn>
                        <TableColumn>Uploaded at</TableColumn>
                        <TableColumn>Document uploded by</TableColumn>
                      </TableHeader>
                      <TableBody>
                        {docs?.map((data, index) => (
                          <TableRow
                            onClick={() =>
                              window.open(data?.payload?.document_url, "_blank")
                            }
                            key={index}
                            className="cursor-pointer hover:bg-grey-200"
                          >
                            <TableCell>
                              <div className="flex gap-4 items-center">
                                <Avatar
                                  className="w-[30px] h-[30px]"
                                  size="sm"
                                  radius="sm"
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Adobe_Acrobat_DC_logo_2020.svg/768px-Adobe_Acrobat_DC_logo_2020.svg.png"
                                />
                                {data.payload.document_title}
                              </div>
                            </TableCell>
                            <TableCell>
                              {formatTimestamp(
                                Number(data?.payload?.file_document_id)
                              )}
                            </TableCell>
                            <TableCell>
                              <UserAvatar id={data?.payload?.user_id} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="mt-12 flex justify-between items-center gap-8">
              <Card
                className="py-4 w-[600px] h-[300px] border border-divider"
                shadow="none"
              >
                <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                  <p className="text-tiny uppercase font-bold">
                    Recent data products
                  </p>
                </CardHeader>
                <CardBody className="overflow-visible p-0">
                  <RecentDataProductTable />
                </CardBody>
              </Card>
              <Card
                className="py-4 flex-1 h-[300px] border border-divider"
                shadow="none"
              >
                <CardHeader className="pb-0 pt-2 px-4 items-center gap-4">
                  <p className="text-tiny uppercase font-bold">Dashboards</p>
                  <Tooltip content="View Dashboards">
                    <Link
                      href={`/${_spaceid}/dashboards`}
                      showAnchorIcon
                      color="danger"
                      className="cursor-pointer"
                    ></Link>
                  </Tooltip>
                </CardHeader>
                <CardBody>
                  <RecentDashboard />
                </CardBody>
              </Card>
            </div>
          </>
        )}
      </NextThemesProvider>
    </div>
  );
};

export default KnowledgeBase;
