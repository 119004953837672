import React, { useEffect, useRef, useState } from "react";
import RightPane from "../Playground/RightPane";
import { RightArrow } from "../../Images/RightArrow";
import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Input,
  Tab,
  Tabs,
  Textarea,
} from "@nextui-org/react";
import { useSelector } from "react-redux";
import { spaceData, userData } from "../../redux/actions";
import { getFilteredRelationData } from "../../controllers/strapiController";
import Logo from "../../Images/haya-logo.png";
import SaveModal from "./SaveModal";
import { ThreadManager } from "../../Utils/ThreadSocket";
import { colorPicker } from "../../controllers/basicControllers";
import { renderBoldText } from "../../Utils/renderingMethods";

const CreateAnomaly = () => {
  const spaceInfo = useSelector(spaceData);
  const userInfo = useSelector(userData);
  const [connectors, setConnectors] = useState([]);
  const [selectedConnector, setSelectedConnector] = useState(new Set([]));
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [thread, setThread] = useState([]);
  const [loading, setLoading] = useState(false);
  const [val, setVal] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const handleKeyPress = () => {
    if (val.trim() !== "") {
      setVal("");
      ThreadManager.getInstance(
        setThread,
        spaceInfo[0]?.attributes?.SpaceName,
        spaceInfo[0]?.attributes?.VerctorDBCollection
      ).sendMessage(val.trim(), setThread, userInfo.id);
    }
  };
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }

    setLoading(true);
    const disableTimeout = setTimeout(() => setLoading(false), 1000);

    return () => {
      clearTimeout(disableTimeout);
    };
  }, [thread]);
  useEffect(() => {
    const fetchConnectors = async () => {
      try {
        const resp = await getFilteredRelationData(
          "collections",
          "space",
          spaceInfo[0].id,
          "Type",
          "connectors"
        );
        setConnectors(resp.data);
        console.log("collector", resp.data);
      } catch (e) {
        console.log(e);
      }
    };
    if (spaceInfo?.[0]?.id) {
      fetchConnectors();
    }
  }, [spaceInfo]);
  // First get all collections

  // Value to choose the collection

  // Create a new database for the

  return (
    <div className="p-8 h-full flex flex-col overflow-y-auto">
      <SaveModal
        dataProduct={connectors.find(
          (it) => String(it.id) === String(Array.from(selectedConnector)[0])
        )}
        desc={desc}
        setDesc={setDesc}
        name={name}
        setName={setName}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div className="flex justify-between ">
        <div>
          <div className="text-3xl font-medium">Create an anomaly detector</div>
          <div className="mt-4 leading-relaxed">
            Detect anomalies in your data products with Haya, enhancing your
            data mesh to provide more accurate insights.
          </div>
        </div>
        <Button
          disabled={Array.from(selectedConnector).length === 0}
          onClick={() => setIsOpen(true)}
          color="primary"
        >
          {" "}
          Save Anomaly
        </Button>
      </div>

      <div className=" flex-1 flex gap-4  mt-4 w-full h-full rounded-md overflow-y-auto">
        {/* Query Card */}

        <div className="p-4 rounded-lg flex flex-col overflow-y-auto gap-4 border-divider border w-2/5">
          <div className="flex justify-end">
            <Dropdown>
              <DropdownTrigger>
                <Button variant="light">
                  {Array.from(selectedConnector).length === 0 ? (
                    "Select Connector"
                  ) : (
                    <div className="flex items-center gap-1">
                      <Avatar
                        src={
                          connectors.find(
                            (it) =>
                              String(it.id) ===
                              String(Array.from(selectedConnector)[0])
                          ).attributes.data_product.data.attributes.Image
                        }
                        size="sm"
                        radius="sm"
                      />
                      <div>
                        {" "}
                        {
                          connectors.find(
                            (it) =>
                              String(it.id) ===
                              String(Array.from(selectedConnector)[0])
                          ).attributes.Name
                        }
                      </div>{" "}
                    </div>
                  )}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                selectedKeys={selectedConnector}
                onSelectionChange={setSelectedConnector}
                selectionMode="single"
              >
                {connectors.map((it) => (
                  <DropdownItem
                    startContent={
                      <Avatar
                        radius="sm"
                        size="sm"
                        src={it.attributes.data_product.data.attributes.Image}
                      />
                    }
                    key={it.id}
                  >
                    {it.attributes.Name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="flex-1 overflow-y-auto">
            <div
              ref={messagesEndRef}
              className="h-full w-full overflow-y-auto "
            >
              {thread.map((it, index) => (
                <div className="flex flex-col gap-4 w-full">
                  <div className="flex px-2 flex-col">
                    <div className="flex flex-row gap-4 items-center">
                      <Avatar
                        size="sm"
                        src={userInfo?.Picture?.url}
                        color={colorPicker(userInfo?.id)}
                        name={userInfo?.email?.slice(0, 1).toUpperCase()}
                      />
                      <p className="font-semibold">You</p>
                    </div>
                    <p className="pl-12">
                      {it?.query?.split("\n")?.map((value) => (
                        <p>{value}</p>
                      ))}
                      {/* {loading && index === chatMessages.length - 1 && (
                              <span className="cursor-dot"></span>
                            )} */}
                    </p>
                  </div>
                  <div className="flex flex-col px-2 pb-4 rounded-md group">
                    <div className="flex flex-row gap-4 items-center pt-10">
                      <Avatar src={Logo} size="sm" />
                      <p className="font-semibold">Haya</p>
                    </div>
                    <>
                      {it?.response ? (
                        <div className={`mb-2 `}>
                          <div className="flex items-center pl-12">
                            <div className="w-full">
                              {it?.response
                                ?.split(/\n|\\n/)
                                .map((part, index) => {
                                  const tablepart = part.trim().startsWith("|");

                                  if (part.trim().startsWith("-")) {
                                    return (
                                      <p
                                        key={`${index}`}
                                        className="leading-loose"
                                      >
                                        &bull;{" "}
                                        {renderBoldText(part.substring(1))}
                                        <br />
                                      </p>
                                    );
                                  } else if (tablepart) {
                                    return <></>;
                                    // if (part.trim().includes("----")) {
                                    //   return (
                                    //     responseTable && (
                                    //       <>
                                    //         <div className="max-h-100px overflow-y-auto">
                                    //           <HayaTableRender
                                    //             markdownTable={responseTable}
                                    //           />
                                    //         </div>
                                    //       </>
                                    //     )
                                    //   );
                                    // } else {
                                    //   const appendedPart = part.trim() + "\n";
                                    //   apphend_table(appendedPart);
                                    //   return null;
                                    // }
                                  } else {
                                    return (
                                      <p key={index} className="leading-loose">
                                        {renderBoldText(part)}
                                        <br />
                                      </p>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        </div>
                      ) : index === thread.length - 1 ? (
                        <div className="w-full pl-12 pt-2 items-center justify-start">
                          <lord-icon
                            src="https://cdn.lordicon.com/lqxfrxad.json"
                            trigger="loop"
                            delay="100"
                            style={{ width: "30px", height: "30px" }}
                          ></lord-icon>
                        </div>
                      ) : (
                        <div className="w-full pl-12 pt-2 items-center justify-start">
                          Error 404
                        </div>
                      )}
                    </>
                  </div>
                </div>
              ))}
              <div className="h-0" ref={messagesEndRef} />
            </div>
          </div>
          <Textarea
            onKeyDown={(event) => {
              if (
                loading ||
                (thread.length > 0 && !thread[thread.length - 1]?.response)
              ) {
                return;
              }
              if (event.key === "Enter") {
                event.preventDefault();
                if (event.shiftKey) {
                  setVal((prev) => prev + "\n");
                } else {
                  handleKeyPress();
                }
              }
            }}
            minRows={1}
            disabled={loading || Array.from(selectedConnector).length === 0}
            maxRows={4}
            classNames={{ input: "text-lg pt-2" }}
            startContent={
              <Image
                src={Logo}
                width={35}
                radius="full"
                className="text-large text-default-400 pointer-events-none mt-1"
              />
            }
            value={val}
            onChange={(e) => setVal(e.target.value)}
            endContent={
              loading ? (
                <div className="mt-3">
                  {" "}
                  <lord-icon
                    src="https://cdn.lordicon.com/lqxfrxad.json"
                    trigger="loop"
                    delay="100"
                    style={{ width: "20px", height: "20px" }}
                  ></lord-icon>{" "}
                </div>
              ) : (
                <div className="mt-3">
                  {" "}
                  <RightArrow />{" "}
                </div>
              )
            }
          />
        </div>

        {/* Result Card */}
        <div className="border border-divider w-3/5 h-full p-8  rounded-lg">
          <Tabs variant="light">
            <Tab title="Anomalies found"></Tab>
            <Tab title="Heat Map"></Tab>
            <Tab title="SQL"></Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default CreateAnomaly;
