import { Avatar, Button, Input } from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { RightArrow } from "./RightArrow";
import Logo from "../../../../Images/haya-logo.png";
import { useSelector } from "react-redux";
import HayaTableRender from "../../../Haya/HayaTableRender";
import DocumentRendering from "../../../Haya/DocumentRendering";
import { userData } from "../../../../redux/actions";
import { colorPicker } from "../../../../controllers/basicControllers";

const ChatBox = ({ query, setQuery, handleSubmit, messages }) => {
  const UserData = useSelector(userData);
  const messagesEndRef = useRef(null);
  const USerProfiled =
    UserData && UserData.Picture !== null
      ? `${
          process.env.REACT_APP_STAGE === "dev"
            ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${
                UserData && UserData.Picture && UserData.Picture.url
              }`
            : `${UserData?.Picture?.url}`
        }`
      : "";
      const renderBoldText = (text) => {
        const boldRegex = /\*\*(.*?)\*\*/g;
        return text.split(boldRegex).map((part, index) => {
          if (index % 2 === 0) {
            return <span key={index}>{part}</span>;
          } else {
            return <strong key={index}>{part}</strong>;
          }
        });
      };
      const responseTable = [];
      const context_documents = []
      function getDocs(props) {
        context_documents.push(props);
    
      }
      function apphend_table(props) {
        responseTable.push(props);
      }
      useEffect(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
      }, [messages]);
  return (
    <div className="h-full p-2 flex flex-col overflow-y-auto gap-4">
      <div ref={messagesEndRef} className="flex-1 flex flex-col  overflow-y-auto">
        <div className="h-full flex flex-col gap-4">
          <div className="flex-1"></div>
          {messages
            .sort((a, b) => a.timestamp - b.timestamp)
            .map((item) => (
              <div className="flex gap-2">
                <div className="w-12" >
                {item.sender === "user" ? (
                  <Avatar
                    size="sm"
                    src={USerProfiled}
                    color={colorPicker(UserData.id)}
                    name={UserData?.email?.slice(0, 1).toUpperCase()}
                  />
                ) : (
                  <Avatar src={Logo} size="sm" />
                )}
                </div>
                <div className="w-full flex flex-col text-sm " >
                {item?.message.split("\n").map((part, index) => {
                  const tablepart = part.trim().startsWith("|");
                  const document = part.trim().startsWith("{");
                  if (part.trim().startsWith("-")) {
                    return (
                      <p key={`${index}`} className="leading-loose">
                        &bull; {renderBoldText(part.substring(1))}
                        <br />
                      </p>
                    );
                  } else if (tablepart) {
                    if (part.trim().includes("----")) {
                      return (
                        responseTable && (
                          <>
                            <div className="max-h-100px overflow-y-auto">
                              <HayaTableRender markdownTable={responseTable} />
                            </div>
                          </>
                        )
                      );
                    } else {
                      const appendedPart = part.trim() + "\n";
                      apphend_table(appendedPart);
                      return null;
                    }
                  } else if (document) {
                    if (part.trim().includes('"filtered_documents')) {
                      getDocs(part.trim());

                      return (
                        <>
                          <div className="max-h-100px overflow-y-auto">
                            <DocumentRendering documents={context_documents} />
                          </div>
                        </>
                      );
                    }
                  } else {
                    return (
                      <p key={index} className="leading-loose">
                        {renderBoldText(part)}
                        <br />
                      </p>
                    );
                  }
                })}
                </div>
              </div>
            ))}
        </div>
        <div className="h-0" ref={messagesEndRef} />
      </div>
      {messages.length === 0 &&  <div className="flex flex-col gap-2 pt-4">
        <div>
          Enter a query to test your agent
          </div>
        {/* <div className="flex justify-between items-center gap-4 ">
          <Button
            className="border border-divider w-[50%]"
            variant="light"
          ></Button>
          <Button
            className="border border-divider w-[50%]"
            variant="light"
          ></Button>
        </div> */}
        {/* <div className="flex justify-between items-center gap-4">
          <Button
            className="border border-divider w-[50%]"
            variant="light"
          ></Button>
          <Button
            className="border border-divider w-[50%]"
            variant="light"
          ></Button>
        </div> */}
      </div>}
      <Input
        endContent={<RightArrow />}
        placeholder="Test your agent"
        variant="outline"
        value={query}
        onChange={(event) => {
          setQuery(event.target.value);
        }}
        onKeyPress={async (event) => {
          if (event.key === "Enter") {
            await handleSubmit();
          }
        }}
      />
    </div>
  );
};

export default ChatBox;
