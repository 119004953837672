import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, Image, Input } from "@nextui-org/react";
import { CommonCollectionFilterApi } from '../../controllers/strapiController';
import SearchIcon from '../../Images/Search';
import {useParams} from "react-router-dom";
import pluralize from "pluralize";


const SelectdataProduct = ({ setFormData, formData, onNext, setSelectProduct, multiSelect }) => {

    const [dataProducts, setDataproducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const params=useParams();
    const type=params?._type&&pluralize.plural(params?._type)
   
    useEffect(() => {
        const url=params?._type?`filters[Type][$eq]=${type}&filters[spaces][Name][$eq]=${params?._spaceid}&[populate][spaces][on]`
        :params?._spaceid!=="haya"?
        `filters[spaces][Name][$eq]=${params?._spaceid}&[populate][spaces][on]`
        :''
        CommonCollectionFilterApi("data-products",url)
            .then((data) => setDataproducts(data.data))
            .catch((error) => console.error("Oops!, something went wrong, please try after some time."));
    }, []);

    const truncateDescription = (description, charLimit = 100) => {
        return description.length > charLimit ? description.slice(0, charLimit) + '...' : description;
    };

    const handleUpdate = (selectedData) => {

        if (multiSelect) {
            if (formData?.type?.includes(selectedData.id)) {
                setFormData((prev) => ({
                    ...prev,
                    type: prev.type.filter(id => id !== selectedData.id),
                }));
            } else {
                setFormData((prev) => ({
                    ...prev,
                    type: [...prev.type, selectedData.id],
                }));
            }
        } else {
            setFormData((prev) => ({ ...prev, type: selectedData.id, dataProduct: selectedData}));
            setSelectProduct(selectedData)
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredProducts = dataProducts?.filter((data) =>
        data.attributes.Name.toLowerCase().includes(searchQuery)
    );

    return (
        <div >
            
            <Input
                placeholder='Search for a data product'
                className='pb-8'
                startContent={<SearchIcon  className={`text-large text- pointer-events-none flex-shrink-0 `} />}
                value={searchQuery}
                onChange={handleSearch}
            />
            <div className='max-w-[1200px] mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8'>
                {filteredProducts?.length > 0 ? filteredProducts?.map((data) => (
                    <div key={data.id} onClick={() => handleUpdate(data)}>
                        <Card shadow='none' className={`border border-divider px-4 py-2 w-full h-[200px] cursor-pointer ${multiSelect ? formData?.type?.includes(data.id) ? "border-danger" : "border-divider" : data.id === formData.type ? "border-danger" : "border-divider"}`}>
                            <CardHeader className='text-lg gap-2 flex items-center'>
                                <Image src={data.attributes.Image} width={20} />
                                <span>{data.attributes.Name}</span>
                            </CardHeader>
                            <CardBody>
                                <div className='text-default-500'>
                                    {truncateDescription(data.attributes.Description)}
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                )) : (
                    <div className="text-center w-full col-span-3">No products found</div>
                )}
            </div>
            <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
                {!multiSelect && <>  <Button color="danger" variant="light">Cancel</Button>
                    <Button color="danger" isDisabled={!formData?.type} onClick={onNext}>Next</Button>
                </>}
            </div>
        </div>
    );
};

export default SelectdataProduct;
