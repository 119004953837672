// import React from 'react';
// import { Button } from "@nextui-org/react";
// import { generateCodeVerifier } from '../../Utils/helper';

import React from "react";
import { Button } from "@nextui-org/react";

const markdownTable = `| | Monthly | Weekly | Daily | Hourly |
| --- | --- | --- | --- | --- |
| ZeroModel | 2.045 | 6.075 | 2.989 | 10.255 |`;
// Utility functions for PKCE
const generateCodeVerifier = () => {
    const array = new Uint8Array(96); // Adjust size to ensure enough characters
    window.crypto.getRandomValues(array);
    return Array.from(array)
      .map((byte) => String.fromCharCode(byte % 94 + 33)) // Generate readable ASCII characters
      .join('')
      .replace(/[\+/=]/g, '') // Remove any invalid characters
      .substring(0, 128); // Trim to ensure a maximum of 128 characters
  };

const generateCodeChallenge = async (codeVerifier) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await crypto.subtle.digest("SHA-256", data);
  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/[\+/=]/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_"); // URL-safe base64
};

const MarkdownTableRenderer = ({ markdownTable }) => {
  const handleButtonClick = async () => {
    // Generate PKCE code verifier and challenge
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    console.log(codeVerifier, codeVerifier.length)
    // Save the code verifier in localStorage for later use
    localStorage.setItem("pkce_code_verifier", codeVerifier);

    // Construct the authorization URL with the code challenge
    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize
?client_id=fef72db7-42e4-467f-b1e8-cb40acb6effd
&response_type=code
&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fmicrosoft%2Fredirect
&scope=openid%20offline_access%20Files.Read.All
&code_challenge=${codeChallenge}
&code_challenge_method=S256`;

    // Redirect to the Microsoft OAuth authorization URL
    window.location.href = authUrl;
  };

  return (
    <div>
      <Button onClick={handleButtonClick}>Login with Microsoft</Button>
    </div>
  );
};

export default function App() {
  return <MarkdownTableRenderer markdownTable={markdownTable} />;
}
