import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react";
import { tableSkeleton } from "../../components/Skeleton";
import React, { useEffect, useState } from "react";
import {
  CommonCollectionFilterApi,
  DeleteSingleAttribute,
} from "../../controllers/strapiController";
import CreateDashboardModal from "./CreateDashboardModal";
import { useNavigate, useParams } from "react-router-dom";
import NullData from "../../components/NullData";
import { Squares2X2Icon } from "@heroicons/react/24/outline";

const Dashboards = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [allDashboardList, setAllDashboardList] = useState([]);
  const [reRender, setReRender] = useState(true);
  const params = useParams();

  const navigate = useNavigate();
  const headers = ["Name", "Description", "Created By", "Actions"];

  const submitHandler = () => {
    setReRender((pre) => !pre);
  };
  const deleteKeyHandler = async (id) => {
    const result = await DeleteSingleAttribute("dashboards", id);
    setReRender((pre) => !pre);
  };

  
  useEffect(() => {
    CommonCollectionFilterApi(
      "dashboards",
      `filters[space][Name][$eq]=${params._spaceid}&[populate][users_permissions_user][on]`
    ).then((data) => {
      setAllDashboardList(data?.data);
      setIsLoading(false);
    });
  }, [reRender]);

  const ItemPerPage = 10;
  const totalPage = Math.ceil(allDashboardList.length / ItemPerPage);

  return (
    <>
      <CreateDashboardModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        submitHandler={submitHandler}
      />
      <div className="p-8">
        <div className="text-3xl font-medium">Dashboards</div>
        <p className="mt-4 leading-relaxed">
          Unlock the full potential of your data mesh by building dynamic and
          visually engaging dashboards. Easily share these dashboards with your
          team to drive better insights and collaboration.
        </p>

        <div className="flex items-center justify-end my-4">
          <Button color="secondary" size="sm" onClick={onOpen}>
            Create New
          </Button>
        </div>

        {isLoading ? (
          tableSkeleton()
        ) : (
          <>
            <div className="mt-8">
              <Table removeWrapper selectionMode="single">
                <TableHeader>
                  {headers.map((header, headerIndex) => (
                    <TableColumn key={headerIndex}>{header}</TableColumn>
                  ))}
                </TableHeader>
                <TableBody
                  emptyContent={
                    <NullData
                      title={`No Api key found. Click on "create" to make a new key`}
                    />
                  }
                >
                  {allDashboardList.map((item, index) => {
                    const lowerPageLimit = (currentPage - 1) * ItemPerPage;
                    const upperPageLimit = currentPage * ItemPerPage;

                    const userAvatarName =
                      item?.attributes?.users_permissions_user?.data?.attributes
                        ?.email || "Unknown";
                    if (index < lowerPageLimit || index >= upperPageLimit)
                      return null;

                    return (
                      <TableRow
                        key={item?.id}
                        onClick={() => {
                          navigate(`${item.id}`);
                        }}
                        className="cursor-pointer"
                      >
                        <TableCell className="flex items-center gap-1">
                          <Squares2X2Icon color="blue" className="size-4" />
                          <span>{item?.attributes?.name}</span>
                        </TableCell>
                        <TableCell>
                          <span>{item?.attributes?.description}</span>
                        </TableCell>
                        <TableCell>
                          <Tooltip content={userAvatarName} showArrow={true}>
                            <Avatar
                              name={userAvatarName[0]}
                              className="w-5 h-5"
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <div
                            className={`flex gap-4 items-center cursor-pointer`}
                          >
                            <Dropdown>
                              <DropdownTrigger>
                                <div className="cursor-pointer">
                                  <Tooltip size="sm" content="Delete Key">
                                    <lord-icon
                                      src="https://cdn.lordicon.com/wpyrrmcq.json"
                                      trigger="hover"
                                      colors={`primary #000000`}
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                      }}
                                    ></lord-icon>
                                  </Tooltip>
                                </div>
                              </DropdownTrigger>
                              <DropdownMenu
                                aria-label="Static Actions"
                                selectionMode="single"
                                onSelectionChange={(event) => {
                                  deleteKeyHandler(item?.id);
                                }}
                              >
                                <DropdownItem
                                  key="delete"
                                  color="danger"
                                  variant="flat"
                                >
                                  Delete {item?.attributes?.name}?
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            {allDashboardList.length > 0 && (
              <div className="mt-4">
                <div className="w-full mt-8 flex items-center justify-center">
                  <Pagination
                    showControls
                    total={totalPage}
                    color="secondary"
                    page={currentPage}
                    onChange={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Dashboards;
