import {
  Checkbox,
  CheckboxGroup,
  Input,
  Switch,
  Tooltip,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import Details from "./Details";
import ChatBox from "./ChatBox";
import { PlaygroundManager } from "../../../../Utils/Playground";
import { useSelector } from "react-redux";
import { userData } from "../../../../redux/actions";

const Inputs = ({ item, onChange }) => {
  return (
    <Input
      size="sm"
      className="px-2"
      placeholder={item?.name}
      label={item?.instructionLabel}
      labelPlacement="outside"
      value={item.value || ""}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
const RightPane = ({
  selectedAgent,
  isSelected,
  setIsSelected,
  selectedInstructions,
  setSelectedInstructions,
  variables,
  setVariables,
}) => {
  const userInfo = useSelector(userData);
  const [disabled, setDisabled] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [detailIns, setDetailIns] = useState(null);
  const handleVariableChange = (id, name, value) => {
    setVariables((prev) =>
      prev.map((it) =>
        it.id === id && it.name === name ? { ...it, value } : it
      )
    );
  };
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState("");
  const handleSubmit = async () => {
    if (query === "" || disabled) {
      return;
    }
    if (
      messages.length > 0 &&
      messages[messages.length - 1].sender === "user"
    ) {
      return;
    }
    if (selectedInstructions.length === 0) {
      const mes = {
        user_id: userInfo?.id,
        prompt: "{query}",
        variables: [
          {
            variable: "query",
            value: query,
          },
        ],
      };
      PlaygroundManager.getInstance(setMessages).sendMessage(mes, setMessages);
      setQuery("");
    } else {
      const queryVariable = variables[0];
      const prompt =
        selectedAgent?.attributes?.InstructionJSON?.InstructionsJSON?.find(
          (it) => it.id === queryVariable.id
        )?.InstructionPrompt;
      if (!prompt) {
        return;
      }
      const sendVariables = variables
        ?.filter((it) => it.id === queryVariable.id)
        .map((item) => {
          return { variable: item.name, value: item.value };
        });
      const mes = {
        user_id : userInfo.id ,
        prompt: prompt,
        variables: [
          {
            variable: "query",
            value: query,
          },
          ...sendVariables,
        ],
      };
      PlaygroundManager.getInstance(setMessages).sendMessage(mes, setMessages);
      setQuery("");
    }
  };
  useEffect(() => {
    if (selectedAgent) {
      let newVariables = variables.filter((it) =>
        selectedInstructions.includes(it.id)
      );
      const instructions =
        selectedAgent?.attributes?.InstructionJSON?.InstructionsJSON?.filter(
          (it) => selectedInstructions.includes(it.id)
        );
      instructions.map((it) => {
        it.variables.map((e) => {
          if (
            !newVariables.find((item) => item.id === it.id && item.name === e)
          ) {
            newVariables.push({
              id: it.id,
              name: e,
              instructionLabel: it.InstructionName,
              value: "",
            });
          }
        });
      });
      setVariables(newVariables);
    }
  }, [selectedInstructions, selectedAgent]);
  useEffect(() => {
    setDisabled(true);
    const time = setTimeout(() => setDisabled(false), 2000);
    return () => clearTimeout(time);
  }, [messages]);
  useEffect(() => {
    setMessages([]);
  }, [selectedAgent]);
  return (
    <div className="h-full pb-4 flex gap-4 w-full overflow-y-auto ">
      <Details
        instruction={detailIns}
        isOpen={isDetails}
        setIsOpen={setIsDetails}
      />
      <div className="border border-divider w-[75%] rounded-lg p-4 overflow-y-auto">
        <ChatBox
          messages={messages}
          query={query}
          setQuery={setQuery}
          handleSubmit={handleSubmit}
        />
      </div>
      <div className="border border-divider w-[25%] overflow-y-auto rounded-lg p-4 flex flex-col gap-4">
        <div className="text-lg">Parameters Code</div>
        <div className="flex items-center justify-between">
          <div className=" text-sm ">Model</div>
          <div className=" text-sm ">
            {selectedAgent?.attributes?.modelJSON?.Language}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <div className="text-sm flex items-center">Instructions</div>
            <Tooltip content={"instructions for the prompt."}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
            </Tooltip>
          </div>
          <Switch
            size="sm"
            isSelected={isSelected}
            onValueChange={setIsSelected}
          />
        </div>

        <div
          className={`flex flex-col gap-2 text-xs transition-all duration-500 ease-in-out ${
            isSelected ? "max-h-auto opacity-100" : "max-h-0 opacity-0 hidden"
          }`}
        >
          <CheckboxGroup
            size="sm"
            color="primary"
            value={selectedInstructions}
            onValueChange={setSelectedInstructions}
          >
            {selectedAgent?.attributes?.InstructionJSON?.InstructionsJSON?.map(
              (it) => (
                <div className="flex items-center justify-between hover:bg-[#F4F4F5] px-2 py-1 rounded-lg group">
                  <Checkbox value={it.id}>{it.InstructionName}</Checkbox>
                  <div
                    onClick={() => {
                      setDetailIns(it);
                      setIsDetails(true);
                    }}
                    className="text-xs text-warmGray-400 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                  >
                    Learn More
                  </div>
                </div>
              )
            )}
          </CheckboxGroup>
        </div>
        <div
          className={`flex flex-col gap-2 text-xs transition-all duration-500 ease-in-out ${
            isSelected &&
            selectedInstructions.length > 0 &&
            variables.length > 0
              ? "max-h-auto opacity-100 py-2"
              : "max-h-0 opacity-0 py-0 hidden"
          }`}
        >
          <div className="text-sm">Variables</div>
          {variables?.map((it) => (
            <Inputs
              key={`${it.id}-${it.name}`}
              item={it}
              onChange={(value) => handleVariableChange(it.id, it.name, value)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RightPane;
