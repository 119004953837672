import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CommonCollectionFilterApi,
  UpdateData,
} from "../../controllers/strapiController";
import RGL, { WidthProvider } from "react-grid-layout";
import ShowChart from "./ShowChart";
import { getItemLayout } from "../../Utils/dashbaord.utils";

import "../../draglayout.css";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Button } from "@nextui-org/react";
export const defaultLayoutProps = {
  items: 5,
  rowHeight: 30,
  preventCollision: false,
  cols: 12,
};

const ReactGridLayout = WidthProvider(RGL);

const defaultProps = {
  isDraggable: true,
  isResizable: true,
  ...defaultLayoutProps,
};

const EditSingleDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [layoutPosition, setLayoutPosition] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState(null);
  const { _dashboardid, _spaceid } = useParams();
  const navigate = useNavigate();

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragStop = () => {
    setIsDragging(false);
  };

  const saveHandler = async () => {
    const chartsInfo = dashboardDetails?.attributes?.chartsInfo;

    const newChartInfo = chartsInfo?.map((chart) => {
      const newChart = { ...chart };
      newChart.poisitionInfo = layoutPosition.find((pos) => pos.i === chart.id);
      return newChart;
    });

    const updateDashbaordResponse = await UpdateData(
      "dashboards",
      _dashboardid,
      {
        chartsInfo: newChartInfo,
      }
    );

    navigate(-1);
  };
  useEffect(() => {
    CommonCollectionFilterApi(
      "dashboards",
      `filters[id][$eq]=${_dashboardid}`
    ).then((data) => {
      const layoutDetails = [];
      const chartinfo = data?.data?.[0]?.attributes?.chartsInfo || [];
      chartinfo.forEach((chart) => {
        layoutDetails.push(chart?.poisitionInfo);
      });
      setLayoutPosition(layoutDetails);
      setDashboardDetails(data?.data?.[0]);
      setIsLoading(false);
    });
  }, []);

  const chartsInfo = dashboardDetails?.attributes?.chartsInfo;

  return (
    <div className="p-8">
      <div className="flex items-center justify-between text-3xl font-medium">
        <span>{dashboardDetails?.attributes?.name}</span>
        <Button color="danger" size="sm" onClick={saveHandler}>
          Save
        </Button>
      </div>
      <div className="my-4 leading-relaxed">
        {dashboardDetails?.attributes?.description}
      </div>

      <div className="flex flex-grap items-start w-full min-h-full">
        {!isLoading && (
          <ReactGridLayout
            {...defaultProps}
            isDraggable={!isDragging}
            isResizable={!isDragging}
            onDragStart={handleDragStart}
            onDragStop={handleDragStop}
            onResizeStart={handleDragStart}
            onResizeStop={handleDragStop}
            onLayoutChange={(newLayout) => {
              setLayoutPosition(newLayout);
            }}
          >
            {chartsInfo?.map((data) => {
              const layoutInfo = getItemLayout(layoutPosition, data.id);

              return (
                <div
                  key={layoutInfo?.i}
                  data-grid={layoutInfo}
                  className="rounded-sm cursor-move"
                >
                  <ShowChart data={data} />
                </div>
              );
            })}
          </ReactGridLayout>
        )}
      </div>
    </div>
  );
};

export default EditSingleDashboard;

/*
-> get the dbConfig, get the result,
->

*/
