import React, { useState, useRef, useEffect } from 'react';
import { Toaster, toast } from "react-hot-toast";
import { Input, Textarea, Switch, useDisclosure, Chip, Button } from "@nextui-org/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from "react-router-dom";
import { colorPicker } from "../../controllers/basicControllers";
import Dangerzone from "../../components/Dangerzone";
import DeleteModal from "../../components/DeleteModal";
import { getAllData, UpdateData, DeleteSingleAttribute } from "../../controllers/strapiController";
import { delete_document, getSingleConnection, patchDataProduct } from '../../controllers/vectordbController';
import { useSelector } from 'react-redux';
import { spaceData } from '../../redux/actions';

const Settings = ({ collection, setLoader, setReload, isHide,collectionName }) => {
    // INITIAL STATE DICLARATION
    const [tagInput, setTagInput] = useState('');
    const [validationCondition, setValidationCondition] = useState(false);
    const [Validatetags, setValidatetags] = useState('');
    const [InitialData, setInitialData] = useState(null);
    const [tagsData, setTagsData] = useState();
    const [selectedTag, setselectedTag] = useState(null);
    const ref = useRef();
    const TextRef = useRef();
    const navigate = useNavigate();
    const spaceInfo = useSelector(spaceData);
    const params = useParams();
    const UserDetails = JSON.parse(sessionStorage.getItem("userData"));
    const UserID = UserDetails && UserDetails.user && UserDetails.user.id;
    const [tags, setTags] = useState([]);
    const [reloadData, setReloadData] = useState(null);
    const [formData, setFormdata] = useState({ Name: '', Description: '', Tags: [], isPublic: false });
    const [formError, setFormError] = useState({ NameError: '', DescriptionError: '' });
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
    // TAGS INPUT ONCHANGE HANDLER
    const handleTitleChange = (e) => {
        const userInput = e.target.value;
        const regex = /^[a-zA-Z_ -]*$/;
        if (regex.test(userInput)) {
            setFormdata(({ ...formData, Name: userInput }))
        }
    }

    const vectorName = "data-products";
    const vectorNameSpecs = "data-product-specs";

    // TAGS TO CHIP RENDERING
    const renderTags = () => {
        return tags && tags.map((tag, index) => (
            <Chip
                key={index}
                className="mr-2 mb-2"
                size='md'
                color={colorPicker(index)}
                variant="flat"
                onClose={() => handleTagRemove(tag)}>
                {tag}
            </Chip>
        ));
    };

    // TAGS INPUT ONCHANGE HANDLER
    const handleChange = (e) => {
        const userInput = e.target.value;

        const regex = /^[a-zA-Z_ -]*$/;
        if (regex.test(userInput)) {
            setTagInput(userInput);
        }
    }

    // FUNCTION TO HANDLE SCROLLBAR
    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    }, [tagInput])

    // GETTING TAGS LIST ACCORDING TO USER
    useEffect(() => {
        getAllData("tags")
            .then((data) => {
                setTagsData(data.data)
            })
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [])

    const handleTagRemove = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
        setFormdata(() => ({ ...formData, Tags: tags.filter(tag => tag !== tagToRemove) }))
    };

    // VISIBALITY OF TAGS WHEN INPUT FIELD IS NOT NULL
    const Text = tagInput.trim().toLowerCase();
    const handleTagInputKeyPress = (e) => {
        if (tagInput.trim() !== '') {
            if (!tags.includes(Text)) {
                setTags((previous) => [...previous, Text]);
                setValidatetags("")
                setFormdata((previous) => ({ ...previous, Tags: [...tags, Text] }));
                setTagInput('');
            } else {
                setValidatetags("Entered tag already exists.")
            }
        }
    };

    // ONCLICK TO SELECT TAGS
    const HandleOnClickInput = (tag) => {
        setselectedTag(tag)
        if (!tags.includes(tag)) {
            setTags((previous) => [...previous, tag]);
            setValidatetags(null)
            setTagInput('');
            setFormdata((previous) => ({ ...previous, Tags: [...tags, tag] }));
        } else {
            setValidatetags("Entered tag already exists.")
        }
    };

    // HANDLE ENTER TO FILL TAGS
    const handleKeyPress = (e) => {
        if (tagInput.trim() !== '' && e.key === "Enter") {
            if (!tags.includes(Text)) {
                setTags((previous) => [...previous, Text]);
                setValidatetags("")
                setFormdata((previous) => ({ ...previous, Tags: [...tags, Text] }));
                setTagInput('');
            } else {
                setValidatetags("Entered tag already exists.")
            }
        }
    }

    //INITIALIZING THE VALUES
    useEffect(() => {
        if (collection) {
            const TagsData = collection.attributes.Tags && collection.attributes.Tags.tags;
            setInitialData(TagsData);
            TagsData && setTags(collection.attributes.Tags.tags)
            setFormdata({
                Name: collection.attributes.Name,
                Description: collection.attributes.Description,
                Tags: TagsData,
                isPublic: collection.attributes.Public
            })
        }
    }, [collection, reloadData])

    // VALIDATION FOR SUBMIT HANDLER
    const Validation = () => {
        let IsValid = true;

        if (!formData.Name) {
            IsValid = false;
            setFormError((previous) => ({ ...previous, NameError: "Name is required." }));
        } else {
            setFormError((previous) => ({ ...previous, NameError: null }))
        }

        if (!formData.Description) {
            IsValid = false;
            setFormError((previous) => ({ ...previous, DescriptionError: "Description is required." }))
        } else {
            setFormError((previous) => ({ ...previous, DescriptionError: null }))
        }
        return IsValid;
    }


    // Check if the arrays are of the same length
    const lengthMatch = formData.Tags && InitialData && InitialData.length === formData.Tags.length;

    // Check if each element matches
    const elementsMatch = InitialData && InitialData.length > 0 && formData.Tags && formData.Tags.length > 0 ? InitialData.every((element, index) => element === formData.Tags[index]) : null;

    const TagsCompare = lengthMatch && elementsMatch;

    const Disabled = collection && collection.attributes &&
        formData.Name === collection.attributes.Name && formData.Description === collection.attributes.Description && (TagsCompare === true || TagsCompare === null) && formData.isPublic === collection.attributes.Public ? true : false;

    // TAGS FILTER DROPDOWN
    const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === UserID)
    // TAGS PRESENTS
    const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
    // FILTER FOR INPUT TEXT RELATED TO TAGS
    const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

    // FINAL DISPLAY TAGS
    const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []
    const FilterInput = FilteredTags && FilteredTags.some((tag) => tag.toLowerCase() === tagInput.toLowerCase());

    const TagsRender = formData.Tags && formData.Tags.filter((data) => TagsLength && !TagsLength.includes(data));
    const NewRenderation = TagsRender && FilteredTags && TagsRender.concat(FilteredTags);
    const TagsID = FilteredUser && FilteredUser[0] && FilteredUser[0].id;

    // UPDATE COLLECTION
    const SubmitHandler = async (onClose) => {
        const payload = {
            Name: formData.Name,
            Description: formData.Description,
            Tags: { tags: formData.Tags },
            Public: formData.isPublic
        }

        const vectorPayload = {
            name : formData.Name,
            description : formData.Description,
            tags : formData.Tags
        }

        const TagsPayload = {
            Tags: { tags: NewRenderation }
        }

        const Validate = Validation();
        if (Validate) {
            setValidationCondition(false);
            const response = await UpdateData(collectionName, collection.id, payload);
            console.log()
            await patchDataProduct(vectorPayload,collection.id, spaceInfo[0]?.attributes?.Name,"data-products" );
            if (response) {
                const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
                setReload(updateResponse)
                toast.success("Your collection has been updated.");
                setTagInput('')
            }
        } else {
            setLoader(false);
            setValidationCondition(true);
        }
    }


    // DELETE HANDLER TO DELETE COLLECTION
    const deleteHandler = async () => {
        try {
            const response = collection && await DeleteSingleAttribute(collectionName, collection.id);
             
            if (response) {
                navigate(-1);
            }
            await delete_document("data-products",collection.id,params?._spaceid);
            const documentsDataPromise = getSingleConnection(vectorName, params?._spaceid, collection?.id);
            const documentSpecsPromise = getSingleConnection(vectorNameSpecs, params?._spaceid, collection?.id);

            const [documentsDataResult, documentSpecsResult] = await Promise.all([documentsDataPromise, documentSpecsPromise]);

            const documentsDataArray = documentsDataResult?.documents || [];
            documentsDataArray.forEach(element => {
                delete_document(vectorName, element?.payload?.id, params?._spaceid)
                    .catch(error => console.error("Error deleting documentData:", error));
            });

            if (collection?.attributes?.Type === "connectors") {
                const documentSpecsArray = documentSpecsResult?.documents || [];
                documentSpecsArray.forEach(element => {
                    delete_document(vectorNameSpecs, element?.payload?.id, params?._spaceid)
                        .catch(error => console.error("Error deleting documentSpec:", error));
                });
            }

        } catch (error) {
            console.error("Error deleting collection or handling documents:", error);
        }
    };



    return (
        <>
            <div className=''>
                <Toaster
                    position="bottom-right"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName=""
                    containerStyle={{}}
                    toastOptions={{
                        style: {
                            boxShadow: "none",
                            border: "1px solid #bdb5b5"
                        },
                        success: { duration: 3000 }
                    }} />
                <h1 className="pt-8 pb-8 text-xl font-medium">Settings</h1>
                <div className='flex flex-col gap-8'>
                    <Input
                        isRequired
                        key="name"
                        className=''
                        type="text"
                        value={formData?.Name}
                        onChange={handleTitleChange}
                        isInvalid={!formData.Name && validationCondition ? !formData.Name || validationCondition : ''}
                        errorMessage={!formData.Name && validationCondition ? formError.NameError : ""}
                        label="Collection Name"
                        labelPlacement="outside"
                        placeholder="Enter your collection folder name"
                    />
                    <Textarea
                        key={"description"}
                        isRequired
                        type="text"
                        value={formData.Description}
                        onChange={(e) => { setFormdata({ ...formData, Description: e.target.value }) }}
                        isInvalid={!formData.Description && validationCondition ? !formData.Description || validationCondition : ''}
                        errorMessage={!formData.Description && validationCondition ? formError.DescriptionError : ""}
                        label="Decription"
                        labelPlacement={"outside"}
                        placeholder="Enter your collection's Description"
                    />
                    {collection?.attributes?.data_product?.data?.attributes?.Type === "collections" && <div className='flex flex-col gap-1'>
                        <div className='flex gap-2 flex-col'>
                            <p className='text-sm'>Make collection public</p>
                            <Switch isSelected={formData.isPublic} onChange={(e) => { setFormdata({ ...formData, isPublic: !formData.isPublic }) }} size='sm' color="secondary"></Switch>
                        </div>
                        {formData.isPublic ? <p className='text-xs text-slate-400'>By making your collection public anyone inside the space can view and upload your documents.</p> : <></>}
                    </div>}
                    {!isHide&&<div className="relative">
                        <lable className="text-sm">Tags</lable>
                        <div style={{ Background: "#f4f4f5" }} className={`border rounded-xl  flex flex-col border-divider p-2`}>
                            <input
                                className={`bg-background border-0 rounded text-sm`}
                                key={"tags"}
                                ref={TextRef}
                                style={{ outline: 'none' }}
                                type="text"
                                variant="bordered"
                                labelPlacement={"outside"}
                                placeholder="Press enter to add multiple tags"
                                value={tagInput && tagInput.trim()}
                                onChange={handleChange}
                                onKeyPress={(event) => { if (event.key === 'Enter') { handleKeyPress(event) } }}
                            />
                            <div className="pt-4 flex flex-wrap gap-2">
                                {renderTags()}
                            </div>
                            <p className="pt-2 text-xs text-red-400">{tags.includes(Text) || tags.includes(selectedTag) ? Validatetags : ""}</p>
                        </div>
                        {tagInput !== '' && tagInput !== ' ' ?
                            <div ref={ref} className='transition ease-in-out delay-200 z-50 w-full pt-2 ' >
                                <div className={`rounded-xl border w-full border-divider divide-y divide-divider overflow-y-auto bg-white`} style={{ maxHeight: "325px" }} >
                                    {FilteredTags && FilteredTags.length > 0 ?
                                        <>
                                            {FilteredTags && FilteredTags.map((data) => {
                                                const TagsCheck = tags && tags.some((tag) => tag === data);
                                                return <div key={data} onClick={() => { HandleOnClickInput(data) }} className="p-2 text-sm flex flex-row gap-2 items-center justify-between">
                                                    <div className="flex flex-row gap-2 items-center">
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/prjooket.json"
                                                            colors={`primary: #000000}`}
                                                            trigger="hover"
                                                            style={{ width: "16px", height: "16px" }}>
                                                        </lord-icon>
                                                        {data} </div>
                                                    {TagsCheck ? <div>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </div> : ""}
                                                </div>
                                            })}
                                            {FilterInput === false ?
                                                <p className="p-2 text-sm" onClick={handleTagInputKeyPress}>+ Add <b>"{tagInput}"</b> to tag list. </p> : ""}
                                        </> :
                                        <p className="p-2 text-sm" onClick={handleTagInputKeyPress}>+ Add <b>"{tagInput}"</b> to tag list. </p>
                                    }
                                </div>
                            </div> : ""}
                    </div>}
                    <div className='flex justify-end gap-4'>
                        <Button color="default" variant="flat" onClick={() => setReloadData(!reloadData)}>Reset</Button>
                        <Button isDisabled={Disabled} color={colorPicker(collection?.id)} onClick={() => { SubmitHandler() }}>Update </Button>
                    </div>
                    <Dangerzone collection={collection?.attributes?.Name} onDeleteOpen={() => onDeleteOpen()} />
                </div>
            </div>
            <DeleteModal
                isOpen={isDeleteOpen}
                onOpenChange={onDeleteOpenChange}
                deleteData={collection}
                setDeleteData={""}
                Collectionname={collection && collection.attributes.Name}
                setLoader={""}
                DeleteHandler={deleteHandler}
            />
        </>

    )
}

export default Settings;