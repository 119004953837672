import React, { useEffect, useState } from "react";
import { CommonCollectionFilterApi } from "../../controllers/strapiController";
import ViewChart from "../Charts/ViewChart";
import { getSQLQueryResult } from "../../controllers/basicControllers";
import { Spinner } from "@nextui-org/react";

// sql = defaultSql,
// chartType = defaultChartType,
// chartInfo = defaultChartInfo,
// data = defaultQueryResult,
// id = defaultId,
// dbConfig = defaultDbConfig,

const ShowChart = ({ data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dbConfig, setDbConfig] = useState(null);
  const [queryResult, setQueryResult] = useState([]);

  useEffect(() => {
    const collectionId = data?.collectionId;
    CommonCollectionFilterApi(
      "collections",
      `filters[id][$eq]=${collectionId}&[populate][data_product][on]`
    ).then((collectionData) => {
      const dbData = {
        dbType:
          collectionData?.data?.[0]?.attributes?.data_product?.data?.attributes
            ?.Name,
        ...collectionData?.data?.[0]?.attributes?.ConnectionJSON?.properties,
      };

      const queryData = {
        sql: data?.sql_statement,
        dbType: dbData?.dbType,
        dbConfig: { ...dbData, user: dbConfig?.username },
      };

      getSQLQueryResult(queryData).then((queryResponse) => {
        setQueryResult(queryResponse || []);
        setDbConfig(dbData);
        setIsLoading(false);
      });
    });
  }, [data]);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Spinner color="default" />
        </div>
      ) : (
        <ViewChart
          dbConfig={dbConfig}
          id={data?.id}
          data={{ query_result: queryResult }}
          chartType={data?.chart_type}
          sql={data?.sql_statement}
          chartInfo={{
            name: data?.chart_title,
            description: data?.chart_description,
            xAxis: data?.xAxis,
            yAxis: data?.yAxis,
            minThreshold: data?.minThreshold,
            maxThreshold: data?.maxThreshold,
          }}
          cardClasses=""
        />
      )}
    </>
  );
};

export default ShowChart;
