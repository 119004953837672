// STRING SPLIT FUNCTION
export const renderBoldText = (text) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    return text.split(boldRegex).map((part, index) => {
      if (index % 2 === 0) {
        return <span key={index}>{part}</span>;
      } else {
        return <strong key={index}>{part}</strong>;
      }
    });
  };