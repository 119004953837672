import pkceChallenge from "pkce-challenge";
  
  // Example usage in Microsoft OAuth flow
  export const MicrosoftOauth = async () => {
    // Generate PKCE code verifier and challenge
    const { code_verifier, code_challenge } = await pkceChallenge(128);
  
    // Save the code verifier in localStorage for later use
    localStorage.setItem('pkce_code_verifier', code_verifier);
  
    // Construct the authorization URL with the code challenge
    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize
  ?client_id=fef72db7-42e4-467f-b1e8-cb40acb6effd
  &response_type=code
  &redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fmicrosoft%2Fredirect
  &scope=openid%20offline_access%20Files.Read.All
  &code_challenge=${code_challenge}
  &code_challenge_method=S256`;
  
    // Open the authorization URL in a new tab
    window.open(authUrl, '_blank');
  };
  
 export const getMicrosoftEmail = async (token) => {
    try {
      const response = await fetch("https://graph.microsoft.com/v1.0/me", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch user email");
      }
  
      const data = await response.json();
  
      if (data?.mail && typeof data.mail === "string") {
        return String(data.mail);
      } else {
        throw new Error("Authentication Failed");
      }
    } catch (error) {
      throw new Error("Authentication Failed");
    }
  };
  

