import React, { useEffect, useState } from "react";
import { Button, Divider, Image, Input, Link } from "@nextui-org/react";
import { EyeSlashFilledIcon } from "../../Images/EyeSlashFilledIcon";
import { EyeFilledIcon } from "../../Images/EyeFilledIcon";
import Github from "../../Images/Github.jsx";
import Google from "../../Images/Google.jsx";
import { CommonCollectionFilterApi } from "../../controllers/strapiController.js";
import { decriptedText } from "../../controllers/basicControllers";
import { NavigateSign } from "../../controllers/loginController.js";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { GitHubAuth, GoogleAuth } from "../../controllers/social.js";
import ButtonSpinner from "../../components/Loader/ButtonSpinner";
import ServerIssue from "../../components/ServerIssue";
import { generateCodeVerifier } from "../../Utils/helper.js";
import pkceChallenge from "pkce-challenge";
import { loginWithLamauth } from "llamauth";
import { getMicrosoftEmail } from "../../Utils/MSauth.js";

const initialState = { emailError: "", passwordError: "", FinalError: "" };

const Signin = () => {
  const [searchParams] = useSearchParams();
  const path = searchParams?.get("path");

  // LOGIN WITH GOOGLE
  const handleGoogleAuth = async (e) => {
    e.stopPropagation();
    const data = await GoogleAuth();
    window.location.href = data.urlWithQueryParams;
  };
  const handleMicrosoftOauth = async () => {
    // Generate PKCE code verifier and challenge
    const { code_verifier, code_challenge } = await pkceChallenge(128);

    // Save the code verifier in localStorage for later use
    localStorage.setItem("pkce_code_verifier", code_verifier);

    // Construct the authorization URL with the code challenge
    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize
  ?client_id=${process.env.REACT_APP_MS_CLIENT}
  &response_type=code
  &redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcallback%2Fmicrosoft
  &scope=openid%20email%20User.Read
  &code_challenge=${code_challenge}
  &code_challenge_method=S256`;

    // Open the authorization URL in a new tab
    window.open(authUrl, "_blank");
  };
  // LOGIN WITH GITHUB
  const handleGithubAuth = async (e) => {
    e.stopPropagation();
    const data = await GitHubAuth();
    window.location.href = data.urlWithQueryParams;
  };

  // INITIALIZATION
  const [isVisible, setIsVisible] = useState(false);
  const Navigate = useNavigate();
  const [ValidationConditions, setValidationConditions] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const Location = useLocation();
  const [connectionRefused, setConnectonRefused] = useState(false);
  const [LoginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [LoginError, setLoginError] = useState(initialState);
  // PASSWORD REGEXs
  var PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const commonProps = {
    size: "md",
    radius: "sm",
    variant: "faded",
    labelPlacement: "outside",
  };

  // NAVIAGE FOR FORGOT PASSWORD
  const navigate_forgot = () => {
    Navigate("/auth/reset-password");
  };

  useEffect(() => {
    if (Location.pathname === "/sign-in") {
      sessionStorage.clear();
    }
    setLoginError(initialState);
  }, [LoginDetails]);

  // VALIDATION FOR SIGN IN
  const Validation = () => {
    let IsValid = true;

    if (LoginDetails.email === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Email is required.",
      }));
    } else if (!EmailRegex.test(LoginDetails.email)) {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Enter valid email format.",
      }));
    } else {
      setLoginError((previous) => ({ ...previous, emailError: "" }));
    }

    if (LoginDetails.password === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        passwordError: "Password is required.",
      }));
    } else if (
      LoginDetails.password !== "" &&
      !PasswordRegex.test(LoginDetails.password)
    ) {
      setLoginError((previous) => ({
        ...previous,
        passwordError: "Enter a strong password.",
      }));
    }
    return IsValid;
  };

  // ENTER KEY TO RUN SUBMIT HANDLER
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      SubmitHandler();
    }
  };

  const signupHandler = () => {
    if (path !== null) {
      Navigate(`/sign-up/redirectTo?path=${path}`);
    } else {
      Navigate("/sign-up");
    }
  };


  // SUBMIT HANDLER FOR SIGN IN
  const SubmitHandler = async () => {
    setButtonLoader(true);
    const Validate = Validation();

    if (Validate) {
      setValidationConditions(false);

      // USER API CALL
      const userEmail = LoginDetails.email.toLowerCase().trim();
      const url = `filters[email][$eq]=${userEmail}`;
      const data = await CommonCollectionFilterApi("users", url);
      if (data?.length > 0) {
        setConnectonRefused(false);

        const decryptedPassword = decriptedText(`${data[0].Password2}`);

        // Compare passwords
        if (decryptedPassword !== LoginDetails.password) {
          setLoginError((previous) => ({
            ...previous,
            passwordError: "Password didn't match.",
          }));
          setButtonLoader(false);
          return;
        }

        const pathData = path !== null ? path : "";
        const navigate_route = await NavigateSign(
          LoginDetails.email,
          LoginDetails.password,
          pathData
        );

        if (navigate_route) {
          Navigate(navigate_route);
        }
      } else {
        setLoginError((previous) => ({
          ...previous,
          emailError: "Email not exists. Create an new account.",
        }));
        setButtonLoader(false);
      }
    } else {
      setValidationConditions(true);
      setButtonLoader(false);
    }
  };

  // PASSWORD EYE FUNCTIONALITY
  const toggleVisibility = () => setIsVisible(!isVisible);

  // SERVER ERROR
  if (connectionRefused) {
    return <ServerIssue />;
  }

    // MSAUTH
    const msAuthLogin = async(data) => {
  
      try{
        const {access_token} = data;
        const email = await getMicrosoftEmail(access_token);
        const pathData = path !== null ? path : "";
        const navigate_route = await NavigateSign(
          email,
          "",
          pathData
        );
    
        if (navigate_route) {
          Navigate(navigate_route);
        } else{
          Navigate('/')
        }
      }
      catch(e){
        console.log(e)
      }
    }
  // RETURN UI
  return (
    <>
      <div className={`flex h-screen items-center justify-center bg-white`}>
        <main className="mx-auto min-h-[590px] w-full max-w-[450px]">
          <Image
            width={50}
            alt="NextUI hero Image"
            src="Assets/haya-logo.png"
          />
          <h1 className="mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold ">
            Sign in to Haya
          </h1>
          <p className="pb-8 sm:text-left text-base text-slate-11 font-normal"></p>
          <form data-gtm-form-interact-id="0" onKeyDown={handleKeyPress}>
            {LoginError.FinalError ? (
              <p className="sm:text-left text-base text-slate-11 font-normal">
                {LoginError.FinalError}
              </p>
            ) : (
              ""
            )}
            <div className="mb-4 space-y-2">
              <Input
                {...commonProps}
                className="border-slate-6"
                isInvalid={!!LoginError?.emailError}
                errorMessage={LoginError?.emailError}
                key={"outside"}
                type="email"
                label="Email"
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, email: e.target.value })
                }
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-4 space-y-2">
              <Input
                {...commonProps}
                className="py-4"
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, password: e.target.value })
                }
                isInvalid={!!LoginError.passwordError}
                errorMessage={LoginError.passwordError}
                label={
                  <div
                    className="flex justify-between "
                    style={{ width: "442px" }}
                  >
                    <span>Password</span>
                    <span className="cursor-pointer" onClick={navigate_forgot}>
                      Forgot your password?
                    </span>
                  </div>
                }
                placeholder="Enter your password"
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility}
                  >
                    {isVisible ? (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
              />
            </div>
            <Button
              isLoading={buttonLoader}
              spinner={<ButtonSpinner />}
              id="CreateAccount"
              radius="sm"
              onClick={SubmitHandler}
              className={
                "w-full text-white bg-black hover:bg-gray-700 hover:text-white"
              }
            >
              Sign In
              <lord-icon
                src="https://cdn.lordicon.com/vduvxizq.json"
                trigger="hover"
                target="#CreateAccount"
                colors="primary:#ffffff"
                style={{
                  width: "18px",
                  height: "20px",
                }}
              />
            </Button>
            <div className="text-small text-default-400 flex items-center justify-end mt-4">
              <Link
                onClick={signupHandler}
                className="cursor-pointer"
                size="sm"
              >
                Dont have an account? Create a new one
              </Link>
            </div>
            <div className="mb-6 mt-6 flex items-center justify-center">
              <Divider className="my-2" style={{ maxWidth: "44%" }} />
              <span className="text-small text-default-400 px-4">OR</span>
              <Divider className="my-2" style={{ maxWidth: "44%" }} />
            </div>
          </form>
          <div className="flex w-full flex-row flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Button
              radius="sm"
              variant={"faded"}
              className="flex gap-1"
              style={{ width: "48%" }}
              onClick={() => loginWithLamauth({key : "pcGUWBQ5hvg9xx1uzgq4GH93DqSv84J4" , callback: msAuthLogin , uuid : "17b357ed-3cee-4c1d-af1e-ddfabf563852" })}
            >
              <div className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
                  />
                </svg>
                Sign in with Microsoft
              </div>
            </Button>
            <Button
              radius="sm"
              variant={"faded"}
              className="flex w-1/2 gap-1 bg"
              style={{ width: "48%" }}
              onClick={handleGoogleAuth}
            >
              <Google />
              Sign in with Google
            </Button>
          </div>
          <p className="text-small text-default-400 mt-8">
            By signing in, you agree to our{" "}
            <Link href="#" size="sm">
              Terms of Service{" "}
            </Link>{" "}
            and{" "}
            <Link href="#" size="sm">
              Privacy Policy{" "}
            </Link>
            .
          </p>
        </main>
      </div>
    </>
  );
};

export default Signin;
