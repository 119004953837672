import React, { useState } from "react";
import RightPane from "./Playground/RightPane";

const Sandbox = ({ agent }) => {
  const [isSelected, setIsSelected] = useState(false);
  const [selectedInstructions, setSelectedInstructions] = useState([]);
  const [variables, setVariables] = useState([]);
  console.log("agent", agent);
  return (
    <RightPane
      selectedAgent={agent}
      variables={variables}
      setVariables={setVariables}
      isSelected={isSelected}
      setIsSelected={setIsSelected}
      setSelectedInstructions={setSelectedInstructions}
      selectedInstructions={selectedInstructions}
    />
  );
};

export default Sandbox;
