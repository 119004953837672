import React, { useRef, useEffect } from "react";
import * as monaco from "monaco-editor";

const MonacoEditor = ({ value, onChange }) => {
  const containerRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    // Create Monaco Editor instance
    const editor = monaco.editor.create(containerRef.current, {
      value: value || "", // Default SQL query if no value is provided
      language: "sql",
      theme: "custom-sql-theme",
      minimap: {
        enabled: false, // Disable minimap
      },
      fontSize: 16, 
      padding: {
        top: 10, // Increase top padding
        bottom: 10, // Increase bottom padding
        left: 10, // Increase left padding
        right: 10, // Increase right padding
      },
    });

    // Store editor instance in ref
    editorRef.current = editor;

    // Define SQL language tokenization rules
    monaco.languages.setMonarchTokensProvider("sql", {
      tokenizer: {
        root: [
          [/\b(SELECT|FROM|WHERE|INSERT|UPDATE|DELETE|CREATE|DROP|TABLE|JOIN)\b/i, "keyword.sql"], // Keywords
          [/\b(AND|AS|OR|NOT|IN|LIKE|IS)\b/i, "operator.sql"], // Operators
          [/'([^']*)'/, "string.sql"], // Strings
          [/--.*/, "comment.sql"], // Comments
          [/\b\d+(\.\d+)?\b/, "number.sql"], // Numbers
          [/[\w$][\w\d$]*/, "identifier.sql"], // Identifiers
        ],
      },
    });

    // Define custom theme for SQL
    monaco.editor.defineTheme("custom-sql-theme", {
      base: "vs-dark", // Base theme
      inherit: true,
      rules: [
        { token: "keyword.sql", foreground: "569cd6", fontStyle: "bold" }, // Blue for keywords
        { token: "operator.sql", foreground: "c586c0" }, // Purple for operators
        { token: "string.sql", foreground: "ce9178" }, // Orange for strings
        { token: "comment.sql", foreground: "6a9955", fontStyle: "italic" }, // Green for comments
        { token: "number.sql", foreground: "b5cea8" }, // Light green for numbers
        { token: "identifier.sql", foreground: "dcdcaa" }, // Yellow for identifiers
      ],
      colors: {
        "editor.foreground": "#d4d4d4",
        "editor.background": "#1e1e1e",
      },
    });

    // Apply the custom theme
    monaco.editor.setTheme("custom-sql-theme");

    // Define SQL autocompletion items
    const sqlKeywords = [
      "SELECT", "FROM", "WHERE", "INSERT", "UPDATE", "DELETE", "CREATE", "DROP",
      "TABLE", "JOIN", "AND","AS", "OR", "NOT", "IN", "LIKE", "IS",
    ];
    const sqlFunctions = ["SUM", "AVG", "COUNT", "MIN", "MAX"];
    const sqlDataTypes = ["VARCHAR", "INT", "FLOAT", "DATE", "BOOLEAN"];

    monaco.languages.registerCompletionItemProvider("sql", {
      provideCompletionItems: () => {
        const suggestions = [
          ...sqlKeywords.map((keyword) => ({
            label: keyword,
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: keyword,
          })),
          ...sqlFunctions.map((func) => ({
            label: func,
            kind: monaco.languages.CompletionItemKind.Function,
            insertText: func + "()",
          })),
          ...sqlDataTypes.map((type) => ({
            label: type,
            kind: monaco.languages.CompletionItemKind.TypeParameter,
            insertText: type,
          })),
        ];
        return { suggestions };
      },
    });

    // Listen for changes in the editor and update the parent component
    editor.onDidChangeModelContent(() => {
      const newValue = editor.getValue();
      if (onChange) onChange(newValue);
    });

    // Cleanup function to dispose of Monaco editor when the component unmounts
    return () => {
      editor.dispose();
    };
  }, []); // Depend on value to update the editor when the value prop changes

  return <div ref={containerRef} style={{ height: "100%", width: "100%" }} />;
};

export default MonacoEditor;
