import React, { useEffect, useState } from "react";
import { getOneData } from "../controllers/strapiController";
import { colorPicker } from "../controllers/basicControllers";
import { Avatar } from "@nextui-org/react";

const UserAvatar = ({ id }) => {
    
  const [user, setUser] = useState(null);
  useEffect(() => {
    const fetchUser = async (id) => {
      const resp = await getOneData("users", id);
      setUser(resp);
    };
    if (id) {
      fetchUser(id);
    }
  }, [id]);
  return (
    <div className="flex gap-4 items-center">
      <Avatar
        showFallback
        className="h-8 w-8"
        color={id && colorPicker(id)}
        name={user && user?.email?.charAt(0)}
        // name={userDetails?.email?.toUpperCase().slice(0, 1)}
        src={
          user?.Picture !== null &&
          `${
            process.env.REACT_APP_STAGE === "dev"
              ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user?.Picture?.url}`
              : user?.Picture?.url
          }`
        }
      />
      <div className="text-tiny text-default-500 font-bold">
        {user && (user?.firstname ? `${user.firstname} ${user?.lastname ?? ""}` : `${user.username}`)}
      </div>
    </div>
  );
};

export default UserAvatar;
