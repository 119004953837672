import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { getOneData, CommonCollectionFilterApi } from "../../../controllers/strapiController";
import { StringLimit, avatarName, colorPicker } from "../../../controllers/basicControllers";
import { ErrorHandler } from '../../../Utils/ErrorHandler';
import NotFound from "../../../components/NotFound";
import ServerIssue from "../../../components/ServerIssue";
import Forbbiden from "../../../components/Forbbiden";
import UnAuthorized from "../../../components/UnAuthorized";
import ButtonSpinner from "../../../components/Loader/ButtonSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Chip, Tooltip, Button, Link, Image } from "@nextui-org/react";
import { UpdateData } from "../../../controllers/strapiController";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import hayalogo from "../../../Images/haya-logo.png"

const DBLearn = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [dataProduct, setDataProduct] = useState([]);
    const [defaultProduct, setDefaultProduct] = useState([]);
    const [reload, setReload] = useState('');
    const [loading, setLoading] = useState(false);
    const data = useSelector(state => state);
    const indexData = data && JSON.stringify(data, null, 2);
    const convertedData = JSON.parse(indexData);
    const spaceId = convertedData?.space?.[0]?.id;
    const templateId = params?._tempid

    useEffect(() => {
        getOneData("data-products", params?._id)
            .then((data) => {
                if (data?.error) {
                    setLoading(false);
                    ErrorHandler(data?.error, { setNotFound, setForbidden, setServerIssue, setAuthorized })
                } else {
                    setLoading(false);
                    setDataProduct(data?.data)
                }
            })
            .catch((error) => setServerIssue(true))

        if (templateId) {
            const url = `filters[id][$eq]=${templateId}&filters[spaces][Name][$eq]=${params?._spaceid}&filters[data_products][id][$eq]=${params?._id}&[populate][data_products][on]&[populate][data_products][]`
            CommonCollectionFilterApi("templates", url)
                .then(data => setDefaultProduct(data?.data))
                .catch((error) => console.error("Oops!, something went wrong, please try after some time."));
        } else {
            const url = `filters[spaces][Name][$eq]=${params?._spaceid}&filters[id][$eq]=${params?._id}&[populate][spaces][on]`
            CommonCollectionFilterApi("data-products", url)
                .then((data) => setDefaultProduct(data.data))
                .catch((error) => console.error("Oops!, something went wrong, please try after some time."));
        }
    }, [params?._id, reload])


    const handleChange = async () => {
        setLoading(true);
        if (defaultProduct?.length === 0) {
            const payload = {
                data_products: {
                    disconnect: [],
                    connect: [{ id: dataProduct.id, position: { end: true } }]
                }
            }

            const response = await UpdateData(templateId ? "templates" : "spaces", templateId ? templateId : spaceId, payload);
            if (response) {
                setLoading(false);
                setReload(response);
                toast.success(`${dataProduct?.attributes?.Name} added to your data mesh.`)
            }
        } else {
            const payload = {
                data_products: {
                    disconnect: [{ id: dataProduct.id, position: { end: true } }],
                    connect: []
                }
            }

            const response = await UpdateData(templateId ? "templates" : "spaces", templateId ? templateId : spaceId, payload)
            if (response) {
                setLoading(false);
                setReload(response);
                toast.success(`${dataProduct?.attributes?.Name} has been removed from your data mesh.`)
            }
        }
    }

    const CardTags = ({ tags, colorIndex }) => (
        <div className='flex pt-2 gap-3'>
            {tags && tags !== null && tags?.slice(0, 3).map((tag, index) => (
                <Chip key={index} size='sm' color={colorIndex} variant='flat'>
                    {tag}
                </Chip>
            ))}
            {tags?.length > 3 && (
                <Tooltip
                    content={tags.slice(3).map((tag, index) => (
                        <div key={index}>{tag}</div>
                    ))}
                    placement='bottom'
                    size='sm'
                    closeDelay={10}
                >
                    <Chip size='sm' color={colorIndex} variant="flat">
                        + {tags?.length - 3}
                    </Chip>
                </Tooltip>
            )}
        </div>
    );

    if (notFound) return <NotFound />;
    if (serverIssue) return <ServerIssue />;
    if (forbidden) return <Forbbiden />;
    if (authorized) return <UnAuthorized />;
    return (
        <div>
            <div className='flex flex-row gap-2 items-center text-default-400 cursor-pointer' onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} />
                Back
            </div>
            <div className='pt-8'>
                <Avatar src={dataProduct?.attributes?.Image} name={avatarName(dataProduct?.attributes?.Name)} color={colorPicker(dataProduct?.id)} />
            </div>
            <div className='flex flex-row gap-20'>
                <div className='w-3/5'>
                    <div className=' border-b border-divider pb-4'>
                        <p className="text-xl font-medium pt-2">{dataProduct?.attributes?.Name}</p>
                        <p className="pt-4">{dataProduct?.attributes?.Description}</p>
                    </div>
                    <div className='pt-4'>
                        <p className="text-xl font-medium pb-4">Overview</p>
                        {dataProduct?.attributes?.docs?.split("\n")?.map((paragraph, index) => <p>{paragraph}<br /></p>)}
                    </div>
                </div>
                <div className='w-2/5'>
                    <div className='flex flex-row justify-between border-b border-divider py-2'>
                        <p>Collection</p>
                        <p>{dataProduct?.attributes?.Type?.charAt(0).toUpperCase() + dataProduct?.attributes?.Type?.slice(1)}</p>
                    </div>

                    <div className='flex flex-row justify-between border-b border-divider  py-2'>
                        <p>Author</p>
                        <div className='flex gap-2 items-center'>
                            <Image
                                width={25}
                                alt="NextUI"
                                src={hayalogo}
                            />
                            Haya
                        </div>
                    </div> <div className='flex flex-row justify-between border-b border-divider  py-2'>
                        <p>Source</p>
                        <Link color="secondary" href={dataProduct?.attributes?.website} className='cursor-pointer'>{dataProduct?.attributes?.website ? StringLimit(dataProduct?.attributes?.website, 30) : "--"}</Link>
                    </div> <div className='flex flex-row justify-between  py-2 pb-6'>
                        <p>Tags</p>
                        {dataProduct?.attributes?.Tags?.properties?.length > 0 ?
                            <CardTags tags={dataProduct?.attributes?.Tags?.properties} colorIndex={"secondary"} />
                            : "--"}
                    </div>

                    <Button isLoading={loading} spinner={loading ? ButtonSpinner() : ""} size='sm' className='w-full' color="danger" variant="flat" onClick={handleChange}>
                        {defaultProduct?.length === 0 ? `Add to ${templateId ? "Template" : "Haya"}` : `Remove from  ${templateId ? "Template" : "data mesh"}`}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DBLearn;