import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
} from "@nextui-org/react";
import React, { useMemo, useState } from "react";
import { RenderTable } from "../Connectors/WorkBench";

const TableModal = ({ isOpen, setIsOpen, sqlData }) => {
  const keys = Object.keys(sqlData[0]);
  const [customize, setCustomize] = useState(new Set(keys.slice(0,3)));
  const customData = useMemo(() => {
    // Convert the Set to an Array for easier iteration
    const keysToInclude = Array.from(customize);

    // Map over the sqlData and create a new object for each item with only the specified keys
    return sqlData.map((item) => {
      // Use reduce to create a new object with only the keys specified in customize
      return keysToInclude.reduce((acc, key) => {
        if (item.hasOwnProperty(key)) {
          acc[key] = item[key];
        }
        return acc;
      }, {});
    });
  }, [customize, sqlData]);
  return (
    <Modal
      radius="none"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      className="absolute !m-0 top-0 right-0 h-screen z-100 overflow-y-auto "
      hideCloseButton={true}
      size="3xl"
    >
      <ModalContent className="h-full">
        <ModalBody className="h-full overflow-y-auto flex flex-col gap-4">
          <div className="flex justify-end">
            <Dropdown>
              <DropdownTrigger>
                <Button size="sm" variant="light">Customize</Button>
              </DropdownTrigger>
              <DropdownMenu
                closeOnSelect={false}
                selectionMode="multiple"
                disallowEmptySelection
                selectedKeys={customize}
                onSelectionChange={setCustomize}
              >
                {keys.map((it) => (
                  <DropdownItem key={it}>{it}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>

          <div className="flex w-full overflow-y-hidden overflow-x-auto">
            <RenderTable json={customData} />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TableModal;
