import { Card, CardHeader, Image } from '@nextui-org/react'
import React, { useState } from 'react'
import CardBG1 from "../../Images/GradientExtsvg.svg"
import CardBG4 from "../../Images/Card_BG4.svg"
import CardBG3 from "../../Images/CardBG_3.svg"
import CardBG2 from "../../Images/CardBG_2.svg"
import { useNavigate, useParams } from 'react-router-dom'
import { extensionSkeleton } from '../../components/Skeleton'

const Extensions = () => {
    // STATE INITIALIZATION
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const params = useParams();

    setTimeout(() => { setLoader(false) }, 200);

    return (
        <div className='p-8'>

            {loader ? extensionSkeleton() : <>
                <div className='text-3xl font-medium'>Integrations</div>
                <div className='mt-4'>
                    Extend your overview by just using our APIs and connect Haya to your Slack channel, Discord Server, Teams App or even your web applications.
                </div>
                <div className='mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12'>
                    <div className='cursor-pointer' onClick={() => (navigate(`/${params._spaceid}/integrations/slack`))} >
                        <Card isFooterBlurred radius="lg" className="h-[300px]">
                            <CardHeader className="absolute z-10 top-1 flex-col items-start">
                                <p className="text-tiny text-white/60 uppercase font-bold">Slack</p>
                                <h4 className="text-white font-medium text-large mt-8">Connect Haya to your Slack channel</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full object-cover"
                                src={CardBG1} />
                        </Card>
                    </div>
                    <div className='cursor-pointer' onClick={() => (navigate(`/${params._spaceid}/integrations/teams`))} >
                        <Card isFooterBlurred radius="lg" className="h-[300px]">
                            <CardHeader className="absolute z-10 top-1 flex-col items-start">
                                <p className="text-tiny text-white/60 uppercase font-bold">Teams</p>
                                <h4 className="text-white font-medium text-large mt-8">Connect Haya to your Teams</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full object-cover"
                                src={CardBG2} />
                        </Card>
                    </div>
                    <div className='cursor-pointer' onClick={() => (navigate(`/${params._spaceid}/integrations/discord`))} >
                        <Card
                            isFooterBlurred
                            radius="lg"
                            className="h-[300px]" >
                            <CardHeader className="absolute z-10 top-1 flex-col items-start">
                                <p className="text-tiny text-white/60 uppercase font-bold">Discord</p>
                                <h4 className="text-white font-medium text-large mt-8">Connect Haya to your Discord server</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full object-cover"
                                src={CardBG3} />
                        </Card>
                    </div>
                    <div className='cursor-pointer' onClick={() => (navigate(`/${params._spaceid}/integrations/react`))} >
                        <Card
                            isFooterBlurred
                            radius="lg"
                            className="h-[300px]">
                            <CardHeader className="absolute z-10 top-1 flex-col items-start">
                                <p className="text-tiny text-white/60 uppercase font-bold">React Application</p>
                                <h4 className="text-white font-medium text-large mt-8">Connect Haya to your react application</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full object-cover"
                                src={CardBG4} />
                        </Card>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default Extensions
