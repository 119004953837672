import React, { useEffect, useState } from "react";
import { Avatar, Tabs, Tab } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { CommonCollectionFilterApi } from "../../controllers/strapiController";
import NotFound from "../../components/NotFound";
import ServerIssue from "../../components/ServerIssue";
import Forbidden from "../../components/Forbbiden";
import WorkBench from "./WorkBench";
import { useSelector } from "react-redux";
import { ConnectorSkeleton } from "../../components/Skeleton";
import { colorPicker } from "../../controllers/basicControllers";
import TableProperty from "./TableProperty";
import Configuration from "../subPages/Configuration";
import Settings from "../subPages/Settings";
import { pageRefereshData } from "../../redux/actions";

const SingleConnector = () => {
  // STATE INITIALIZATION
  const [connectorData, setConnectorData] = useState();
  const [loader, setLoader] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [reload, setReload] = useState();
  const [serverIssue, setServerIssue] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [connectionRefused, setConnectonRefused] = useState(false);
  const [selected, setSelected] = useState();
  const params = useParams();
  const type = "connectors";
  const tabData = useSelector((state) => state?.ActiveTab);
  const indexData = tabData && JSON.stringify(tabData, null, 2);
  const ConvertedData = indexData && JSON.parse(indexData);
  const pageRender = useSelector(pageRefereshData);

  // API RENDERING
  useEffect(() => {
    const url = `filters[id][$in][0]=${params._id}&filters[Type]][$eq]=${type}&filters[space][Name][$eq]=${params._spaceid}&[populate][space][on]&[populate][author][on]&[populate][connector_list][on]&[populate][database][on]&[populate][data_product][on]`;
    CommonCollectionFilterApi("collections", url)
      .then((data) => {
        setLoader(false);
        if (data.error) {
          if (data.error.status !== 404 && data.error.status !== 403) {
            setServerIssue(true);
          }
          if (data.error.status === 404) {
            setNotFound(true);
          }
          if (data.error.status === 403) {
            setForbidden(true);
          }
        } else if (data.data.length > 0) {
          setLoader(false);
          setConnectorData(data?.data?.[0]);
        } else {
          setNotFound(true);
        }
      })
      .catch((error) => {
        setLoader(false);
        setConnectonRefused(true);
      });
  }, [Response, params._id, params._spaceid, reload, pageRender]);

  if (loader) {
    return ConnectorSkeleton();
  }

  if (notFound) {
    return <NotFound />;
  }

  if (serverIssue) {
    return <ServerIssue />;
  }

  if (connectionRefused) {
    return <ServerIssue />;
  }

  if (forbidden) {
    return <Forbidden />;
  }

  return (
    <div className="p-8 flex overflow-y-auto flex-col h-full">
      <div className="flex gap-4 items-center">
        <Avatar
          radius="sm"
          src={connectorData?.attributes?.data_product?.data?.attributes?.Image}
          width={30}
        />
        <div className="text-3xl font-medium">
          {connectorData?.attributes?.Name}
        </div>
      </div>
      {/* Description */}
      <div className="mt-8">{connectorData?.attributes?.Description}</div>
      <div className="pt-8">
        <Tabs
          aria-label="Options"
          color={colorPicker(connectorData?.id)}
          variant="underlined"
          selectedKey={selected}
          defaultSelectedKey={ConvertedData}
          onSelectionChange={setSelected}
        >
          <Tab key="Workbench" title="Workbench"></Tab>
          <Tab key="Properties" title="Properties"></Tab>
          <Tab key="Configuration" title="Configuration"></Tab>
          <Tab key="Settings" title="Settings"></Tab>
        </Tabs>
      </div>
      <div className="flex-1 overflow-y-auto ">
        {selected === "Workbench" ? (
          <WorkBench data={connectorData} />
        ) : selected === "Properties" ? (
          <TableProperty connectorData={connectorData} />
        ) : selected === "Configuration" ? (
          <Configuration
            collection={connectorData}
            setReload={setReload}
            setLoader={setLoader}
          />
        ) : selected === "Settings" ? (
          <Settings
            collection={connectorData}
            setReload={setReload}
            setLoader={setLoader}
            collectionName="collections"
            isHide={false}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SingleConnector;
