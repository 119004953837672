import { Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleDocument, patchAnyCollection, patchData } from "../../controllers/vectordbController";
import Settings from "./Settings";
import { useSelector } from "react-redux";
import { spaceData } from "../../redux/actions";
import { getOneData } from "../../controllers/strapiController";
import { convertKeysToLowercase } from "../../controllers/basicControllers";
import { getSQL } from "../../controllers/sqlController";
import ViewAnomaly from "./ViewAnomaly";
import WorkBench from "../Connectors/WorkBench";
import SQLCode from "../../components/SQLCode";
import HeatAnomaly from "./Heat-Anomaly";

const SingleAnomaly = () => {
  const [anomaly, setAnomaly] = useState(null);
  const { _id, _spaceid } = useParams();
  const [selected, setSelected] = useState("view");
  const spaceInfo = useSelector(spaceData);
  const [sqlResult, setSqlResult] = useState([]);
  const [timeSqlResult, setTimeSqlResult] = useState([]);
  const [dataProd, setDataProd] = useState(null);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  console.log("confog",dataProd);
  const handleUpdateSQL = async(query) => {
    setLoader(true);
    try{
      await patchAnyCollection({sql : query},anomaly.payload.id,spaceInfo[0]?.attributes?.SpaceName, "anomaly");
      const dbConfig = convertKeysToLowercase(
        dataProd.attributes?.ConnectionJSON?.properties
      );
      const result = await getSQL(
        query,
        dataProd.attributes?.data_product?.data?.attributes?.Name,
        dbConfig
      );
      setSqlResult(result);
      setAnomaly((prev) => ({...prev, payload : {...prev.payload , sql : query}}));
    } catch(e){
      console.log(e)
    } finally {
      setLoader(false);
    }
  }
  useEffect(() => {
    const fetchAnomaly = async () => {
      try {
        const resp = await getSingleDocument(
          Number(_id),
          spaceInfo[0]?.attributes?.SpaceName,
          "anomaly"
        );
        if (resp.length > 0) {
          setAnomaly(resp[0]);
          const config = await getOneData(
            "collections",
            Number(resp[0].payload?.dataproduct_id)
          );
          setDataProd(config.data);
          const dbConfig = convertKeysToLowercase(
            config.data?.attributes?.ConnectionJSON?.properties
          );
          const result = await getSQL(
            resp[0].payload?.sql,
            config.data?.attributes?.data_product?.data?.attributes?.Name,
            dbConfig
          );
          const timeResult = await getSQL(
            resp[0].payload?.["time-sql"],
            config.data?.attributes?.data_product?.data?.attributes?.Name,
            dbConfig
          );
          setTimeSqlResult(timeResult);
          setSqlResult(result);
        } else {
          navigate(`/${_spaceid}/anomaly`);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoader(false);
      }
    };
    if (_id && spaceInfo[0]?.attributes?.SpaceName) {
      fetchAnomaly();
    }
  }, [_id, spaceInfo]);

  return (
    <div className="p-8 flex flex-col h-full overflow-y-auto">
      <div className="text-3xl font-medium">{anomaly?.payload?.name}</div>
      <div className="mt-4 leading-relaxed">
        {anomaly?.payload?.description}
      </div>
      <div className="pt-8">
        <Tabs
          selectedKey={selected}
          onSelectionChange={setSelected}
          variant="underlined"
          color="secondary"
        >
          <Tab key={"view"} title="View Anomalies"></Tab>
          <Tab key={"heat"} title="Heat map"></Tab>
          <Tab key={"workbench"} title="Workbench"></Tab>
          <Tab key={"settings"} title="Settings"></Tab>
        </Tabs>
      </div>
      {!loader && (
        <div className="flex-1 flex flex-col pt-8 overflow-y-auto">
          {selected === "view" ? (
            <ViewAnomaly
              timeSqlResult={timeSqlResult}
              resp={anomaly}
              config={dataProd.attributes}
              sqlData={sqlResult}
            />
          ) : selected === "heat" ? (
            <HeatAnomaly
              data={sqlResult}
              resp={anomaly}
              config={dataProd.attributes}
            />
          ) : selected === "contribute" ? (
            <></>
          ) : selected === "workbench" ? (
            <WorkBench
              anomalyArr={sqlResult}
              data={dataProd}
              anomalySql={anomaly?.payload?.sql}
              handleUpdateAnomaly={handleUpdateSQL}
            />
          ) : selected === "settings" ? (
            <Settings anomaly={anomaly} setAnomaly={setAnomaly} />
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default SingleAnomaly;
