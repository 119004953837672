import React, { useState, useEffect } from "react";
import { Button } from "@nextui-org/react";
import { getOAuthFunction } from "../Utils/OauthFunction";

const icon = (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-white">
    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z" />
  </svg>)

export const OAuthButtonUpdate = ({setFormData , database , formData,data }) => {
    const [isConnected, setIsConnected] = useState(
        !!localStorage.getItem("one_drive")
      );
    const [hasVal, setHasVal] = useState(false);
    console.log(formData)
    useEffect(() => {
        if(formData?.token){
            setHasVal(true);
        }
    },[formData])
    useEffect(() => {
        if (isConnected && database === "One Drive") {
          setFormData((prev) => ({
            ...prev,
            token: JSON.parse(localStorage.getItem("one_drive")),
          }));
        }
      }, [isConnected]);
      useEffect(() => {
        const handleStorageChange = () => {
            setIsConnected(!!localStorage.getItem("one_drive"));
        };
    
        // Listen for localStorage changes
        window.addEventListener("storage", handleStorageChange);
    
        return () => {
          // Cleanup listener on component unmount
          window.removeEventListener("storage", handleStorageChange);
        };
      }, []);
    if (database === "One Drive") {
        return <Button
            startContent={icon}
            onClick={getOAuthFunction(data.function)}
            color={hasVal ? "danger" : "primary"}
            className="w-52"
          >
            {`${hasVal ? "Change Account" : "Add Account"}`}
          </Button>
        
      }
    else{
        return <></>;
    }
}

const OAuthButton = ({ database, data, setFormData }) => {
  const [isConnected, setIsConnected] = useState(
    !!localStorage.getItem("one_drive")
  );
  useEffect(() => {
    if (isConnected && database === "One Drive") {
      setFormData((prev) => ({
        ...prev,
        driveInfo: localStorage.getItem("one_drive"),
      }));
    }
  }, [isConnected]);
  useEffect(() => {
    const handleStorageChange = () => {
      setIsConnected(!!localStorage.getItem("one_drive"));
    };

    // Listen for localStorage changes
    window.addEventListener("storage", handleStorageChange);

    return () => {
      // Cleanup listener on component unmount
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleDisconnect = () => {
    localStorage.removeItem("one_drive");
    setFormData((prevData) => {
      const { ["driveInfo"]: _, ...newData } = prevData;
      return newData;
    });
    setIsConnected(false); // Update state to reflect disconnection
  };

  if (database === "One Drive") {
    return isConnected ? (
      <Button startContent={icon}  color="danger" className="w-52" onClick={handleDisconnect}>
        Disconnect your OneDrive
      </Button>
    ) : (
      <Button
        startContent={icon}
        onClick={getOAuthFunction(data.function)}
        color="primary"
        className="w-52"
      >
        {data.name}
      </Button>
    );
  }

  return null; // Render nothing if not "One Drive"
};

export default OAuthButton;
