import React, { useState, useEffect } from 'react';
import { Button, Input, Select, SelectItem, Textarea } from '@nextui-org/react';
import { CommonCollectionFilterApi } from '../../../controllers/strapiController'
import { useParams } from 'react-router-dom';
import { toast } from "react-hot-toast";
import pluralize from "pluralize"
import { StringLimit, titleCreater } from '../../../controllers/basicControllers';
import { agentValidator } from '../../../Utils/TemplateValidation';


const CreateAgentDetails = ({ setFormValues, formValues, onNext }) => {

  const params = useParams();
  const collectionname = "collections"
  const [collections, setCollections] = useState();
  const [validate, setValidate] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [respponseValid, setResponseValid] = useState();

  useEffect(() => {
    const url = `filters[space][Name][$eq]=${params._spaceid}&[populate][author][on]&[populate][inviteds][on]&[populate][viewed][on]&[populate][requests][on]&[populate][teams][on]&[populate][space][on]`
    CommonCollectionFilterApi(collectionname, url)
      .then((data) => { setCollections(data.data) })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
  }, [])

  const collectionArray = collections?.map(data => `${data?.id}`);
  const isAllSelected = collectionArray?.length > 0 && formValues?.DataProducts?.length === collectionArray?.length;

  const onchangeHandler = (event) => {
    const { name, value } = event.target;

    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSelectAllToggle = () => {
    if (selectAll) {
      setFormValues({
        ...formValues,
        DataProducts: []
      });
    } else {
      // Select all
      setFormValues({
        ...formValues,
        DataProducts: [...collectionArray, "SelectAll"]
      });
    }
    setSelectAll(!selectAll);
  }

  const handleSelectChange = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === 'SelectAll') {

      setFormValues({
        ...formValues,
        DataProducts: isAllSelected ? [] : collectionArray
      });
    } else {
      // Handle individual item selection
      setFormValues({
        ...formValues,
        DataProducts: isAllSelected ? [...value?.split(","), "SelectAll"] : value?.split(",")?.filter(data => data !== "SelectAll")
      });
    }
  };

  const onNextValidation = async () => {

    const url = `filters[Name][$eq]=${encodeURIComponent(formValues?.Name)}&filters[space][Name][$eq]=${params?._spaceid}`
    const response = await CommonCollectionFilterApi("agents", url);
    setResponseValid(response?.data);
    const validate = agentValidator(formValues, response, params?._spaceid);
    setValidate(validate);
    if (validate?.isValid) {
      onNext();
      setFormValues({
        ...formValues,
        SelectAll: formValues?.DataProducts?.some(data => data === "SelectAll") ? true : false
      });
    }
  }


  return (
    <div className='p-8'>
      <div className='text-3xl font-medium'>Create a mini-Haya agent</div>
      <div className='mt-4 leading-relaxed'>
        Create a mini Haya agent and integrate it to your data mesh and your workflows.
      </div>
      <div className='mt-16'>
        <Input
          label="Name"
          placeholder='Give a name to your Agent'
          labelPlacement='outside'
          name="Name"
          isRequired
          value={formValues?.Name}
          onChange={onchangeHandler}
          errorMessage={(formValues?.Name === '' || formValues?.Name?.length < 3 || respponseValid?.[0]?.attributes?.Name === formValues?.Name) && validate?.errors?.Name}
          isInvalid={(formValues?.Name === '' || formValues?.Name?.length < 3 || respponseValid?.[0]?.attributes?.Name === formValues?.Name) && !!validate?.errors?.Name}
        />
        <div >
          <Textarea
            label="Description"
            placeholder='Give context to your agent'
            labelPlacement='outside'
            name="Context"
            className='mt-8'
            isRequired
            value={formValues?.Context}
            onChange={onchangeHandler}
            errorMessage={formValues?.Context === '' && validate?.errors?.Context}
            isInvalid={formValues?.Context === '' && !!validate?.errors?.Context}
          />
        </div>
        {/* select data products */}
        <div className='mt-16'>
          <Select
            label="Select your data products"
            placeholder='Attach data products from your data mesh'
            labelPlacement='outside'
            name="DataProducts"
            selectionMode='multiple'
            disabledKeys={["NoDataProducts"]}
            selectedKeys={formValues?.DataProducts}
            onChange={handleSelectChange}
            errorMessage={formValues?.DataProducts?.length === 0 && validate?.errors?.DataProducts}
            isInvalid={formValues?.DataProducts?.length === 0 && !!validate?.errors?.DataProducts}>
            {collections?.length > 0 && <SelectItem key="SelectAll" textValue="Select All" onClick={handleSelectAllToggle} >
              <div className="flex justify-between items-center gap-2">
                <div className="flex flex-col">
                  <span>
                    Select All
                  </span>
                  <span className="text-default-500 text-tiny">
                    Select All Data Products.
                  </span>
                </div>
                <div className='text-default-500 text-tiny'>
                  All Data Products
                </div>
              </div>
            </SelectItem>}
            {collections?.length > 0 ? (collections?.map((item) => (
              <SelectItem value={item?.id} key={item?.id} textValue={item?.attributes?.Name}>
                <div key={item.key} className="flex justify-between items-center gap-2">
                  <div className="flex flex-col">
                    <span>
                      {item?.attributes?.Name}
                    </span>
                    <span className="text-default-500 text-tiny">
                      {StringLimit(item?.attributes?.Description, 150)}
                    </span>
                  </div>
                  <div className='text-default-500 text-tiny'>
                    {titleCreater(pluralize.singular(item?.attributes?.Type || ""))}
                  </div>
                </div>
              </SelectItem>
            ))) : <SelectItem key='NoDataProducts'>No data products created yet.</SelectItem>}
          </Select>
        </div>
        <div className='mt-32 flex gap-2 justify-end'>
          <Button onClick={onNextValidation} color='secondary' variant='flat'>Next</Button>
        </div>
      </div>
    </div>
  )
}

export default CreateAgentDetails