// Microsoft read the Oauth token and save it in the DB

import { useNavigate } from "react-router-dom";
import { NavigateSign } from "./loginController";
import { CommonCollectionFilterApi } from "./strapiController";

export const OauthMicrosoft = async () => {
  // URL: http://localhost:3000/microsoft/redirect?code=1.AVMA9EHIGPpunkKssN0f5SsveLct9_7kQn9GsejLQKy27_3FANVTAA.AgABBAIAAADW6jl31mB3T7ugrWTT8pFeAwDs_wUA9P8wqakgVysvRaS69wxNTXQ-JA2StYZFYPPcKFBMbmuVxPEFsyukWlUgZekAM0EpIqrx_ygfTIF1IfmyOV5a4p37-Rzp7wFxhMGnEiIIMdrTUi873QelW_INm0YlohIrHu2F7PopV4do5IMgbFvKnupyN49PMcgBV99jPf2z7rYPxjlHm2KdN74QcWGQVYqx_8QrbXx1h1ITF7zDGmYCqG8izSd0oL8UF-1nIAiJCvbc-coBqy3PIrz13hX_WtfmBmMoIL-4vURUjaooozzm8pMlGZ0fJhJ4j3mhWTN_MGlOGFZhDW71ifzJx197qwvXe9fvdGkWRHJl_ayMB3SQ7yed9cc2K9g7jTAdq3kl0X3Z5EXrprHW4GQ0vGWeODkj_LtAMWp6KxHrmXAAXmwWbL_bclE2BmliiA2juQDVBS8bgJUoykizi0GtEQAq-xFMB56__cpvedINfEdJu80ZQq1kTvL4JyZsEEAj2jA7XVjpyLMUUVU_IsjWbiO8hgG68Cs0uMZsUjIsdhYF-pg_Dr2yCc6fU0m9xiBgaiWFGRyyK37DicrQfrhq9L_0mucWD528hPBxVPBcMzQLsfZugyigtfOFyoZHPZM5LoSax7lGOJLBUeb9koMwOirOH7p_XZscMnnqlQe03a55IRqq0JYurG5zLXtDKNxr0vGAyDnjK2xNdVU0qLJ1TtLruAIlefuoq5PPbxg4TvHqt_6q1oymRg&session_state=ea2b35e9-541d-4917-8444-c8cd9a1ac358#
  // URL: http://localhost:3000/microsoft/redirect?code=1.AVMA9EHIGPpunkKssN0f5SsveLct9_7kQn9GsejLQKy27_3FANVTAA.AgABBAIAAADW6jl31mB3T7ugrWTT8pFeAwDs_wUA9P8hlcgQ6jFOiqso-IqxltpTC8aBxmfjTMBNWzm7TbmvHYcjsV1FnQveXeeJwuOXMjDurruFw6Hn3TQq0k-AWegaBWmqdP_lBDTS4__cfBlqmlOPyNqVDEtEu6qk3VRT88gywj2Md_z2EUAosjrk2PxXLHQZJarurquW8gY-OV3EUnbfpSM92UIhmXNHBk4Pc24ipPeKpCul_GQPqn5jLufefLU3bm9tohqwSLm5gf1tLy1IM-CKj71wMf1R5btgNZ96FdrlbwWfmhaz1B4Gbo-KjANoEoRrBH1LEbrDcpDyLAl59OkMW5nDDAMeMm3xctH5u_p5DXgJc9HuFvKztqs2sTytMJEB3rz67Xa-UpFbPzOUG6RqJ7PGbbLk_ky2x5eft7zSwavw5c-LUY7WZUCwRkZ9dyFf8aabUTbaESYD4WfzmlG2hPfOzUN_6zmvAfBIcvU-8NAaKWTbgUom0HFvWABEAMhZPZZTH34tMKHl2Y9fsFNqEd6p5J_lSv-MkQ2jpSMt5L7UYPgPOgNBnmfcqyACcjO-Tj3BK4LLOMdQW3BS4MKuog-pYK_Z6NKyK53FuAiuzba9ZVFlOW7LvGvnGhfQv4OojlCiX2PzUETqodnAPl0-SNiSt3Nur7xheFqUPBdp1hHsHsw4pORDRUZI-FV1WHZmprJhTXdChp5ZMwTfW8kzVNyk0IdjgCqtZz25UtKjh8S01SPHiEk&session_state=ea2b35e9-541d-4917-8444-c8cd9a1ac358#
};

export const OauthMicrosoftToken = async (code) => {
  const url = "https://login.microsoftonline.com/common/oauth2/v2.0/token";
  const codeVerifier = localStorage.getItem("pkce_code_verifier");
  const params = new URLSearchParams();
  params.append("client_id", process.env.REACT_APP_MICROSOFT_CLIENT_ID); // Replace with your app's client ID
  params.append("scope", "openid offline_access Files.Read.All ");
  params.append("redirect_uri", "http://localhost:3000/microsoft/redirect"); // Replace with your redirect URI
  params.append("grant_type", "authorization_code");
  params.append("code", code); // The authorization code from the redirect
  params.append("code_verifier", codeVerifier);
  const resp = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: params.toString(),
    mode: "cors",
  });
  if (!resp.ok) {
    const errorData = await resp.json();
    console.error("Error:", errorData);
    return errorData;
  }
  const data = await resp.json();
  localStorage.setItem("one_drive", JSON.stringify(data));
  window.close();
};

export const LoginHandler = async (code) => {
  const url = "https://login.microsoftonline.com/common/oauth2/v2.0/token";
  const codeVerifier = localStorage.getItem("pkce_code_verifier");
  const params = new URLSearchParams();
  params.append("client_id", process.env.REACT_APP_MS_CLIENT); // Replace with your app's client ID
  params.append("scope", "openid email User.Read");
  params.append("redirect_uri", "http://localhost:3000/callback/microsoft"); // Replace with your redirect URI
  params.append("grant_type", "authorization_code");
  params.append("code", code); // The authorization code from the redirect
  params.append("code_verifier", codeVerifier);
  const resp = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: params.toString(),
    mode: "cors",
  });
  const data = await resp.json();
  console.log("resp", data.access_token);
  const resp2 = await fetch("https://graph.microsoft.com/v1.0/me", {
    method: "GET",
    headers: { Authorization: `Bearer ${data.access_token}` },
  });
  if (!resp2.ok) {
    const errorData = await resp2.json();
    console.error("Error:", errorData);
    return errorData;
  }
  const data2 = await resp2.json();
  console.log("email", data2);
  const userEmail = data2.mail;
  const navigate_route = await NavigateSign(
    userEmail,
    "",
    ""
  );
  const route = navigate_route ?? "/";
  return route;
};
