import React, { useState, useEffect } from 'react'
import { Input, Button } from "@nextui-org/react";
import { test_schema } from '../../../controllers/vectordbController';
import { convertKeysToLowercase } from '../../../controllers/basicControllers';
import { CommonCollectionFilterApi, userLoginDetails } from '../../../controllers/strapiController';
import { toast, Toaster } from "react-hot-toast";
import ButtonSpinner from '../../../components/Loader/ButtonSpinner';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Redendent from '../Redendent';
import { spaceData } from '../../../redux/actions';
import OAuthButton from '../../../components/OAuthButton';

const DB_Selector = ({ formData, setFormData, onPrevious, onNext, selectProduct }) => {
    const [JsonData, setJsonData] = useState();
    const [formDB, setFormDB] = useState();
    const [errors, setErrors] = useState({});
    const [buttonLoad, setButtonLoad] = useState(false);
    const [renderpage, setRenderPage] = useState(false);
    const userId = userLoginDetails()?.userId;
    const convertedData = useSelector(spaceData);
    const [agents, setAgents] = useState([]);
    const params = useParams();
    const [disabled,setDisabled] = useState(false);
    useEffect(() => {
        if(formData?.dataProduct?.attributes?.Name === "One Drive"){
            if(formData?.driveInfo){
                setDisabled(false);
            }
            else{
                setDisabled(true)
            }
        }
    },[formData])
    
    useEffect(() => {

        const url2 = `filters[space][Name][$eq]=${params._spaceid}&filters[SelectAll][$eq]=${true}`;
        CommonCollectionFilterApi("agents", url2)
            .then((data) => { setAgents(data.data) })
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
        // Update jsonData state and log it when selectProduct changes
        const data = selectProduct?.attributes?.Schema;
        setJsonData(data);
        if (!formData?.DBJson) {

            data?.properties?.filter((a) => a.type === "input")?.map((data) => (
                setFormDB(prev => ({
                    ...prev,
                    [data?.name]: ''
                }))
            ))
        } else {
            setFormDB(formData?.DBJson)
        }
    }, [selectProduct]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormDB((prev) => ({
            ...prev,
            [name]: value,
        }));
        // Clear error on input change
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    const validateField = (field, value) => {
        let error = '';
        if (!value) {
            error = `${field.name} is required`;
        }
        return error;
    }

    const validation = () => {
        let isValid = true;
        const newErrors = {};

        JsonData?.properties?.filter((a) => a.type === "input")?.forEach((field) => {
            const error = validateField(field, formDB[field.name]);
            if (error) {
                newErrors[field.name] = error;
                isValid = false;
            }
        });
        setErrors(newErrors);
        return isValid;
    }

    useEffect(() => {
        const relation = agents?.map((data, index) => {
            if (index === 0) {
                return { "id": parseInt(data?.id), "position": { "end": true } }
            }
            return { "id": parseInt(data?.id), "position": { "before": parseInt(agents[index - 1]?.id) } }

        })
        setFormData && setFormData(prev => ({ ...prev, agentRelation: relation }))

    }, [agents])

    const handleSubmit = async () => {

        const validate = validation();
        if (validate) {
            setButtonLoad(true);
            localStorage.removeItem('one_drive');
            if (selectProduct?.attributes.Type === "connectors") {
                const ConnectionPayload = {
                    source: selectProduct?.attributes.Name,
                    ...formDB
                };
                const ConnectionPayloadLowerCase = convertKeysToLowercase(ConnectionPayload);
                const response = await test_schema(ConnectionPayloadLowerCase);

                if (response?.tables) {
                    setButtonLoad(false);
                    setFormData(prev => ({
                        ...prev,
                        DBJson: formDB
                    }));
                    onNext();
                } else {
                    setButtonLoad(false);
                    toast.error("Incorrect database credentials.")
                }
            }
            else {
                setFormData(prev => ({ ...prev, DBJson: formDB }))
                setRenderPage(true);
            }
        } else {
            setButtonLoad(false);
        }
    }


    const formCreater = () => {
        return (
            <div className='flex flex-col gap-8 pt-8'>
                {JsonData?.properties?.map((data) => (
                    data.type === "button" ? <OAuthButton setFormData={setFormData} data={data} database={formData?.dataProduct?.attributes?.Name} /> :
                    <Input
                        isRequired
                        key={data.name} // Ensure unique key for each input
                        type="text"
                        htmlFor={data.name}
                        name={data.name}
                        value={formDB?.[data.name] || ''}
                        onChange={handleInputChange}
                        label={data.name}
                        labelPlacement="outside"
                        placeholder={`Enter a ${data.name}`}
                        errorMessage={errors?.[data.name]} // Show error message
                        isInvalid={!!errors?.[data.name]}
                    />
                ))}
            </div>
        );
    };

    return <div>
        {renderpage ? <Redendent setFormData={setFormData} setRenderPage={setRenderPage} formData={formData} convertedData={convertedData} userId={userId} finalJson={""} /> :
            <>
                <Toaster position="bottom-right"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName=""
                    containerStyle={{}}
                    toastOptions={{
                        style: {
                            boxShadow: "none",
                            border: "1px solid #bdb5b5"
                        },
                        error: { duration: 6000 }
                    }} />
                <div className='pb-8'>
                    <h1 className='text-3xl font-medium pb-4'>{selectProduct?.attributes?.Type === "tools" ? `Integrate ${selectProduct?.attributes?.Name} to ${convertedData?.space?.[0]?.attributes?.SpaceName}` : "Connect to your data source"}</h1>
                    <p>Connect to your source by providing your credentials</p>
                </div>
                {formCreater()}
                <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
                    <Button variant="light" onClick={onPrevious}>Back</Button>
                    <Button isLoading={buttonLoad} color="danger" spinner={buttonLoad ? ButtonSpinner() : ""} isDisabled={!formData.type || disabled} onClick={handleSubmit}>Next</Button>
                </div>
            </>}
    </div>

}
export default DB_Selector;