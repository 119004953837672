import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  useDisclosure,
  Avatar,
  CircularProgress,
  Chip,
  Textarea,
  Input,
  ModalBody,
  ModalHeader,
} from "@nextui-org/react";
import ModalPopup from ".././Collections/ModalPopup";
import {
  CommonCollectionFilterApi,
  uploadFile,
  CreateData,
} from "../../controllers/strapiController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useParams, useNavigate } from "react-router-dom";
import { colorPicker } from "../../controllers/basicControllers";
import { toast } from "react-hot-toast";

const CreateTeam = ({ spaceData }) => {
  // STATE INITIALIZATION
  const {
    isOpen: isTeamOpen,
    onOpen: onTeamOpen,
    onOpenChange: onTeamOpenChange,
  } = useDisclosure();
  const [FileUpload, setFileUpload] = useState(null);
  const [tagInput, setTagInput] = useState("");
  const [fileUploaded, setFileUploaded] = useState();
  const [imageLoader, setImageLoader] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [Validatetags, setValidatetags] = useState("");
  const [selectedTag, setselectedTag] = useState(null);
  const [userDetails, setUserDatails] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [Validator, setValidator] = useState(false);
  const fileInputRef = useRef();
  const params = useParams();
  const [emails, setEmails] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [displayedItems, setDisplayedItems] = useState();
  const ref = useRef();
  const TextRef = useRef();
  const navigate = useNavigate();
  const [formData, setFormdata] = useState({
    Name: "",
    Description: "",
    Logo: "",
    Members: [],
  });
  const [errorData, seterrorData] = useState({
    NameError: "",
    DescriptionError: "",
    LogoError: "",
  });
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = userData && userData.user && userData.user.id;

  useEffect(() => {
    if (showAll) {
      handleShowMore();
    } else {
      setDisplayedItems(emails.slice(0, 5));
    }
  }, [emails]);

  const handleShowMore = () => {
    setDisplayedItems(emails);
    setShowAll(true);
  };

  const handleShowLess = () => {
    emails && setDisplayedItems(emails.slice(0, 5));
    setShowAll(false);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleTagRemove = (tagToRemove) => {
    setEmails(emails.filter((tag) => tag !== tagToRemove));
    setFormdata(() => ({
      ...formData,
      members: emails.filter((tag) => tag !== tagToRemove),
    }));
  };

  const Text = tagInput.trim().toLowerCase();

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];
      setFileUpload(file);
    } else {
      setFileUpload(null);
    }
  };

  const UploadFileHandler = async () => {
    setImageLoader(true);
    let formData = new FormData();
    formData.append("files", FileUpload);
    const response = await uploadFile(formData);
    if (response) {
      setImageLoader(false);
      const URLresponse = response && response[0];
      URLresponse && setFileUploaded(URLresponse);
      setFormdata((prev) => ({ ...prev, Logo: URLresponse }));
    }
  };

  useEffect(() => {
    UploadFileHandler();
  }, [FileUpload]);

  useEffect(() => {
    const url2 = `filters[$or][0][spaces][Name][$in]=${params._spaceid}&filters[$or][1][space][Name][$in]=${params._spaceid}&[populate][Picture][on]&[populate][spaces][on]&[populate][space][on]`;
    CommonCollectionFilterApi("users", url2)
      .then((data) => setUserDatails(data))
      .catch((error) =>
        toast.error("Oops!, something went wrong, please try after some time.")
      );
  }, []);

  const FilteredUser =
    userDetails && userDetails.filter((data) => data.id !== UserId);
  const FilteredData =
    Text !== ""
      ? FilteredUser &&
        FilteredUser.filter((data) => data.email.toLowerCase().includes(Text))
      : FilteredUser;
  const EmailVerify =
    FilteredData &&
    FilteredData.some((data) => data.email.toLowerCase() === Text);

  const HandleOnClickInput = (data) => {
    setselectedTag(data.email);
    if (!emails.includes(data.email) && !EmailVerify) {
      setEmails((previous) => [data.email, ...previous]);
      setValidatetags(null);
      setTagInput("");
      setFormdata((previous) => ({
        ...previous,
        Members: [...previous.Members, data.id],
      }));
    } else {
      setValidatetags("Entered email already exists.");
    }
  };

  const handleKeyPress = (e) => {
    if (tagInput.trim() !== "" && e.key === "Enter") {
      if (!emails.includes(Text) && EmailVerify) {
        setEmails((previous) => [Text, ...previous]);
        setValidatetags("");
        const Filter =
          FilteredData && FilteredData.find((data) => data.email === Text);
        setFormdata((previous) => ({
          ...previous,
          Members: [...previous.Members, Filter.id],
        }));
        setTagInput("");
      } else {
        setValidatetags("Entered email already exists.");
      }
    }
  };

  const resultsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (onFocus) {
          setIsClicked(true);
        } else {
          setIsClicked(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const renderTags = () => {
    return (
      displayedItems &&
      displayedItems.map((email, index) => (
        <>
          {" "}
          <Chip
            key={`${index}`}
            className=""
            size="sm"
            color={colorPicker(6)}
            variant="flat"
            onClose={() => handleTagRemove(email)}
          >
            {email}
          </Chip>
        </>
      ))
    );
  };

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [tagInput]);

  const handleInputFocus = () => {
    setOnFocus(true);
    setIsClicked(true);
  };

  const UploadedUser =
    fileUploaded &&
    fileUploaded !== null &&
    `${
      process.env.REACT_APP_STAGE === "dev"
        ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${fileUploaded.url}`
        : `${fileUploaded?.url}`
    }`;

  const Validation = () => {
    var isValid = true;

    if (!formData.Name) {
      isValid = false;
      seterrorData((previous) => ({
        ...previous,
        NameError: "Name is required.",
      }));
    } else {
      seterrorData((previous) => ({ ...previous, NameError: null }));
    }

    if (!formData.Description) {
      isValid = false;
      seterrorData((previous) => ({
        ...previous,
        DescriptionError: "Description is required.",
      }));
    } else {
      seterrorData((previous) => ({ ...previous, DescriptionError: null }));
    }
    return isValid;
  };

  const SubmitHandler = async (onClose) => {
    const ConnectPayload = formData.Members.map((data, index) => {
      if (index === 0) {
        return { id: data, position: { end: true } };
      }
      return { id: data, position: { before: formData.Members[index - 1] } };
    });

    const Payload = {
      Name: formData.Name,
      Description: formData.Description,
      members: { disconnect: [], connect: ConnectPayload },
      Logo: formData.Logo,
      author: { disconnect: [], connect: [{ id: UserId, position: "end" }] },
      space: {
        disconnect: [],
        connect: [{ id: spaceData?.id, position: "end" }],
      },
    };

    const Validate = Validation();
    if (Validate) {
      setbuttonLoader(true);
      try {
        const response = await CreateData("teams", Payload);
        if (response) {
          setbuttonLoader(false);
          navigate(`${response.data.id}`);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setValidator(true);
    }
  };

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [tagInput]);

  const ModalBodyData = () => {
    return (
      <>
        <ModalHeader className="flex flex-col mt-4 gap-1">
          Create a new Team
        </ModalHeader>
        <ModalBody className="gap-0 h-96">
          <div className="flex flex-col gap-4">
            <Input
              key="name"
              type="text"
              label="Team name"
              isInvalid={
                !formData.Name && Validator ? !formData.Name || Validator : ""
              }
              errorMessage={
                !formData.Name && Validator ? errorData.NameError : ""
              }
              onChange={(e) =>
                setFormdata({ ...formData, Name: e.target.value })
              }
              labelPlacement="outside"
              placeholder="Enter your team name"
            />
            <Textarea
              key="description"
              variant="flat"
              label="Description"
              isInvalid={
                !formData.Description && Validator
                  ? !formData.Description || Validator
                  : ""
              }
              errorMessage={
                !formData.Description && Validator
                  ? errorData.DescriptionError
                  : ""
              }
              onChange={(e) =>
                setFormdata({ ...formData, Description: e.target.value })
              }
              labelPlacement="outside"
              placeholder="Enter your description"
            />
            <div className="flex flex-row items-center gap-6">
              <Avatar
                radius="full"
                size="lg"
                src={UploadedUser ? UploadedUser : formData.Logo}
                name={
                  formData.Name !== ""
                    ? formData.Name.slice(0, 1).toUpperCase()
                    : "T"
                }
              />
              <input
                ref={fileInputRef}
                type="file"
                onChange={(event) => handleFileChange(event)}
                className="hidden"
              />
              <Button onClick={handleButtonClick} size="sm" variant="bordered">
                Select profile image
              </Button>
              {imageLoader ? (
                <CircularProgress size="sm" aria-label="Loading..." />
              ) : (
                ""
              )}
            </div>
            <div className="relative pt-2">
              <lable className="text-sm">Add users to your team</lable>
              <div
                style={{ Background: "#f4f4f5" }}
                className={`border rounded-xl flex flex-col border-divider p-2`}
              >
                <input
                  className={`bg-transparent border-0 rounded text-sm`}
                  key={"teams"}
                  ref={TextRef}
                  style={{ outline: "none" }}
                  type="text"
                  variant="bordered"
                  labelPlacement={"outside"}
                  placeholder="Press enter to add multiple users"
                  value={tagInput && tagInput.trim()}
                  onChange={(e) => {
                    setTagInput(e.target.value);
                  }}
                  onFocus={handleInputFocus}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleKeyPress(e);
                    }
                  }}
                />
                <div className="pt-4 flex flex-wrap gap-2">
                  {renderTags()}
                  {!showAll ? (
                    <>
                      {emails && emails.length > 5 ? (
                        <Chip className="" size="sm" onClick={handleShowMore}>
                          Show More
                        </Chip>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <Chip className="" size="sm" onClick={handleShowLess}>
                      Show less
                    </Chip>
                  )}
                </div>
                <p className="pt-2 text-xs text-red-400">
                  {emails.includes(Text) ||
                  emails.includes(selectedTag) ||
                  !EmailVerify
                    ? Validatetags
                    : ""}
                </p>
              </div>
              {isClicked === true ? (
                <div
                  ref={ref}
                  className="transition ease-in-out delay-200 absolute z-50 w-full pt-2 pb-8 "
                >
                  <div
                    ref={resultsRef}
                    className={`rounded-xl border w-full border-divider divide-y divide-divider overflow-y-auto  bg-white`}
                    style={{ maxHeight: "325px" }}
                  >
                    {FilteredData && FilteredData.length > 0 ? (
                      <>
                        {FilteredData &&
                          FilteredData.map((data) => {
                            const TagsCheck =
                              emails &&
                              emails.some((tag) => tag === data.email);
                            return (
                              <div
                                onClick={() => {
                                  HandleOnClickInput(data);
                                }}
                                className="p-2 text-sm flex flex-row gap-2 items-center justify-between"
                              >
                                <div
                                  key={data?.id}
                                  className="flex flex-row gap-2 items-center"
                                >
                                  <lord-icon
                                    src="https://cdn.lordicon.com/prjooket.json"
                                    trigger="hover"
                                    style={{ width: "16px", height: "16px" }}
                                  ></lord-icon>
                                  {data.email}{" "}
                                </div>
                                {TagsCheck ? (
                                  <div>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {Text !== "" ? (
                          <p className="p-2 text-sm">No related data found </p>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </ModalBody>
      </>
    );
  };

  const footerCreate = (onClose) => {
    return (
      <>
        <Button
          color="default"
          variant="flat"
          onClick={() => {
            setFormdata({});
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          isLoading={buttonLoader}
          onClick={() => {
            SubmitHandler(onClose);
          }}
        >
          Create
        </Button>
      </>
    );
  };

  return (
    <>
      <Button
        color="danger"
        onClick={() => {
          onTeamOpen();
          setFormdata({ Name: "", Description: "", Logo: "", Members: [] });
        }}
      >
        + Create new Team
      </Button>
      <ModalPopup
        size="2xl"
        isOpen={isTeamOpen}
        onOpenChange={onTeamOpenChange}
        ModalBodyData={ModalBodyData}
        footer={footerCreate}
        scrollBehavior="inside"
      />
    </>
  );
};

export default CreateTeam;
