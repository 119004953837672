import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import { BarChart, Bar } from "recharts";
import { PieChart, Pie, Cell, Tooltip as PieTooltip } from "recharts";
import { ScatterChart, Scatter } from "recharts";
import { LineChart, Line } from "recharts";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import { randomColorGenerator } from "../../Utils/charts.utils";
import TableChart from "./TableChart";
import TimeSeriesChart from "./TimeSeriesChart";
import HeatMap from "./HeatMap";

const ChartGenerator = ({ chartType, data, id, chartInfo,onAddCustomFilter,dbConfig,sql }) => {
  const xAxisName = Object.keys(data[0])[0];
  const yAxisName = Object.keys(data[0])[1];
  const chartColor = randomColorGenerator(id);


  switch (chartType) {
    case "area chart":
      return (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data}>
            {/* <defs>
              <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#8884d8" stopOpacity={0.9} />
                <stop offset="100%" stopColor="#ffffff" stopOpacity={0.5} />
              </linearGradient>
            </defs> */}
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisName} />
            <YAxis dataKey={yAxisName} hide={true}></YAxis>
            <Tooltip />
            <Area
              type="monotone"
              dataKey={yAxisName}
              stackId="1"
              stroke={chartColor.arr[0]}
              fill={chartColor.arr[0]} // Using gradient here
            ></Area>
          </AreaChart>
        </ResponsiveContainer>
      );

    case "bar chart":
      return (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            {/* <defs>
              <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#8884d8" stopOpacity={0.9} />
                <stop offset="100%" stopColor="#ffffff" stopOpacity={0.5} />
              </linearGradient>
            </defs> */}
            <Tooltip
              contentStyle={{
                outline: "none", // Removes the outline
                border: "1px solid #F0F3F7", // Adds the custom border with 'border-divider' color
                borderRadius: "4px", // Optional: Add rounded corners if needed
              }}
              labelClassName=" font-bold"
              cursor={{ fill: "transparent" }}
            />
            <CartesianGrid vertical={false} stroke="#F0F3F7" />
            <XAxis
              stroke="#F0F3F7"
              tick={{ fill: "#000000", fontSize: 12 }}
              dataKey={xAxisName}
            />
            <YAxis dataKey={yAxisName} hide={true}></YAxis>

            <Bar
              barSize={30}
              dataKey={yAxisName}
              fill={chartColor.arr[0]}
              radius={[6, 6, 6, 6]}
            />
          </BarChart>
        </ResponsiveContainer>
      );

    case "pie chart":
      return (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              dataKey={yAxisName}
              nameKey="name"
              innerRadius={50}
              outerRadius={100}
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  // fill={BlueColorArray[index % blueSize]}
                  fill={chartColor.arr[index % chartColor.len]}
                  name={entry[xAxisName]}
                />
              ))}
            </Pie>
            <PieTooltip
              contentStyle={{
                outline: "none", // Removes the outline
                border: "1px solid #F0F3F7", // Adds the custom border with 'border-divider' color
                borderRadius: "4px", // Optional: Add rounded corners if needed
              }}
              labelClassName=" font-bold"
              cursor={{ fill: "transparent" }}
            />
          </PieChart>
        </ResponsiveContainer>
      );

    case "scatter chart":
      return (
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart data={data}>
            <CartesianGrid />
            <XAxis dataKey={xAxisName} />
            <YAxis dataKey={yAxisName}>
              <Label
                value={yAxisName || ""}
                offset={30}
                angle={-90}
                position={"left"}
                dy={-50}
                style={{ fontWeight: "bold" }}
              />
            </YAxis>
            <Tooltip />
            <Scatter dataKey={yAxisName} data={data} fill={chartColor.arr[0]} />
          </ScatterChart>
        </ResponsiveContainer>
      );

    case "line chart":
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisName} />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey={yAxisName}
              stroke={chartColor.arr[0]}
            />
          </LineChart>
        </ResponsiveContainer>
      );

    case "radar chart":
      return (
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart data={data}>
            <PolarGrid />
            <PolarAngleAxis tick={{ fontSize: 12 }} dataKey={xAxisName} />
            <PolarRadiusAxis />
            <Tooltip
              contentStyle={{
                outline: "none", // Removes the outline
                border: "1px solid #F0F3F7", // Adds the custom border with 'border-divider' color
                borderRadius: "4px", // Optional: Add rounded corners if needed
              }}
              labelClassName=" font-bold"
              cursor={{ fill: "transparent" }}
            />
            <Radar
              name={yAxisName}
              dataKey={yAxisName}
              stroke={chartColor.arr[0]}
              fill={chartColor.arr[0]}
              fillOpacity={0.6}
            />
          </RadarChart>
        </ResponsiveContainer>
      );
    case "table chart":
      return <TableChart TableData={data} />;
    case "time series chart":
      return (
        <TimeSeriesChart
          chartInfo={chartInfo}
          chartType={chartType}
          data={data}
        />
      );
    case "heat map":
      return (
        <HeatMap chartInfo={chartInfo} chartType={chartType} data={data} onAddCustomFilter={onAddCustomFilter} sql = {sql} dbConfig={dbConfig}/>
      );
    default:
      return <div>Invalid chart type</div>;
  }
};

export default ChartGenerator;
