import React, { useState } from "react";
import {
  defaultChartConfigData,
  formatEpochDate,
  formatLargeNumber,
} from "../../Utils/charts.utils";
import {
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { StringLimit } from "../../controllers/basicControllers";

const CustomeToolTip = ({ data }) => {
  const { payload, yAxis, xAxis } = data;

  if (payload) {
    return (
      <div
        className="flex flex-col items-center bg-black text-white p-2 rounded-sm text-sm max-w-[300px]"
        style={{
          position: "absolute",
          top: 0,
          left: "25%",
          background: "rgba(0, 0, 0, 0.75)",
          color: "#fff",
          padding: "5px 10px",
          borderRadius: "4px",
          pointerEvents: "none",
          zIndex: 1000,
        }}
      >
        <p className="">{formatEpochDate(payload?.[xAxis])}</p>
        <p className="">
          {yAxis} :{payload?.[yAxis]}{" "}
        </p>
      </div>
    );
  }
  return null;
};
const TimeSeriesChart = ({
  chartInfo,
  chartType,
  data,
  onAddCustomFilter = () => {},
}) => {

  const [hoveredBar, setHoveredBar] = useState(null);
  const lowerLimit = chartInfo?.minThreshold || 10;
  const upperLimit = chartInfo?.maxThreshold || 30;

  const handleMouseEnter = (payload, xAxis, yAxis) => {
    setHoveredBar({
      payload: payload,
      xAxis: xAxis,
      yAxis: yAxis,
    });
  };
  const handleMouseLeave = () => {
    setHoveredBar(null);
  };
  const colors = {
    lower: "#c20e29",
    upper: "#0c59cc",
    default: "#7d7e80",
  };

  const getColorBasedOnValue = (value) => {
    if (value < lowerLimit) {
      return colors.lower;
    } else if (value > upperLimit) {
      return colors.upper;
    }
    return colors.default;
  };

  const clickHandler = (data, xAxis, yAxis) => {
    console.log("helllo", data, xAxis, yAxis);

    const parts = xAxis?.split("_");
    const type = parts.pop();
    let selectedFilterColumn = {};
    let query = "";
    let date = new Date(data?.[xAxis]).toISOString();
    if (["day", "week", "month", "year"].includes(type)) {
      const columnName = parts.pop();
      const tableName = parts.join("_");
      selectedFilterColumn = {
        table: tableName,
        column: columnName,
        type: "TIMESTAMP",
        date_type: type,
      };
      query = `(DATE_TRUNC('${type.toUpperCase()}', ${columnName})) = DATE_TRUNC(${type.toUpperCase()},CAST('${date}' AS timestamp))`;
    } else {
      const tableName = parts.join("_");
      selectedFilterColumn = {
        table: tableName,
        column: type,
        type: "TIMESTAMP",
        date_type: "raw",
      };
      query = `${type} = CAST(${date} AS timestamp)`;
    }
    const d = {
      selectedFilterColumn: selectedFilterColumn,
      filteredType: "is",
      filteredValue: null,
      customChartFilter: {},
      attachedTo: null,
      DateFilterValue: {
        startDate: date,
        endDate: date,
        inTheCurrent: "",
        inTheLastNext: {
          num: 1,
          text: "",
        },
      },
      selectedFilteredColumnOption: null,
      id: Date.now(),
      query: query,
    };

    console.log(d);
    onAddCustomFilter(d);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        minHeight: "200px",
        position: "relative",
      }}
    >
      {hoveredBar && <CustomeToolTip data={hoveredBar} />}
      <ResponsiveContainer width="100%" height={"100%"}>
        <LineChart
          data={data}
          margin={{
            top: defaultChartConfigData?.topMargin,
            right: defaultChartConfigData?.rightMargin,
            left: defaultChartConfigData?.leftMargin,
            bottom: defaultChartConfigData?.bottomMargin,
          }}
        >
          <XAxis
            dataKey={chartInfo?.xAxis}
            textAnchor="end"
            tickFormatter={formatEpochDate}
          >
            <Label
              value={chartInfo?.xAxisLabel || chartInfo?.xAxis}
              //   offset={5}
              position="bottom"
              style={{ fontWeight: "bold", fontSize: 12 }}
            />
          </XAxis>

          <YAxis
            orientation={chartInfo?.yAxis?.orientation || "left"}
            tickFormatter={formatLargeNumber}
          >
            <Label
              value={StringLimit(
                chartInfo?.yAxisLabel || chartInfo?.yAxis || "",
                20
              )}
              angle={-90}
              position={"left"}
              dy={-30}
              dx={15}
              style={{ fontWeight: "bold", fontSize: 12 }}
            />
          </YAxis>

          <ReferenceLine
            y={lowerLimit}
            stroke={colors["lower"]}
            strokeDasharray="3 3"
          />
          <ReferenceLine
            y={upperLimit}
            stroke={colors["upper"]}
            strokeDasharray="3 3"
          />

          <Line
            dataKey={chartInfo?.yAxis}
            name={chartInfo?.yAxis || chartInfo?.yAxis}
            stroke={"#7d7e80"}
            dot={(props) => {
              const { cx, cy, payload } = props;
              const color = getColorBasedOnValue(payload[chartInfo?.yAxis]);
              return (
                <circle
                  cx={cx}
                  cy={cy}
                  r={4}
                  fill={color}
                  stroke={color}
                  strokeWidth={1}
                  onMouseEnter={(e) => {
                    handleMouseEnter(
                      payload,
                      chartInfo?.xAxis,
                      chartInfo?.yAxis
                    );
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event bubbling
                    // handleMouseEnter(payload, label);
                    clickHandler(payload, chartInfo?.xAxis, chartInfo?.yAxis);
                  }} // Attach onClick to the dot
                  onMouseLeave={handleMouseLeave}
                  style={{
                    cursor: "pointer",
                    pointerEvents: "visiblePainted", // Allow clicks on visible parts of the element
                  }}
                />
              );
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TimeSeriesChart;
