import React from 'react';
import AgentTools from '../CreateAgents/AgentTools';

const Instructions = ({collection, setReload, setLoader}) => {
    
  return (
    <div >
        <h1 className="pt-8 pb-8 text-xl font-medium">Instructions</h1>
       <AgentTools agent={collection} setReload={setReload}/>
    </div>
  )
}

export default Instructions