import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { LoginHandler, OauthMicrosoftToken } from '../../controllers/OAuthControllers';

const AuthMS = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");
    
        const fetchToken = async() => {
            try{
                await LoginHandler(code);
                navigate('/');
            }
            catch(e){
                console.log(e)
            }
        }
    
        if (code) {
          // Handle the code, e.g., send it to the server to exchange for tokens
            fetchToken();
    
        }
      }, [navigate]);
  return (
    <div>
      Redirecting...
    </div>
  )
}

export default AuthMS
