import React from "react";
import ChartGenerator from "./ChartGenerator";

const Charts = ({ data, type }) => {
  return (
    <div>
      <ChartGenerator chartType={type} data={data} />
    </div>
  );
};

export default Charts;
