import {
    Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Image,
  Input,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "../Images/Search";
import {
  CommonCollectionFilterApi,
  userLoginDetails,
} from "../controllers/strapiController";
import { colorPicker, StringLimit } from "../controllers/basicControllers";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { useSelector } from "react-redux";
import { creditData, userData } from "../redux/actions";
import Credits from "./Credits";
import BreadcrumbsTabs from "./BreadcrumbsTabs";

const Header = ({ params,UserDetails,userItems }) => {
  //STATE INITIALIZATION
  const [InputText, setInputText] = useState("");
  const [showResults, setShowResults] = useState(true);
  const collectionName = "collections";
  const [AllCollections, setAllCollections] = useState(null);
  const Location = useLocation();
  const URLPath = Location.pathname.split("/");
  const [searchResults, setSearchResults] = useState([]);
  const Navigate = useNavigate();
  const credits = sessionStorage.getItem("Credits")
    ? sessionStorage.getItem("Credits")
    : userLoginDetails()?.userDetails?.user?.Credit;
  const userCredits = useSelector(creditData);
  const userDetails = useSelector(userData)
  // HANDLE ENTER KEY
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && InputText.trim() !== "") {
      Navigate(`/${params._spaceid}/search/${InputText}`);
      setInputText("");
    }
  };

  // console.log("UserDetails:", userDetails)
  

  // API FOR COLLECTIONS RESPONSE
  useEffect(() => {
    const url = `filters[space][Name][$eq]=${params._spaceid}&[populate][author][on]&[populate][inviteds][on]&[populate][viewed][on]&[populate][requests][on]&[populate][teams][on]&[populate][space][on]`;
    CommonCollectionFilterApi(collectionName, url)
      .then((data) => setAllCollections(data.data))
      .catch((err) => err);

    const url2 = `filters[space][Name][$eq]=${params._spaceid}`;
  }, [collectionName]);

  // SEARCH INPUT VALUE ONCHANGE EVENT
  const HandleChange = (event) => {
    const TextValue = event.target.value;
    setInputText(TextValue);
    const Filterdata =
      AllCollections &&
      AllCollections.filter(
        (data) =>
          data.attributes &&
          data.attributes.Name.toLowerCase().includes(TextValue.toLowerCase())
      );
    setSearchResults(Filterdata);
  };

  const resultsRef = useRef(null);

  // SCROLL HANDLER
  useEffect(() => {
    function handleClickOutside(event) {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setShowResults(!showResults);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [resultsRef, showResults]);

  useEffect(() => {
    setShowResults(true);
  }, [InputText]);

  return (
    <div className="flex h-[60px] items-center justify-between border-b border-solid border-[#EEEEEE] px-8">
      
        <BreadcrumbsTabs />
      
        <Dropdown
          radius="sm"
          size="lg"
          classNames={{
            base: "before:bg-default-200", // change arrow background
            content: "p-0 border-small border-divider bg-background",
          }}
        >
          <DropdownTrigger>
            <div className="flex items-center justify-evenly cursor-pointer">
              <Avatar
                className="h-8 w-8"
                color={userDetails && colorPicker(userDetails?.id)}
                name={userDetails?.email?.toUpperCase().slice(0, 1)}
                src={
                  userDetails?.Picture !== null &&
                  `${
                    process.env.REACT_APP_STAGE === "dev"
                      ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${userDetails?.Picture?.url}`
                      : userDetails?.Picture?.url
                  }`
                }
              />
              
            </div>
          </DropdownTrigger>
          <NextThemesProvider>
            <DropdownMenu
              aria-label="Custom item styles"
              className="p-2 -mb-1"
              itemClasses={{
                base: [
                  "rounded-md",
                  "text-default-500",
                  "transition-opacity",
                  "data-[hover=true]:text-foreground",
                  "data-[hover=true]:bg-default-100",
                  "dark:data-[hover=true]:bg-default-50",
                  "data-[selectable=true]:focus:bg-default-50",
                  "data-[pressed=true]:opacity-70",
                  "data-[focus-visible=true]:ring-default-500",
                ],
              }}
            >
              <DropdownSection aria-label="Profile & Actions">
                {userItems &&
                  userItems.map((items) => {
                    return (
                      <DropdownItem
                        id={items.id}
                        onClick={items.Click}
                        onPress={() => items.Click}
                        startContent={items.icon}
                      >
                        <span className="font-bold">{items.title}</span>
                      </DropdownItem>
                    );
                  })}
              </DropdownSection>
            </DropdownMenu>
          </NextThemesProvider>
        </Dropdown>
      {/* <div className='flex h-[60px] items-center justify-end border-b border-divider px-6'>
                <div className='relative w-full '>
                    <div className='w-full relative'>
                        <Input
                            classNames=""
                            onChange={HandleChange}
                            value={InputText}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    handleKeyPress(event);
                                }
                            }}
                            placeholder='Search by files, tags, content...' className='px-8'
                            startContent={<SearchIcon className={`text-large text- pointer-events-none flex-shrink-0 `} />}
                        />
                    </div>
                    {InputText !== "" ? <> {showResults && (
                        <div className='transition ease-in-out delay-200 absolute z-50 w-full px-8 pt-2 '>
                            <div ref={resultsRef} className={`rounded-xl border border-divider divide-y divide-divider overflow-y-auto min-h-16 bg-white`} style={{ maxHeight: "325px" }} >
                                <div className='p-4' onClick={() => { Navigate(`/${params._spaceid}/search/${InputText}`); setInputText('') }}>
                                    <p id='File' className='flex flex-row items-center cursor-pointer text-foreground gap-2'>
                                        <lord-icon
                                            src="https://cdn.lordicon.com/kkvxgpti.json"
                                            trigger="hover"
                                            colors={`primary:"#000000"`}
                                            style={{ width: "20px", height: "20px" }}>
                                        </lord-icon>  Search for "{InputText}"
                                    </p>
                                </div>
                                {searchResults && searchResults.length > 0 ?
                                    <>
                                        {searchResults && searchResults.map((data, index) => {
                                            const name = data.attributes.Name;
                                            const regex = new RegExp(`(${InputText})`, 'i');
                                            const parts = name.split(regex);
                                            const highlightedIndex = parts.findIndex(part => part.toLowerCase() === InputText.toLowerCase());
                                            return (
                                                <div key={index} className='p-4 cursor-pointer' onClick={() => { Navigate(`/${params._spaceid}/search/${data.attributes.Name}`); setInputText('') }}>
                                                    <p id='File' className='flex flex-row items-center gap-1'>
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/jkzgajyr.json"
                                                            trigger="hover"
                                                            target="#File"
                                                            colors={`primary:"#000000"`}
                                                            style={{ width: "20px", height: "20px", paddingRight: "8px" }}>
                                                        </lord-icon>
                                                        <p>
                                                            {parts.map((part, i) => {
                                                                const NewFormatData = part.endsWith(" ") && `${part} `
                                                                return <span key={i}>
                                                                    {i === highlightedIndex ?
                                                                        <span className='bg-indigo-200 text-black'>{part}</span>
                                                                        : <span className='whitespace-nowrap'>{NewFormatData ? (<>{part.trim()}<span className='invisible'>_</span></>) : part}</span>
                                                                    }
                                                                </span>
                                                            })}
                                                        </p>
                                                    </p>
                                                    <p className='text-slate-400 text-sm' >{data.attributes.Description && StringLimit(data.attributes.Description, 250)}</p>
                                                </div>
                                            );
                                        })}</>
                                    : ""
                                }
                            </div>
                        </div>)}</> : ""}
                </div>
                <div className='flex flex-row items-center gap-3  h-14'>
                    <span className='flex h-8 items-center gap-2 rounded-md px-2 text-sm text-grey-black hover:bg-grey-200 active:bg-grey-200 pt-4 cursor-pointer '>
                        <div className='h-8 rounded-md' >
                            <Dropdown placement='bottom-end' className={``}>
                                <DropdownTrigger >
                                    <lord-icon
                                        src="https://cdn.lordicon.com/vspbqszr.json"
                                        trigger="hover"
                                        colors={`primary:"#000000"`}
                                        style={{ width: "20px", height: "20px" }}>
                                    </lord-icon>
                                </DropdownTrigger>
                                <NextThemesProvider>
                                    <DropdownMenu className="">
                                        <DropdownItem>
                                            <p className='cursor-default'>No notifications</p>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </NextThemesProvider>
                            </Dropdown>
                        </div>
                    </span>
                    
                  <Credits/>
                </div>
            </div> */}
    </div>
  );
};

export default Header;
