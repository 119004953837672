import { Tabs, Tab, Avatar, Chip, Button } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { CommonCollectionFilterApi } from '../../controllers/strapiController';
import NotFound from '../../components/NotFound';
import ServerIssue from '../../components/ServerIssue';
import { Toaster } from 'react-hot-toast';
import Documents from './CollectionTabs/Documents';
import CollectionMembers from './CollectionTabs/Collection_Members';
import { collectionSkeleton } from '../../components/Skeleton';
import { colorPicker, titleCreater } from '../../controllers/basicControllers';
import Settings from '../subPages/Settings';
import Configuration from '../subPages/Configuration';

const SingleCollection = () => {
  const [collectionData, setCollection] = useState(null);
  const [Loader, setLoader] = useState(true);
  const [Response, setReload] = useState('');
  const CollectionName = "collections";
  const params = useParams();
  const [notFound, setNotFound] = useState(false);
  const [Forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [connectionRefused, setConnectonRefused] = useState(false);
  const UserDetails = JSON.parse(sessionStorage.getItem("userData"));
  const UserID = UserDetails && UserDetails.user && UserDetails.user.id;

  useEffect(() => {
    const url = `filters[id][$in][0]=${params._id}&filters[Type][$eq]=${CollectionName}&filters[space][Name][$eq]=${params._spaceid}&[populate][space][on]&[populate][data_product][on]&[populate][author][on]&[populate][inviteds][on]&[populate][requests][on]&[populate][viewed][on]`
    CommonCollectionFilterApi(CollectionName, url)
      .then((data) => {
        setLoader(false)
        if (data.error) {
          if (data.error.status !== 404 && data.error.status !== 403) { setServerIssue(true) }
          if (data.error.status === 404) { setNotFound(true) }
          if (data.error.status === 403) { setForbidden(true) }
        } else if (data.data.length > 0) {
          setLoader(false)
          setCollection(data.data)
        } else {
          setNotFound(true)
        }
      })
      .catch((error) => { setLoader(false); setConnectonRefused(true) })

  }, [Response, params._id, params._spaceid])

  const Collection = collectionData && collectionData[0];
  const sharedCollections = Collection?.attributes?.inviteds?.data;
  const sharedUser = sharedCollections && sharedCollections.some((datafilter) => datafilter.id === UserID);


  const UserOptions = Collection?.attributes?.author?.data?.id === UserID;

  if (Loader) {
    return collectionSkeleton()
  }

  if (notFound) {
    return <NotFound />
  }

  if (!UserOptions && !sharedUser && Collection.attributes.Public !== true) {
    return <NotFound />
  }

  if (serverIssue) {
    return <ServerIssue />
  }

  if (connectionRefused) {
    return <ServerIssue />
  }

  if (Forbidden) {
    return <Forbidden />
  }

  if (Collection && Collection.attributes && (Collection.attributes.Public === true || (UserOptions || sharedUser))) {
    return (
      <div className=' p-8' >
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            style: { boxShadow: "none", border: "1px solid #bdb5b5" },
            success: { duration: 3000 }
          }} />
        <div className='text-3xl font-medium flex gap-4 items-center'>
          <Avatar isBordered radius="full" name={Collection?.attributes?.Name?.slice(0, 1).toUpperCase()} size="md" color={colorPicker(Collection.id)} />
          {titleCreater(Collection?.attributes?.Name)}
        </div>
        <div className='mt-8 gap-2 flex flex-wrap'>
          {/* Render tags if Collection and Collection.attributes exist */}
          {Collection?.attributes?.Tags?.tags?.map((tag, index) => (
            <Chip key={index} color={colorPicker(Collection.id)} variant='flat' size='sm'>
              {tag}
            </Chip>
          ))}
        </div>
        <div className='mt-4 leading-relaxed '>
          {Collection && Collection.attributes.Description}
        </div>
        <div className='flex flex-row'>
          <div className='w-full'>
            <Tabs aria-label="Options" color={colorPicker(Collection.id)} variant="underlined" disabledKeys={!UserOptions ? ["Settings", "Members"] : []} className="pt-8">
              <Tab key="Documents" title="Documents" >
                <Documents UserOptions={UserOptions} color={colorPicker(Collection.id)} />
              </Tab>
              <Tab key="Members" title="Members">
                <CollectionMembers setLoader={setLoader} params={params} UserID={UserID} setResponse={setReload} />
              </Tab>
              {Collection?.attributes?.data_product?.data?.attributes?.Schema && <Tab key="Configuration" title="Configuration">
                <Configuration collection={Collection} setReload={setReload} setLoader={setLoader} />
              </Tab>}
              <Tab key="Settings" title="Settings" >
                <Settings collection={Collection} setReload={setReload} setLoader={setLoader}  collectionName="collections" isHide={false} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div >
    )
  }
}

export default SingleCollection;