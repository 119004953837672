import React from "react";
import { formatSingleLineSQL } from "../Utils/charts.utils";

const SQLCode = ({ sql }) => {
    function formatSQL(sql) {
        return sql
          .replace(/\b(SELECT|FROM|WHERE|GROUP BY|ORDER BY|LIMIT)\b/gi, "\n$1")
          .trim();
      }
  const codeLines = formatSQL(sql).split("\n");
  return (
    <div
      className="bg-black text-white p-4 overflow-x-auto text-lg rounded-md w-full flex"
      style={{ fontFamily: "monospace" }}
    >
      {/* Line Numbers Column */}
      <div
        className="line-numbers"
        style={{
          color: "gray",
          textAlign: "right",
          marginRight: "1em",
          userSelect: "none",
        }}
      >
        {codeLines.map((_, index) => (
          <div key={index} style={{ lineHeight: "2em" }}>
            {index + 1}
          </div>
        ))}
      </div>

      {/* Code Column */}
      <div className="code-content">
        {codeLines.map((line, index) => (
          <div
            style={{ lineHeight: "2em" }}
            key={index}
            dangerouslySetInnerHTML={{
              __html: formatSingleLineSQL(line),
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SQLCode;
