import React from 'react';

export const NavbarItems = (params) => {
    const nav1 = [
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#FF5722]">
                <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>
            ,
            title: "Home",
            path: `/${params._spaceid}/`,
            id: "KnowledgeBase",
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#3371FF]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25" />
            </svg>
            ,
            title: "Domains",
            path: `/${params._spaceid}/domains`,
            id: "Domains",
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#388E3C]">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
            </svg>
            ,
            title: "Data Products",
            path: `/${params._spaceid}/data-products`,
            id: "DatabaseProducts",
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#D32F2F]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
            </svg>
            ,
            title: "Collections",
            path: `/${params._spaceid}/collections`,
            id: "Collections"
        },
        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/pqirzoux.json"
        //         trigger="hover"
        //         target="#Connectors"
        //         state="morph-home-2"

        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Connectors",
        //     path: `/${params._spaceid}/connectors`,
        //     id: "Connectors"
        // },
        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/pflszboa.json"
        //         trigger="hover"
        //         target="#Tool"
        //         state="morph-home-2"

        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Tools",
        //     path: `/${params._spaceid}/tools`,
        //     id: "Tool"
        // }
    ]
    const nav2 = [
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#F57C00]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
            </svg>
            ,
            title: "Haya",
            path: `/${params._spaceid}/haya`,
            id: "Haya"
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#0288D1]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
            </svg>
            ,
            title: "Dashboards",
            path: `/${params._spaceid}/dashboards`,
            id: "Dashboards"
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#81a21f]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
            </svg>

            ,
            title: "Anomalies",
            path: `/${params._spaceid}/anomaly`,
            id: "Anomalies"
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#7B1FA2]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
            </svg>
            ,
            title: "Teams",
            path: `/${params._spaceid}/teams`,
            id: "Teams"
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#C2185B]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5" />
            </svg>
            ,
            title: "Agents",
            path: `/${params._spaceid}/agents`,
            id: "extensions",
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#00796B]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6Z" />
            </svg>
            ,
            title: "Tags",
            path: `/${params._spaceid}/tags`,
            id: "Tags"
        },


        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/ercyvufy.json"
        //         trigger="hover"
        //         target="#Knowledgetree"
        //         state="morph-home-2"
        //         
        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Knowledge Tree",
        //     path: `/${params._spaceid}/knowledge-tree`,
        //     id: "Knowledgetree"
        // },
        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/jfwzwlls.json"
        //         trigger="hover"
        //         target="#Analytics"
        //         state="morph-home-2"
        //         
        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Analytics",
        //     path: `/${params._spaceid}/analytics`,
        //     id: "Analytics"
        // },



    ]

    const nav3 = [
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#FF3380]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
            </svg>
            ,
            title: "Keys",
            path: `/${params._spaceid}/keys`,
            id: "Keys",
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-4 text-[#5D4037]">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
            </svg>,
            title: "Integrations",
            path: `/${params._spaceid}/integrations`,
            id: "extensions",
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-[#303F9F]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 8.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v8.25A2.25 2.25 0 0 0 6 16.5h2.25m8.25-8.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-7.5A2.25 2.25 0 0 1 8.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 0 0-2.25 2.25v6" />
            </svg>
            ,
            title: "Templates",
            path: `/${params._spaceid}/templates`,
            id: "Templates",
            condition: "Haya"
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 text-[#7B1FA2]">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
            </svg>,
            title: "Users",
            path: `/${params._spaceid}/users`,
            id: "members"
        },
        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/vuiggmtc.json"
        //         trigger="hover"
        //         target="#Archive"
        //         state="morph-home-2"
        //         
        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Archive",
        //     path: `/${params._spaceid}/archive`,
        //     id: "Archive"
        // },

        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/wpyrrmcq.json"
        //         trigger="hover"
        //         target="#trash"
        //         state="morph-home-2"
        //         
        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Trash",
        //     path: `/${params._spaceid}/trash`,
        //     id: "trash"
        // },


        // {
        //     icon: ,
        //     title: "Settings",
        //     path: `/${params._spaceid}/settings`,
        //     id: "settings"
        // }
    ]
    return { nav1, nav2, nav3 }
}

export const SubNavbarItems = () => {

    const navbarItem = [

        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/kthelypq.json"

                trigger="hover"
                target="#AnalyticsData"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Profile",
            path: "/profile",
            id: "AnalyticsData"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/utpmnzxz.json"
                trigger="hover"
                target="#DocumentUpload"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Documents Uploaded",
            path: "/all-docs",
            id: "DocumentUpload"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/axteoudt.json"
                trigger="hover"
                target="#helpFeedback"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Help & Feedback",
            path: "/help",
            id: "helpFeedback"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/mdmniuqr.json"
                trigger="hover"
                target="#credits"
                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Credits",
            path: "/credits",
            id: "credits"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/prjooket.json"
                trigger="hover"
                target="#ViewSpace"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "View All Applications",
            path: "/",
            id: "ViewSpace"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/vduvxizq.json"
                trigger="hover"
                target="#LogoutData"

                style={{ width: "20px", height: "20px" }}>
            </lord-icon>,
            title: "Logout",
            path: "/sign-in",
            id: "LogoutData"
        },

    ]

    return navbarItem;
}

export const userDropDownItems = (userNavigate, tourHandler, handleChange, setIsTourOpen, logOutHandler) => {
    const items = [
        {
            title: "Profile",
            path: "Profile",
            id: "Profile",
            Click: userNavigate,
            icon: <lord-icon
                src="https://cdn.lordicon.com/kthelypq.json"
                trigger="hover"
                target="#Profile"
                state="morph-home-2"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>
        },
        {
            title: "Feedback",
            id: "Onboarding",
            Click: handleChange,
            icon: <lord-icon
                src="https://cdn.lordicon.com/zrtfxghu.json"
                trigger="hover"
                target="#Onboarding"

                style={{ width: "20px", height: "20px" }}>
            </lord-icon>
        },
        // {
        //     title: "Help",
        //     id: "Help",
        //     Click: tourHandler,
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/axteoudt.json"
        //         trigger="hover"
        //         target="#Help"
        //         
        //         style={{ width: "20px", height: "20px" }}>
        //     </lord-icon>
        // },
        {
            title: "Logout",
            id: "Logout",
            Click: logOutHandler,
            icon: <lord-icon
                src="https://cdn.lordicon.com/vduvxizq.json"
                trigger="hover"
                target="#Logout"

                style={{ width: "20px", height: "20px" }}>
            </lord-icon>
        },
    ]

    return items
}

