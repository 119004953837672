import React, { useEffect, useState } from 'react'
import { Tooltip, Dropdown, DropdownTrigger, DropdownItem, DropdownMenu, Avatar, Chip, Button, Input, useDisclosure } from '@nextui-org/react'
import { UpdateData, CommonCollectionFilterApi, InviteUser } from '../../../controllers/strapiController';
import DeleteModal from "../../../components/DeleteModal";
import SearchIcon from '../../../Images/Search';
import FetchUserDataAndRenderAvatar from "../FetchUserDataAndRenderAvatar";
import CryptoJS from "crypto-js";
import AvatarComponent from "../../../NextUI/Avatar";
import { colorPicker } from '../../../controllers/basicControllers';
import EmptyArray from '../../../components/EmptyArray';
import { toast } from "react-hot-toast";

const Collection_Members = ({ params, UserID, setLoader }) => {
  // STATE INITIALIZATION
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
  const [collectionData, setCollection] = useState(null);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [AllUsers, setAllUsers] = useState(null);
  const [SelectedId, setSelectedId] = useState('');
  const [deleteData, setDeleteData] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [textValue, setTextValue] = useState('');
  const [EmailValidate, setEmailValidate] = useState('');
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [response, setResponse] = useState();
  const spaceName = params._spaceid;

  // EVENT CHANGE HANDLER
  const HandleChange = (event) => {
    const TextValue = event.target.value;
    setTextValue(TextValue);
    const Filterdata = TextValue !== '' ? AllUsers && AllUsers.filter((data) => data && data.email.toLowerCase().includes(TextValue.toLowerCase())) : AllUsers
    setSearchResults(Filterdata);
  }

  // API RENDERING
  useEffect(() => {
    const url = `filters[id][$in][0]=${params._id}&filters[space][Name][$eq]=${spaceName}&[populate][space][on]&[populate][author][on]&[populate][inviteds][on]&[populate][requests][on]&[populate][viewed][on]`
    CommonCollectionFilterApi("collections", url)
      .then((data) => {
        setCollection(data.data);
        setButtonLoader(false);
      })

    const url2 = `filters[$or][0][spaces][Name][$in]=${spaceName}&filters[$or][1][space][Name][$in]=${spaceName}&[populate][Picture][on]&[populate][spaces][on]&[populate][space][on]`
    CommonCollectionFilterApi("users", url2)
      .then((data) => setAllUsers(data))
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
  }, [response, params._id, spaceName])


  // FILTER FUNCTION FOR INVITE, VIEWED AND REQUESTED COLLECTIONS 
  const Collection = collectionData && collectionData[0];
  const InvitedUsers = Collection?.attributes?.inviteds?.data;
  const ViewdUsers = Collection?.attributes?.viewed?.data;
  const requestedUser = Collection?.attributes?.requests?.data;
  const MergeUsers = InvitedUsers && InvitedUsers.concat(ViewdUsers && ViewdUsers);

  // SETTING INITIAL SEARCH RESULTS
  useEffect(() => {
    textValue === '' && AllUsers && setSearchResults(AllUsers)
  }, [AllUsers, textValue])

  // ONCHANGE VALIDATION
  useEffect(() => {
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (textValue !== '' && searchResults.length === 0) {
      if (!EmailRegex.test(textValue)) {
        setEmailValidate("Please enter a valid email.")
      } else {
        setEmailValidate(null)
      }
    }
  }, [textValue, searchResults])

  // DELETE WARMING
  const DeleteWarningHandle = (data) => {
    setDeleteData(data);
    onDeleteOpen();
  }

  // TAGS TO CREATE
  const handleKeyPress = (e) => {
    if (tagInput.trim() !== '' && e.key === "Enter") {
      if (!tags.includes(Text)) {
        setTags((previous) => [...previous, Text]);
        setTagInput('');
      }
    }
  }

  // DELETE HANDLER TO DELETE COLLECTION
  const DeleteHandler = async (onClose) => {

    const payload = {
      inviteds: { disconnect: [{ id: deleteData, }], connect: [] }
    }
    const response = await UpdateData("collections", params._id, payload);
    if (response) {
      setResponse(response);
      onClose()
    }
  }

  // INVITE THE USER FOR COLLECTION 
  const InvitedHandler = async (id) => {
    const payload = {
      requests: { disconnect: [], connect: [{ id: id, position: { end: true } }] },
    }

    const response = await UpdateData("collections", params._id, payload)
    if (response) {
      setResponse(response)
      setSelectedId(id)
      setButtonLoader(true)
    }
  }

  // REMOVE THE REQUEST 
  const RemoveRequestHandler = async (id) => {
    const payload = {
      requests: { disconnect: [{ id: id, position: { end: true } }], connect: [] },
    }

    const response = await UpdateData("collections", params._id, payload)
    if (response) {
      setResponse(response)
      setSelectedId(id)
      setButtonLoader(true)
    }
  }

  // USER PERMISSIONS WHO ARE ALREADY INVITED
  const DropDownHandler = async (data, Selecteddata, id) => {

    if (data !== Selecteddata) {
      if (data === "Upload & View") {
        // invited
        const payload = {
          inviteds: { disconnect: [{ id: id, position: { end: true } }], connect: [] },
          viewed: { disconnect: [{ id: id }], connect: [] }
        }

        const response = await UpdateData("collections", params._id, payload)
        if (response) {
          setResponse(response)
        }

      } else if (data === "View only") {
        // view
        const payload = {
          inviteds: { disconnect: [{ id: id }], connect: [] },
          viewed: { disconnect: [], connect: [{ id: id, position: { end: true } }] }
        }
        const response = await UpdateData('collections', params._id, payload)
        if (response) {
          setResponse(response)
        }
      }
    }
  }

  // USER SECTION FORMAT
  const userDisplay = (data) => {
    return <div className='flex flex-row items-center gap-4  w-full'>
      <FetchUserDataAndRenderAvatar userId={data.id} />
      <div className='flex flex-col'>
        <p className=''>{data.email && data.email.split('@')[0]}</p>
        <p className='text-slate-400'>{data.username}</p>
      </div>
    </div>
  }

  // INVITING THE MEMBERS TO SPACE. INVITE BUTTON
  const InviteToSpaceHandler = async () => {
    const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
    const inputString = textValue;
    const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
    const AESHash = encrypted.toString();
    const url = `${process.env.REACT_APP_IP_ADDRESS}/${spaceName}/?${btoa(AESHash)}`
    const payload = {
      "to": textValue,
      "subject": "Invitation from Haya.",
      "html": `<html><body><a href=${url}>${url}</a></body></html>`
    };
    InviteUser(payload);
    setTextValue('');
    setResponse(response);
    return response
  }

  const invitedUsers = [];
  const remainingUsers = [];
  const requestedUsers = [];

  // LIST OF INVITED USERS
  searchResults && searchResults.forEach((data) => {
    const InvitedTest = MergeUsers && MergeUsers.find((item) => item.id === data.id);
    const request = requestedUser && requestedUser.find((item) => item.id === data.id);
    const ViewedTest = ViewdUsers && ViewdUsers.find((item) => item.id === data.id);
    const Newtest = ViewedTest ? "View only" : "Upload & View";
    const adminTest = data.id === UserID;

    if (InvitedTest) {
      invitedUsers.push(
        <div key={data.id} className='flex flex-row items-center justify-between py-2'>
          {userDisplay(data)}
          <div className='w-full  text-center'>
            <Chip size='sm' color="success" variant='flat'>Added</Chip>
          </div>
          <div className='w-full text-end flex justify-end gap-2'>
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                <div>
                  <Tooltip placement='bottom' size='sm' content='Manage role' delay={1000}>
                    <lord-icon
                      src="https://cdn.lordicon.com/lecprnjb.json"
                      trigger="hover"
                      target="#settings"
                      state="morph-home-2"

                      style={{ width: "20px", height: "20px" }}>
                    </lord-icon>
                  </Tooltip>
                </div>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={[Newtest]}
                aria-label="Profile Actions">
                <DropdownItem onClick={() => DropDownHandler("Upload & View", Newtest, data.id)} key="Upload & View" >Upload & View</DropdownItem>
                <DropdownItem onClick={() => DropDownHandler("View only", Newtest, data.id)} key="View only">View only</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Tooltip placement='bottom' size='sm' content='Remove user from collection' delay={1000}>
              <lord-icon
                src="https://cdn.lordicon.com/wpyrrmcq.json"
                colors="primary:#e1426c"
                trigger="hover"
                onClick={() => DeleteWarningHandle(data.id)}
                style={{ width: "20px", height: "20px" }}>
              </lord-icon>
            </Tooltip>
          </div>
        </div>
      );
    } else if (request) {
      // LIST OF REQUESTED MEMBERS
      requestedUsers.push(
        <div key={data.id} className='flex flex-row items-center justify-between py-2'>
          {userDisplay(data)}
          <div>
            {!request ? <Button onClick={() => InvitedHandler(data.id)} isLoading={SelectedId === data.id ? ButtonLoader : false} size='sm' radius='lg' color="danger">
              Invite
            </Button> :
              <Button onClick={() => RemoveRequestHandler(data.id)} className='gap-1' id='CancelBtn' size='sm' color="secondary" variant="light">
                <lord-icon
                  src="https://cdn.lordicon.com/nqtddedc.json"
                  trigger="hover"
                  target="#CancelBtn"

                  style={{ width: "16px", height: "16px" }}>
                </lord-icon>
                <span> Cancel request</span>
              </Button>}
          </div>
        </div>
      )
    }
    else {
      // LIST OF MEMBERS WHO ARE NOT INVITED AND REQUESTED
      remainingUsers.push(
        <div key={data.id} className='flex flex-row items-center justify-between py-2'>
          {userDisplay(data)}
          <div>
            {!request ? <>
              {adminTest ? <Button size='sm' color="success" variant='flat'>Invited</Button> :
                <Button onClick={() => InvitedHandler(data.id)} isLoading={SelectedId === data.id ? ButtonLoader : false} size='sm' radius='md' color="danger">
                  Add user
                </Button>}</> :
              <Button onClick={() => RemoveRequestHandler(data.id)} className='gap-1' id='CancelBtn' size='sm' color="secondary" variant="light">
                <lord-icon
                  src="https://cdn.lordicon.com/nqtddedc.json"
                  trigger="hover"
                  target="#CancelBtn"

                  style={{ width: "16px", height: "16px" }}>
                </lord-icon>
                <span> Cancel request</span>
              </Button>}
          </div>
        </div>
      );
    }
  });

  // RETURN UI
  return (
    <div>
      <div className="flex flex-col  gap-1">
        <h1 className="pt-8 pb-8 text-xl font-medium">Manage users</h1>
        <div className='pt-2 pb-8'>
          <Input
            classNames=""
            onChange={HandleChange}
            value={textValue}
            onKeyPress={(event) => { if (event.key === 'Enter') { handleKeyPress(event) } }}
            placeholder='Search users'
            startContent={<SearchIcon className="text-large text-default-400 pointer-events-none flex-shrink-0" />}
          />
        </div>
      </div>
      <div className='gap-0'>
        <div>
          <div className='divide-y divide-divider'>
            {searchResults && searchResults.length > 0 ? <>
              {invitedUsers}
              {requestedUsers}
              {remainingUsers}
            </>
              :
              <>{textValue !== "" ? <>{EmailValidate !== null ?
                <p className='text-red-500'>{EmailValidate}</p> :
                <div className='flex flex-row justify-between items-center'>
                  <div className='flex flex-row items-center gap-4'>
                    <AvatarComponent
                      color={Collection && colorPicker(Collection.id)} name={textValue.slice(0, 1).toUpperCase()} size="sm" />
                    <div className='flex flex-col '>
                      <p className=''>{textValue}</p>
                    </div>
                  </div>
                  <p className='text-xs text-slate-400'>This email does not exists in the space.</p>
                  <Button variant='flat' color='success' onClick={InviteToSpaceHandler}>Invite</Button>
                </div>}</> : <EmptyArray message={"No members added."} />}</>}
          </div>
        </div>
      </div>
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        Collectionname={"collection"}
        DeleteHandler={DeleteHandler}
        setLoader={setLoader} />
    </div>
  )
}

export default Collection_Members;