import { Button, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input, Select, SelectItem, Textarea } from '@nextui-org/react';
import React, { useState, useEffect } from 'react';
import { AgentForm } from '../../../JSON/AgentForm';
import { spaceInfo } from '../../../redux/actions';
import { useSelector } from "react-redux";
import { CreateData, UpdateData } from '../../../controllers/strapiController';
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AgentTools = ({ agent, setReload, formValues, setFormValues, onNext, onPrevious }) => {
    const items = AgentForm();  //list of dropdown items
    const [formData, setFormData] = useState([]);
    const [selectedForms, setSelectedForms] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const ReduxData = useSelector(spaceInfo);
    const [validate, setValidate] = useState(false);
    const collectionName = 'agents';
    const navigate = useNavigate();

    const handleDropdownSelect = (key) => {
        const selectedItem = items.find(item => item.key === key);

        const formInstanceId = `${key}-${Date.now()}`;

        setSelectedForms([...selectedForms, { ...selectedItem, id: formInstanceId }]);

        const initialFormValues = selectedItem.schema.properties.reduce((acc, field) => {
            acc[field.key] = "";
            return acc;
        }, { id: formInstanceId, type: selectedItem.key });

        setFormData(prev => [...prev, initialFormValues]);
    };

    const agentInfo = agent?.attributes?.InstructionJSON?.InstructionsJSON;

    useEffect(() => {
        if (agentInfo) {

            const newFormData = agentInfo.map(key => {
                const selectedKey = items?.find(data => data?.label === key?.id?.split('-')?.[0]);

                // Prepare the initialFormValues for each agent
                const initialFormValues = selectedKey?.schema?.properties?.reduce((acc, field) => {
                    acc[field.key] = key[field.key];
                    return acc;
                }, { id: key?.id, type: selectedKey?.key });

                return initialFormValues;
            });

            // Set formData only once
            setFormData(newFormData);

            // Set selectedForms based on the Agentdata
            const Agentdata = agentInfo.map(key => {
                const selectedKey = items?.find(data => data?.label === key?.id?.split('-')?.[0]);
                return { ...selectedKey, id: key?.id };
            });

            const flattenedData = Agentdata?.flat();
            setSelectedForms(flattenedData);
        }
    }, [agentInfo]);

    const handleInputChange = (formKey, inputKey, value) => {
        // Match text inside {}
        const matches = value.match(/\{([^}]+)\}/g);
        const valuedata = matches ? matches.map(word => word.replace(/[{}]/g, '')) : [];

        // Determine the new value for the input field
        let newvalue;
        if (inputKey === 'variables') {
            newvalue = value !== '' ? [...new Set(value.split(",").concat(valuedata))] : [];
        } else {
            newvalue = value;
        }

        // Update form data
        setFormData(prev => prev.map(form => {
            if (form.id === formKey) {
                const updatedVariables = inputKey === 'InstructionPrompt'
                    ? [...new Set(valuedata)]
                    : [...new Set((form.variables || []).concat(valuedata))];

                return {
                    ...form,
                    [inputKey]: newvalue,
                    variables: inputKey === 'InstructionPrompt'
                        ? updatedVariables.filter(varItem => valuedata.includes(varItem))
                        : updatedVariables
                };
            }
            return form;
        }));

        // Trigger validation if `validate` is true
        validate && validateForm();
    };


    const handleDeleteTool = (formId) => {
        // Remove form from selectedForms
        setSelectedForms(prev => prev.filter(form => form.id !== formId));

        // Remove form data entry for the deleted formId
        setFormData(prev => prev.filter(data => data.id !== formId));

        // Remove form error entry for the deleted formId
        setFormErrors(prev => {
            const updatedErrors = { ...prev };
            delete updatedErrors[formId];
            return updatedErrors;
        });
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        selectedForms?.forEach(form => {
            const formId = form.id;
            const currentFormErrors = {};

            // Find the corresponding form data entry by ID
            const formDataEntry = formData.find(data => data.id === formId);

            form?.schema?.properties?.forEach(field => {
                const fieldValue = formDataEntry?.[field.key];

                if (typeof fieldValue === 'string') {
                    // Check for required string fields
                    if (field.required && !fieldValue.trim()) {
                        currentFormErrors[field.key] = `${field.name} is required.`;
                        isValid = false;
                    }
                } else if (Array.isArray(fieldValue)) {
                    // Check for required array fields
                    if (field.required && fieldValue.length === 0) {
                        currentFormErrors[field.key] = `${field.name} is required.`;
                        isValid = false;
                    }
                } else {
                    // Check for other required fields that might be null or undefined
                    if (field.required && (fieldValue === null || fieldValue === undefined)) {
                        currentFormErrors[field.key] = `${field.name} is required.`;
                        isValid = false;
                    }
                }
            });

            if (Object.keys(currentFormErrors).length > 0) {
                errors[formId] = currentFormErrors;
            }
        });

        setFormErrors(errors);
        return isValid;
    };


    const dataproducts = formValues?.DataProducts?.map((data, index) => {
        if (data !== "SelectAll") {
            if (index === 0) {
                return { "id": parseInt(data), "position": { "end": true } }
            }
            return { "id": parseInt(data), "position": { "before": parseInt(formValues?.DataProducts[index - 1]) } }
        } else {
            return null
        }
    }).filter(item => item !== null);

    const submitHandler = async () => {

        const spaceID = ReduxData?.payload?.space?.[0]?.id;
        const userID = ReduxData?.payload?.data?.id
        if (validateForm()) {
            setValidate(false);
            const payload = {
                Name: formValues?.Name,
                Description: formValues?.Context,
                SelectAll: formValues?.SelectAll,
                modelJSON: formValues?.modelJSON,
                InstructionJSON: { InstructionsJSON: formData },
                space: { disconnect: [], connect: [{ id: parseInt(spaceID), position: { end: true } }] },
                users_permissions_user: { disconnect: [], connect: [{ id: parseInt(userID), position: { end: true } }] },
                collections: { disconnect: [], connect: dataproducts }
            }

            const updatepayload = {
                InstructionJSON: { InstructionsJSON: formData },
            }

            const response = agent ? await UpdateData(collectionName, agent?.id, updatepayload) : await CreateData(collectionName, payload);

            if (response) {
                if (agent) {
                    toast.success("Agent Updated successfully.");
                    setReload(response);
                }
                else {
                    toast.success("Agent created successfully.");
                    navigate(`/${ReduxData?.payload?.space?.[0]?.attributes?.Name}/agents/${response?.data?.id}`)
                }
            } else {
                toast.error("Something went wrong. Please try after sometime.")
            }
        } else {
            setValidate(true);
        }
    }

    const formCreator = (form) => {
        return (
            <div className='flex flex-col gap-8 pt-4'>
                {form?.schema?.properties?.map((data, toolIndex) => {
                    const error = formErrors[form.id]?.[data.key];
                    const findData = formData?.find(val => val?.id === form.id);
                    const commonProps = {
                        key: `${data.key}${toolIndex}`,
                        labelPlacement: 'outside',
                        placeholder: data.placeholder,
                        label: data.name,
                        name: data.name,
                        value: findData?.[data.key],
                        onChange: (e) => handleInputChange(form.id, data.key, e.target.value),
                        isInvalid: !!error,
                        errorMessage: error
                    }

                    if (data.type === "dropdown") {
                        return (
                            <Select
                                {...commonProps}
                            >
                                {data.values?.map((option) => (
                                    <SelectItem key={option} value={option}>
                                        {option}
                                    </SelectItem>
                                ))}
                            </Select>
                        );
                    }
                    else if (data.type === "textarea") {
                        return (
                            <Textarea
                                {...commonProps}
                                type='text'
                                description={data.description}
                            />
                        )
                    }
                    else {
                        return (
                            <Input
                                {...commonProps}
                                type={data.type === "password" ? "password" : "text"}
                                description={data.description}
                                isDisabled={data.key === 'variables' ? true : false}
                            />
                        );
                    }
                })}
            </div>
        );
    };

    return (
        <div className='p-8'>
            {!agent && <> <div className='text-3xl font-medium'>Build your agent</div>
                <div className='mt-4 leading-relaxed'>
                    Haya Agents are intelligent mini AI agents with predefined agent instructions, allowing them to perform specific tasks autonomously within your applications. You can also create and customize your own agent actions to suit the unique needs of your workflows, ensuring a tailored and efficient solution for your data-driven processes.
                </div>
            </>}
            <div>
                <div className='flex justify-end mt-4'>
                    <Dropdown>
                        <DropdownTrigger>
                            <Button color='primary' variant="flat">+ Add Instructions</Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Dynamic Actions" items={items}>
                            {items?.map((item, index) => (
                                <DropdownItem
                                    key={`${item.key}`}
                                    description={item.description}
                                    startContent={item.icon}
                                    onClick={(event) => handleDropdownSelect(item.key)}
                                >
                                    {item.label}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
            {/* Render instructions */}
            <div className='mt-4'>
                {selectedForms?.map((tool, toolIndex) => {

                    return <div key={toolIndex} className='mb-4'>
                        <div className='flex items-center justify-between'>
                            <div className='flex items-center'>
                                {tool.icon} {/* Render the icon */}
                                <span className='ml-2'>{tool.label}</span> {/* Render the label */}
                            </div>
                            <div>
                                <Button
                                    isIconOnly
                                    variant='light'
                                    color='danger'
                                    onClick={() => handleDeleteTool(tool?.id)} // Delete handler
                                    startContent={<lord-icon
                                        src="https://cdn.lordicon.com/wpyrrmcq.json"
                                        trigger="hover"
                                        state="morph-home-2"
                                        colors="primary:#e1426c"
                                        style={{ width: "16px", height: "16px" }}>
                                    </lord-icon>}
                                />
                            </div>
                        </div>
                        <p className='text-sm text-gray-600 mt-4'>{tool.description}</p> {/* Render the description */}
                        <div className='mt-2'>
                            {formCreator(tool)}
                        </div>
                        <div className='mt-4'>
                            <Divider />
                        </div>
                    </div>
                })}
            </div>
            <div>
                <div className='mt-32 flex gap-2 justify-end'>
                    {!agent && <Button onClick={onPrevious} variant="light">Previous</Button>}
                    <Button onClick={submitHandler} color='secondary' variant='flat'>{agent ? "Update" : "Test"}</Button>
                </div>
            </div>
        </div>
    );
}

export default AgentTools;
