import React, { useEffect, useState } from "react";
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import { useLocation, Link } from "react-router-dom";
import { getOneData } from "../controllers/strapiController";

const BreadcrumbsTabs = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter((path) => path !== "");

  const [collectionData, setCollectionData] = useState({});

  const nameAdjust = (string) => {
    console.log(string);

    const nameSplit = string && string.replaceAll("-", " ");
    const capitalizedString = nameSplit
      .split(" ")
      .map((word) => word?.charAt(0).toUpperCase() + word?.slice(1))
      .join(" ");
    return capitalizedString;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (paths.length > 2 && paths[1] && paths[2]) {
        const collectionName = paths[1];
        try {
          const response = await getOneData(collectionName, paths[2]);
          setCollectionData((prevData) => ({
            ...prevData,
            [paths[2]]: response.data,
          }));
        } catch (error) {
          console.error("Error fetching collection data:", error);
        }
      }
    };
    fetchData();
  }, [location]);

  console.log(paths);

  return (
    <>
      {paths && paths.length > 1 && (
        <Breadcrumbs>
          {paths.includes("haya")
            ? paths.map((path, index) => {
                const url = `/${paths.slice(0, index + 1).join("/")}`;
                return (
                  <BreadcrumbItem key={index}>
                    {index === 0 || index == 1 ? (
                      <Link to={url}>{nameAdjust(path)}</Link>
                    ) : (
                      path
                    )}
                  </BreadcrumbItem>
                );
              })
            : paths.map((path, index) => {
                console.log(path);

                const url = `/${paths.slice(0, index + 1).join("/")}`;
                const collection = index > 1 ? collectionData[path] : "";
                const decodedString = decodeURIComponent(path);
                return (
                  <BreadcrumbItem key={index}>
                    <Link to={url}>
                      {collection
                        ? collection.attributes.Name
                        : nameAdjust(decodedString)}
                    </Link>
                  </BreadcrumbItem>
                );
              })}
        </Breadcrumbs>
      )}

      {paths && paths.length === 1 && (
        <div>
          <>
            {paths.map((path, index) => {
              const url = `/${paths.slice(0, index + 1).join("/")}`;
              return (
                <Breadcrumbs>
                  <BreadcrumbItem key={index}>
                    <Link to={url}>{nameAdjust(path)}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>Overview</BreadcrumbItem>
                </Breadcrumbs>
              );
            })}
          </>
        </div>
      )}
    </>
  );
};

export default BreadcrumbsTabs;
