import React, { useState, useEffect } from 'react';
import { CreateData, UpdateData, getAllData, CommonCollectionFilterApi } from "../../controllers/strapiController";
import { tableSkeleton } from "../../components/Skeleton";
import { TableCell, TableColumn, TableBody, TableRow, CardBody, Card, Input, Table, TableHeader, Dropdown, DropdownTrigger, DropdownItem, DropdownMenu, Chip, Pagination } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import Paginate from '../../components/Pagination';

const Tags = () => {
  //STATE INITIALIZATION
  const [tags, setTags] = useState();
  const [Loader, setLoader] = useState(true);
  const [inputText, setInputText] = useState('');
  const [response, setresponse] = useState('');
  const [FilteredUser, setFilteredUser] = useState(null);
  const [TagsLength, setTagsLength] = useState(null);
  const [collections, setCollections] = useState([]);
  const CollectionName = "tags";
  const [validateCondition, setvalidateCondition] = useState(false);
  const UserDetails = JSON.parse(sessionStorage.getItem("userData"));
  const userId = UserDetails && UserDetails.user && UserDetails.user.id;
  const [Validation, setValidation] = useState('');
  const [currentPage, setCurrentPage] = useState(1);


  // API RENDERING
  useEffect(() => {
    setLoader(true);
    getAllData(CollectionName)
      .then((data) => {
        setTimeout(() => { setLoader(false); }, 200)
        setTags(data.data)
      })

    const url = `filters[$or][0][admin][id][$eq]=${userId}&filters[$or][1][members][id][$eq]=${userId}&[populate][admin][on]&[populate][collections][on]&[populate][connectors][on]&[populate][tools][on]`
    CommonCollectionFilterApi("spaces", url)
      .then((data) => { setTimeout(() => { setLoader(false); }, 300); setCollections(data.data) })
  }, [response])

  const CollectionTags = collections ? collections?.map((data) => data?.attributes?.collections?.data) : [];
  const connectorsTags = collections ? collections?.map((data) => data?.attributes?.connectors?.data) : [];
  const concatedData = []?.concat(...connectorsTags, ...CollectionTags)

  const tagsCount = concatedData?.map((data) => data?.attributes?.Tags?.tags)
  // Count occurrences of each element
  const counts = tagsCount && tagsCount.reduce((acc, curr) => {
    curr && curr.forEach(item => {
      acc[item] = (acc[item] || 0) + 1;
    });
    return acc;
  }, {});

  // TAGS INPUT ONCHANGE HANDLER
  const handleChange = (e) => {
    const userInput = e.target.value;

    const regex = /^[a-zA-Z_-]*$/
    if (regex.test(userInput)) {
      setInputText(userInput)
      setCurrentPage(1)
    }
  }

  useEffect(() => {
    // FILTER USERS 
    const FilteredUser = tags && tags.filter((data) => data?.attributes?.users_permissions_user?.data?.id === userId)
    // TAGS PRESENTS
    const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;

    if (FilteredUser) {
      setFilteredUser(FilteredUser);
    } else {
      setFilteredUser(null);
    }

    if (TagsLength) {
      setTagsLength(TagsLength);
    } else {
      setTagsLength(null);
    }
  }, [tags])

  // FILTER FOR INPUT TEXT RELATED TO TAGS
  const FilteredText = inputText !== '' ? TagsLength?.filter((data) => data?.toLowerCase().includes(inputText?.trim().toLowerCase())) : TagsLength;

  // FINAL DISPLAY TAGS
  const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : TagsLength

  // TAG ID TO UPDATE TAGS
  const CollectionId = FilteredUser && FilteredUser[0] && FilteredUser[0].id;

  const Validations = () => {
    let isValid = true;

    if (inputText !== '' && TagsLength && TagsLength.includes(inputText)) {
      isValid = false;
      setValidation("Entered tag already exists.");
    } else {
      setValidation(null)
    }
    return isValid
  }

  // ENTER TO CREATE TAG
  const handleKeyPress = async (event) => {
    const Validate = Validations();
    if (event.key === "Enter" && inputText.trim() !== '') {
      if (Validate) {
        setvalidateCondition(false)
        if (FilteredUser && FilteredUser.length > 0) {
          const payload = { Tags: { tags: [...TagsLength && TagsLength.length > 0 ? TagsLength : "", inputText.trim()] } }
          const response = await UpdateData(CollectionName, CollectionId, payload);
          if (response) {
            setresponse(response)
            setInputText('');
          }
        }
        if (FilteredUser && FilteredUser.length === 0) {
          const payload = {
            users_permissions_user: {
              disconnect: [],
              connect: [{
                id: userId,
                position: {
                  end: true
                }
              }]
            },
            Tags: { tags: [inputText.trim()] }
          }
          const response = await CreateData(CollectionName, payload);
          if (response) {
            setresponse(response)
            setInputText('');
          }
        }
      } else {
        setvalidateCondition(true);
      }
    }
  }

  // DISPLAY ITEM ONCLICK TO CREATE
  const handleOnClick = async (event) => {
    const Validate = Validations();
    if (Validate && inputText !== '') {
      if (FilteredUser && FilteredUser.length > 0) {
        const payload = { Tags: { tags: [...TagsLength && TagsLength.length > 0 ? TagsLength : "", inputText.trim()] } }
        const response = await UpdateData(CollectionName, CollectionId, payload);
        if (response) {
          setresponse(response)
          setInputText('');
        }
      }
      if (FilteredUser && FilteredUser.length === 0) {
        const payload = {
          users_permissions_user: {
            disconnect: [],
            connect: [{
              id: userId,
              position: {
                end: true
              }
            }]
          },
          Tags: { tags: [inputText.trim()] }
        }
        const response = await CreateData(CollectionName, payload);
        if (response) {
          setresponse(response)
          setInputText('');
        }
      }
    }
  }

  // REMOVE THAG FROM LIST
  const RemoveTag = async (tag) => {

    const removeTag = FilteredTags && FilteredTags.length !== 0 && FilteredTags.filter((data) => data !== tag);

    const payload = {
      Tags: {
        tags: removeTag
      }
    }
    const response = await UpdateData(CollectionName, CollectionId, payload);
    if (response) {
      setresponse(response);
    }
  }


  // Calculate indexes for pagination
  const recordsPerPage = 10;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = FilteredTags && FilteredTags?.slice(indexOfFirstRecord, indexOfLastRecord);

  return (
    <div className='p-8'>

      <div className='text-3xl font-medium'>Manage Tags</div>
      <div className='mt-4 leading-relaxed'>
        Pin all your important tags to your documents to make your overview more organised.
      </div>
      <div className='mt-8 justify-between'>
        <div className='mt-8'>
          <Card className="shadow-none border border-divider rounded-2xl">
            <CardBody>
              <div className='gap-8 px-8 py-4'>
                <div className='flex flex-row items-center justify-between mb-8'>
                  <p className='font-medium text-lg'> All Tags</p>
                </div>
                <div id="tagsearch">
                  <Input
                    placeholder="Add a new pinned tag"
                    variant='bordered'
                    size='xs'
                    errorMessage={Validation !== '' && TagsLength && TagsLength.includes(inputText) ? Validation : ""}
                    isInvalid={validateCondition && Validation !== '' && TagsLength && TagsLength.includes(inputText) ? validateCondition : ""}
                    onChange={handleChange}
                    value={inputText}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleKeyPress(event);
                      }
                    }}
                    endContent={
                      <lord-icon
                        onClick={handleOnClick}
                        src="https://cdn.lordicon.com/vduvxizq.json"
                        trigger="hover"
                        target="#tagsearch"
                        Colors={`primary:${validateCondition && Validation !== '' && TagsLength && TagsLength.includes(inputText) ? "#c71f16" : ""}`}
                        style={{
                          width: "18px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      />
                    }
                    startContent={
                      <lord-icon
                        src="https://cdn.lordicon.com/prjooket.json"
                        trigger="hover"
                        target="#tagsearch"
                        Colors={`primary:${validateCondition && Validation !== '' && TagsLength && TagsLength.includes(inputText) ? "#c71f16" : `""}`}`}
                        state="morph-home-2"
                        style={{ width: "16px", height: "16px" }}>
                      </lord-icon>
                    }
                    className='mb-8'
                  />
                </div>
                {Loader ? tableSkeleton() :
                  <Table removeWrapper aria-label="Example static collection table" className='h-[435px]'>
                    <TableHeader>
                      <TableColumn>Name</TableColumn>
                      <TableColumn>Frequency</TableColumn>
                      <TableColumn className="flex flex-row items-center justify-center">Action</TableColumn>
                    </TableHeader>
                    <TableBody emptyContent={"add a new tag to keep your document organized."}>
                      {currentRecords && currentRecords.map((tag, index) => {
                        return <TableRow key={index + 1}>
                          <TableCell>
                            <Chip size='sm' variant='flat'>
                              #
                              <span className='font-bold'>
                                {tag}
                              </span>
                            </Chip>
                          </TableCell>
                          <TableCell>{counts[tag] ? counts[tag].toString().length > 1 ? counts[tag] : `${"0" + counts[tag]}` : "00"}</TableCell>
                          {/* <TableCell>Active</TableCell> */}
                          <TableCell className="cursor-pointer flex flex-row justify-center">
                            <Dropdown placement='bottom-end' className=''>
                              <DropdownTrigger >
                                <lord-icon
                                  src="https://cdn.lordicon.com/wpyrrmcq.json"
                                  trigger="hover"

                                  style={{ width: "16px", height: "16px" }}>
                                </lord-icon>
                              </DropdownTrigger>
                              <NextThemesProvider>
                                <DropdownMenu>
                                  <DropdownItem color='danger' variant='flat'>
                                    <p className='cursor-pointer' onClick={() => RemoveTag(tag)}>Delete {tag}</p>
                                  </DropdownItem>
                                </DropdownMenu>
                              </NextThemesProvider>
                            </Dropdown>
                          </TableCell>
                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                }

                <div className='flex flex-row justify-end pt-8'>
                  <Paginate
                    totalPage={FilteredTags}
                    initialPage={"1"}
                    id={userId}
                    currentRecords={currentRecords}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Tags