export const prompt = (query) =>  { return {
  prompt:
    "What is the subject of this query: {query}. Your output should only be 3 words",
  variables: [{ variable: "query", value: query }],
}};


// Helper function to format dates without external libraries
export const getDateLabel = (timestamp) => {
  const date = new Date(timestamp);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  // Check if the date is today
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return "Today";
  }

  // Check if the date is yesterday
  if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return "Yesterday";
  }

  // Check if the date is within the last 7 days (but not today or yesterday)
  if (date >= sevenDaysAgo) {
    return "Previous 7 days";
  }

  // Check if the date is within the last 30 days (but not in the last 7 days)
  if (date >= thirtyDaysAgo) {
    return "Previous 30 days";
  }

  // For older dates, group by "Month-YYYY" (e.g., "October-2024")
  return date.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
  });
};


export const generateCodeVerifier = () => {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return btoa(String.fromCharCode(...array))
      .replace(/[\+/=]/g, '')
      .substring(0, 128); // limit to 128 characters
};

export const generateCodeChallenge = async (codeVerifier) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await crypto.subtle.digest('SHA-256', data);
  return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/[\+/=]/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_'); // URL-safe base64
};

export function formatTimestamp(timestamp) {

  // Check if the timestamp is in seconds or milliseconds
  const isSeconds = timestamp < 1e12; // Timestamps in seconds are much smaller than milliseconds
  const date = new Date(isSeconds ? timestamp * 1000 : timestamp);

  // Get date components
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Get time components
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedTime = `${hours}:${minutes}${ampm}`;

  // Format as mm/dd/yyyy hh:mm(am/pm)
  return `${month}/${day}/${year} ${formattedTime}`;
}

export function getMonthName(monthNumber) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthNames[monthNumber - 1];
}

export function convertToDate(dateObj) {
  if (!dateObj) {
    return "";
  }
  const { day, month, year } = dateObj;
  return `${getMonthName(month)} ${day} ${year}`;
}

export const trimString = (str, len) => {
  if (!str) {
    return "";
  }
  if (str.length > len) {
    return `${str.slice(0, len)}...`;
  } else {
    return str;
  }
};