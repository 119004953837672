import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllDocs } from "../../controllers/vectordbController";
import { trimString } from "../../Utils/helper";
import { useSelector } from "react-redux";
import { spaceData } from "../../redux/actions";
import { getFilteredBySingleRelationSingleData } from "../../controllers/strapiController";

const Anomaly = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [anomalies, setAnomalies] = useState([]);
  const spaceInfo = useSelector(spaceData);
  const UserDetails = JSON.parse(sessionStorage.getItem("userData"));
  const UserID = UserDetails && UserDetails.user && UserDetails.user.id;
  const [dataProducts, setDataProducts] = useState([]);
  useState(() => {
    const fetchDataProducts = async () => {
      try {
        const resp = await getFilteredBySingleRelationSingleData(
          "collections",
          "space",
          spaceInfo[0]?.id,
          "Public",
          true
        );
        setDataProducts(resp.data);
      } catch (e) {
        console.log(e);
      }
    };
    if (spaceInfo[0]?.id) {
      fetchDataProducts();
    }
  }, [spaceInfo]);
  useEffect(() => {
    const getAnomalies = async () => {
      try {
        const resp = await getAllDocs(
          spaceInfo[0]?.attributes?.SpaceName,
          "anomaly"
        );
        setAnomalies(
          resp[0]
          // .filter(
          //   (a) =>
          //     a.payload.userId === UserID ||
          //     a.payload.public &&
          //     dataProducts.some(
          //       (b) => String(b.id) === String(a.payload.dataproduct_id)
          //     )
          // )
        );
      } catch (e) {
        console.log(e);
      }
    };
    if (spaceInfo[0]?.attributes?.SpaceName) {
      getAnomalies();
    }
  }, [spaceInfo, dataProducts, UserID]);

  return (
    <div className="p-8">
      <div className="text-3xl font-medium">Detect Anomalies</div>
      <div className="mt-4 leading-relaxed">
        Uncover anomalies in your data products seamlessly with Haya, empowering
        your data mesh to deliver more reliable insights.
      </div>
      <div className="pt-4 flex w-full justify-end">
        <Button
          color="danger"
          onClick={() => navigate(`/${params._spaceid}/anomaly/create`)}
        >
          Detect a new anomaly
        </Button>
      </div>

      {/* Table to show the anomaly list*/}
      <Table removeWrapper className="mt-8">
        <TableHeader>
          <TableColumn>Name</TableColumn>
          <TableColumn>Description</TableColumn>
          <TableColumn></TableColumn>
        </TableHeader>
        <TableBody>
          {anomalies.map((it) => (
            <TableRow
              className="cursor-pointer hover:bg-grey-200 rounded-md"
              onClick={() =>
                navigate(`/${params._spaceid}/anomaly/${it.payload.id}`)
              }
            >
              <TableCell>{it.payload.name}</TableCell>
              <TableCell>{trimString(it.payload.description, 60)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Anomaly;
