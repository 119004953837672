import {
  Button,
  Image,
  Pagination,
  Spinner,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Textarea,
} from "@nextui-org/react";
import React, { useMemo, useState } from "react";
import { getSQL } from "../../controllers/sqlController";
import { copyToClipboard } from "../../Utils/charts.utils";
import MonacoEditor from "../../components/MonacoEditor";

const PrettyJSON = ({ json }) => {
  return (
    <div
      style={{
        whiteSpace: "pre-wrap", // Preserve line breaks
        fontFamily: "monospace", // Use monospaced font for better readability
        padding: "10px", // Padding around the text
      }}
    >
      {JSON.stringify(json, null, 2)}
    </div>
  );
};

export const RenderTable = ({ json }) => {
  const [page, setPage] = useState(1);
  const rowsPerPage = 8;

  const pages = Math.ceil(json?.length / rowsPerPage);

  const tableData = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return json?.slice(start, end);
  }, [page, json]);
  if (json?.length > 0) {
    return (
      <Table
        bottomContent={
          <div className="flex w-full justify-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="secondary"
              page={page}
              total={pages}
              onChange={(page) => setPage(page)}
            />
          </div>
        }
        removeWrapper
      >
        <TableHeader>
          {Object.keys(json[0]).map((item) => (
            <TableColumn>{item}</TableColumn>
          ))}
        </TableHeader>
        <TableBody>
          {tableData.map((item) => (
            <TableRow>
              {Object.keys(item).map((t) => (
                <TableCell>{item[t] ?? ""}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  } else {
    return <></>;
  }
};

const WorkBench = ({ data,anomalySql,anomalyArr , handleUpdateAnomaly }) => {
  console.log("arr-->",anomalyArr);
  console.log("sql-->",anomalySql)
  function keysToLowerCase(obj) {
    if (typeof obj !== "object" || obj === null) return obj; // Handle non-object inputs

    return Object.keys(obj).reduce((acc, key) => {
      acc[key.toLowerCase()] = obj[key];
      return acc;
    }, {});
  }
  
  const [selected, setSelected] = useState("Table");
  const [val, setVal] = useState(anomalySql ?? "");
  const [arr, setArr] = useState(anomalyArr ?? null);
  const [disabled, setDisabled] = useState(false);
  const [error,setError] = useState("")
  const submitHandle = async () => {
    if (
      val === "" ||
      !data?.attributes?.ConnectionJSON?.properties ||
      !data?.attributes?.data_product?.data?.attributes?.Name ||
      disabled
    ) {
      return;
    }
    try {
      setError("");
      setDisabled(true);
      const dbConfig = keysToLowerCase(
        data?.attributes?.ConnectionJSON?.properties
      );
      const resp = await getSQL(
        val,
        data?.attributes?.data_product?.data?.attributes?.Name,
        dbConfig
      );
      setArr(resp);
      setDisabled(false);
    } catch (e) {
      
      setError(e?.message);
      setDisabled(false);
    }
  };
  return (
    <div className="h-full pt-8 overflow-y-auto flex gap-8 w-full">
      <div className="w-[35%] h-full flex flex-col gap-4 overflow-hidden">
        <div className="flex-1 w-full">
          <MonacoEditor value={val} onChange={(newSql) => setVal(newSql)} />
        </div>
        <div className={`flex ${anomalySql ? "justify-between" : " justify-end"} items-center`}>
        {anomalySql && <Button
            disabled={disabled || val === anomalySql }
            onClick={async() => handleUpdateAnomaly(val) }
            size="md"
            color="secondary"
          >
            Update
          </Button>}
          <Button
            disabled={disabled}
            onClick={submitHandle}
            size="md"
            color="secondary"
          >
            Submit
          </Button>
        </div>
      </div>
      <div className="w-[65%] flex flex-col p-4 gap-4 h-full border rounded-lg border-divider overflow-y-auto">
        <div className="flex justify-between items-center ">
          <Tabs
            selectedKey={selected}
            onSelectionChange={setSelected}
            size="md"
          >
            <Tab key={"Table"} title="Table"></Tab>
            <Tab key={"JSON"} title="JSON"></Tab>
          </Tabs>
          <Button
            onClick={() => copyToClipboard(arr)}
            isIconOnly
            disabled={!arr}
            size="sm"
            variant="light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
              />
            </svg>
          </Button>
        </div>

        {disabled ? (
          <div className="h-full w-full flex items-center justify-center">
            <Spinner />{" "}
          </div>
        ) : 
        error !== "" ? <div className="text-sm text-red-500" >{error}</div> :
        (
          arr &&
          arr?.length > 0 && (
            <div className="flex-1 overflow-y-auto overflow-x-auto">
              {selected === "Table" ? (
                <RenderTable json={arr} />
              ) : selected === "JSON" ? (
                <PrettyJSON json={arr} />
              ) : (
                <></>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default WorkBench;
